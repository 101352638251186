define("adtikir3v1/pods/error/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function adkError(e, controller) {
    //format the e to a generic error format that everyone know
    //this will be the routine to format a error object to a general one 
    // for didplay at the error route
    let code, title, detail;
    code = e.code;
    title = 'Error';
    detail = e.message;

    if (e.errors) {
      //title = e.errors[0].title;
      code = e.errors[0].code;
      detail = detail + "\n" + e.errors[0].detail;
    }

    controller.set('errTitle', title);
    controller.set('errDetail', detail);
    controller.set('errCode', code);
  }

  let ErrorRouteClass = (_dec = Ember.inject.service, (_class = class ErrorRouteClass extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adkSessionAccount", _descriptor, this);
    }

    setupController(controller, error) {
      super.setupController(...arguments);
      adkError(error, controller);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ErrorRouteClass;
});