define("adtikir3v1/pods/components/adk-media-type-selection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {
  //     computed
  // } from '@ember/object';
  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments); // this.set('activeLi', null);

    },

    actions: {
      selectMediaType(mediaType, event) {
        //   if (this.setMediaTypeAction) {
        //     this.setMediaTypeAction(mediaType); 
        //   } else {        
        //     this.set("selectedMediaType", mediaType);    
        //   }
        //   let currentTarget = event.target.parentNode.parentNode.parentNode;
        //   if (this.activeLi && this.activeLi.classList.contains("active-class")) {
        //      this.activeLi.classList.remove("active-class");
        //   } 
        //   this.set("activeLi", currentTarget);
        //   this.activeLi.classList.add('active-class');
        //   console.log(this.activeLi.classList);
        this.set("selectedMediaType", mediaType);
      }

    } //actions

  });

  _exports.default = _default;
});