define("adtikir3v1/pods/components/adk-rating-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jM+D9eqd",
    "block": "{\"symbols\":[],\"statements\":[[2,\" \\nCopyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.\\nThis software is protected by Copyright laws and use is subject to license agreement available at adtiki.com\\nAuthor: \\nDate:\\nPurpose: To create ratings for RFPs using the starts \\nUsage:  The fillColor can only be standard webcolors.\\n\"],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"display\"]],true],null]],null,{\"statements\":[[0,\"     \"],[1,[28,\"star-rating\",[[24,[\"rating\"]]],[[\"readOnly\",\"anyPercent\",\"fillColor\",\"width\",\"height\"],[true,true,\"orange\",[24,[\"width\"]],[24,[\"height\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"star-rating\",[[24,[\"rating\"]]],[[\"fillColor\",\"width\",\"height\",\"onClick\"],[\"blue\",[24,[\"width\"]],[24,[\"height\"]],[28,\"action\",[[23,0,[]],\"setRating\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-rating-selection/template.hbs"
    }
  });

  _exports.default = _default;
});