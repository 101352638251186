define("adtikir3v1/helpers/scroll-to", ["exports", "@abcum/ember-helpers/helpers/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _scrollTo.default;
    }
  });
  Object.defineProperty(_exports, "scrollTo", {
    enumerable: true,
    get: function () {
      return _scrollTo.scrollTo;
    }
  });
});