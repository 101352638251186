define("adtikir3v1/pods/components/adk-media-rfp/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    campaignName: '',
    campaignNameLocal: '',
    loadIndustryTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignMediaRfp = this.get('adCampaignMediaRfp');

      if (!adCampaignMediaRfp.get('targetingIndustry')) {
        return [];
      }

      let targetingIndustryArray = adCampaignMediaRfp.get('targetingIndustry').split(',');
      let returnArray = [];

      for (let i = 0; i < targetingIndustryArray.length; i++) {
        returnArray[i] = yield this.store.findRecord('industry-type', targetingIndustryArray[i]);
      } //for


      this.set('industryArray', returnArray);
    }),
    loadLanguageTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignMediaRfp = this.get('adCampaignMediaRfp');

      if (!adCampaignMediaRfp.get('targetingLanguage')) {
        return [];
      }

      let targetingLanguageArray = adCampaignMediaRfp.get('targetingLanguage').split(',');
      let returnArray = [];

      for (let i = 0; i < targetingLanguageArray.length; i++) {
        returnArray[i] = yield this.store.findRecord('language', targetingLanguageArray[i]);
      } //for


      this.set('languageArray', returnArray);
    }),
    loadLocationTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignMediaRfp = this.get('adCampaignMediaRfp');

      if (!adCampaignMediaRfp.get('targetingLocation')) {
        return [];
      }

      let targetingLocationArray = adCampaignMediaRfp.get('targetingLocation').split(',');
      let returnArray = [];

      for (let i = 0; i < targetingLocationArray.length; i++) {
        let model = '';

        if (targetingLocationArray[i] === "City") {
          model = "location-city";
        } else if (targetingLocationArray[i] === "State") {
          model = "location-state";
        } else {
          model = "location-country";
        }

        let location = yield this.store.findRecord(model, targetingLocationArray[i + 1]);
        i++;
        returnArray.push(location);
      } //for


      this.set('locationArray', returnArray);
    }),
    loadMediaRfpBodyAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adCampaignMediaRfpBodyId = this.get('adCampaignMediaRfpBody').id;
      let attachments = yield this.store.query('media-rfp-body-attachment', {
        adCampaignMediaRfpBody: adCampaignMediaRfpBodyId,
        page: page,
        pageSize: pagingObj.pageSize
      }); // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }

      Ember.set(pagingObj, "result", attachments); //return attachments;
    }).restartable(),
    loadMediaRfpAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adCampaignMediaRfpId = this.get('adCampaignMediaRfp').id;
      let attachments = yield this.store.query('media-rfp-attachment', {
        adCampaignMediaRfp: adCampaignMediaRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      }); // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }

      Ember.set(pagingObj, "result", attachments); //return attachments;
    }).restartable(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('loadIndustryTaskInstance', this.loadIndustryTask.perform());
      this.set('loadLanguageTaskInstance', this.loadLanguageTask.perform());
      this.set('loadLocationTaskInstance', this.loadLocationTask.perform());
    },

    // industryArray: computed("adCampaignMediaRfp.targetingIndustry", function() {
    // }),
    actions: {
      nothing() {}

    }
  });

  _exports.default = _default;
});