define("adtikir3v1/pods/components/adk-vendor-rfp-agency-selection-tbd/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4K07M8ut",
    "block": "{\"symbols\":[\"result\"],\"statements\":[[4,\"adk-standard-paging\",null,[[\"pagingObj\"],[[24,[\"pagingObj\"]]]],{\"statements\":[[0,\"  \"],[5,\"models-table\",[],[[\"@data\",\"@columns\",\"@showComponentFooter\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@showGlobalFilter\",\"@useNumericPagination\",\"@doFilteringByHiddenColumns\",\"@filteringIgnoreCase\",\"@multipleColumnsSorting\",\"@showPageSize\",\"@columnComponents\"],[[23,1,[]],[22,\"columns\"],false,false,false,false,false,false,false,true,false,[28,\"hash\",null,[[\"row-add\"],[[28,\"component\",[\"adk-models-table-add-button\"],[[\"componentAddAction\"],[[28,\"action\",[[23,0,[]],\"addAction\"],null]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-vendor-rfp-agency-selection-tbd/template.hbs"
    }
  });

  _exports.default = _default;
});