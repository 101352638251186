define("adtikir3v1/pods/campaign/details/vendor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7bCDD//8",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[5,\"paper-tabs\",[],[[\"@class\",\"@borderBottom\",\"@selected\",\"@onChange\"],[\"adkTab\",true,[22,\"currentRouteName\"],[28,\"action\",[[23,0,[]],\"noop\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"campaign.details.vendor.agency\",[28,\"href-to\",[\"campaign.details.vendor.agency\"],null]]],{\"statements\":[[0,\"\\n    Agency\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"campaign.details.vendor.media\",[28,\"href-to\",[\"campaign.details.vendor.media\"],null]]],{\"statements\":[[0,\"\\n    Media\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/vendor/template.hbs"
    }
  });

  _exports.default = _default;
});