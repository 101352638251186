define("adtikir3v1/pods/campaign/details/vendor/agency/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    currentRouteName: Ember.computed("router.currentRouteName", function () {
      let router = this.get("router");
      let currentRouteName = router.get("currentRouteName");
      currentRouteName = currentRouteName.split('.').slice(0, 5).join('.');
      return currentRouteName;
    }) // parentRouteName: computed("router.currentRouteName", function() {
    //   let router = this.get("router");
    //   let currentRouteName = router.get("currentRouteName");
    //   currentRouteName = currentRouteName.split('.').slice(0, 4).join('.');
    //   return currentRouteName;    
    // }), 
    // actions: {
    //   noop() {}
    // }

  });

  _exports.default = _default;
});