define("adtikir3v1/pods/components/adk-dashboard-targeting/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);

      this.setProperties({
        adCampaign: this.get('adCampaign'),
        adCampaignDetail: this.get('adCampaignDetail'),
        targeting: this.get('targeting') // targetCountries:  this.get('targetCountries'),
        // targetLanguages:  this.get('targetLanguages'),

      });
    }

  });

  _exports.default = _default;
});