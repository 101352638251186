define("adtikir3v1/components/timeline/timeline-image", ["exports", "ember-vertical-timeline/components/timeline/timeline-image"], function (_exports, _timelineImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _timelineImage.default;
    }
  });
});