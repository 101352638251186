define("adtikir3v1/pods/components/adk-models-table-display-color/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VrtGoivs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"color-box\"],[8],[0,\"\\n    \"],[1,[28,\"spectrum-color-picker\",null,[[\"disabled\",\"color\",\"preferredFormat\",\"onChange\",\"showButtons\",\"showPaletteOnly\",\"moveFiresChange\",\"showSelectionPalette\",\"hideAfterPaletteSelect\",\"togglePaletteOnly\",\"togglePaletteMoreText\",\"togglePaletteLessText\",\"palette\"],[[28,\"not\",[[23,0,[\"editMode\"]]],null],[23,0,[\"record\",\"workFlowColor\"]],\"hex\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"record\",\"workFlowColor\"]]],null]],null],false,true,true,true,true,true,\"MORE\",\"LESS\",\"[\\n            [\\\"#000\\\",\\\"#444\\\",\\\"#666\\\",\\\"#999\\\",\\\"#ccc\\\",\\\"#eee\\\",\\\"#f3f3f3\\\",\\\"#fff\\\"],\\n            [\\\"#f00\\\",\\\"#f90\\\",\\\"#ff0\\\",\\\"#0f0\\\",\\\"#0ff\\\",\\\"#00f\\\",\\\"#90f\\\",\\\"#f0f\\\"],\\n            [\\\"#f4cccc\\\",\\\"#fce5cd\\\",\\\"#fff2cc\\\",\\\"#d9ead3\\\",\\\"#d0e0e3\\\",\\\"#cfe2f3\\\",\\\"#d9d2e9\\\",\\\"#ead1dc\\\"],\\n            [\\\"#ea9999\\\",\\\"#f9cb9c\\\",\\\"#ffe599\\\",\\\"#b6d7a8\\\",\\\"#a2c4c9\\\",\\\"#9fc5e8\\\",\\\"#b4a7d6\\\",\\\"#d5a6bd\\\"],\\n            [\\\"#e06666\\\",\\\"#f6b26b\\\",\\\"#ffd966\\\",\\\"#93c47d\\\",\\\"#76a5af\\\",\\\"#6fa8dc\\\",\\\"#8e7cc3\\\",\\\"#c27ba0\\\"],\\n            [\\\"#c00\\\",\\\"#e69138\\\",\\\"#f1c232\\\",\\\"#6aa84f\\\",\\\"#45818e\\\",\\\"#3d85c6\\\",\\\"#674ea7\\\",\\\"#a64d79\\\"],\\n            [\\\"#900\\\",\\\"#b45f06\\\",\\\"#bf9000\\\",\\\"#38761d\\\",\\\"#134f5c\\\",\\\"#0b5394\\\",\\\"#351c75\\\",\\\"#741b47\\\"],\\n            [\\\"#600\\\",\\\"#783f04\\\",\\\"#7f6000\\\",\\\"#274e13\\\",\\\"#0c343d\\\",\\\"#073763\\\",\\\"#20124d\\\",\\\"#4c1130\\\"]\\n        ]\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-models-table-display-color/template.hbs"
    }
  });

  _exports.default = _default;
});