define("adtikir3v1/pods/campaign/details/vendor/media/clientbrief/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    //adkSessionAccount: service(),
    loadMediaRfpAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adCampaign = this.get('adCampaign');
      let attachments = yield this.store.query('media-rfp-attachment', {
        adCampaign: adCampaign.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", attachments); //return attachments;
    }),
    //loadMediaRfpAttachmentTask
    saveMediaRfpAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentObj) {
      let adCampaignMediaRfpSetting = this.get('adCampaignMediaRfpSetting');
      let attachment = this.store.createRecord('media-rfp-attachment', {
        attachFile: attachmentObj.attachFile,
        description: attachmentObj.description,
        adCampaignMediaRfpSetting: adCampaignMediaRfpSetting
      });

      try {
        attachment = yield attachment.save();
        return attachment;
      } catch (e) {
        throw e;
      }
    }).drop(),
    actions: {
      saveAndCreateRfp: function () {}
    }
  });

  _exports.default = _default;
});