define("adtikir3v1/pods/components/adk-vendor-media-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageSize: 5,
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    removeList: Ember.A(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('vendorMediaDetailList', {
        page: 0,
        pageSize: this.get('pageSize'),
        action: this.loadVendorMediaDetailTask,
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },

    loadVendorMediaDetailTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let vendorMedia = document.getElementById("vendorMedia");

      if (vendorMedia) {
        let currentCard = this.element;
        currentCard.appendChild(vendorMedia);
        this.set('showEditVendorMediaCard', false);
      }

      let myVendorMediaDetail = yield this.store.query('my-vendor-media-detail', {
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "page", page);
      Ember.set(pagingObj, "result", myVendorMediaDetail);
    }),
    loadMediaDetailLocationTask: (0, _emberConcurrency.task)(function* (mediaDetail) {
      let locations = yield this.store.query('location', {
        vendorMediaDetail: mediaDetail.id
      });
      this.set("locationList", locations.toArray());
    }).restartable(),
    addNewMediaDetail: (0, _emberConcurrency.task)(function* () {
      this.set('showAddMediaDetail', true);
      this.set("locationList", Ember.A());
      this.set("newSelectedMediaType", Ember.A()); // isNew = true shows create form, false shows edit form 

      this.set("isNew", true);
      let mediaDetail = yield this.store.createRecord('my-vendor-media-detail');
      this.set("newMediaDetail", mediaDetail); // close the edit form when creating new media

      this.set('showEditVendorMediaCard', false);
      this.actions.removeActiveClass.apply(this);
    }),
    //addNewMediaDetail - open create media box
    createMediaDetail: (0, _emberConcurrency.task)(function* (mediaDetail) {
      let adkToast = this.get('adkToast');
      let locationList = this.get('locationList');
      let selectedMediaType = this.get("newSelectedMediaType");
      mediaDetail.set('mediaType', selectedMediaType);

      try {
        yield mediaDetail.save();
        let parentId = mediaDetail.id;
        let parentField = "vendorMediaDetail";

        for (let i = 0; i < locationList.length; i++) {
          if (locationList[i].adkNewFlag) {
            let location = this.store.createRecord('location', {
              parentId: parentId,
              parentField: parentField,
              locationableType: locationList[i].constructor.modelName,
              LocationableId: locationList[i].id
            });
            yield location.save();
            locationList[i].set('adkNewFlag', false);
          }
        }

        this.set("isNew", false);
        this.set('showAddMediaDetail', false);
        adkToast.display("Media created successfully");
        this.set('vendorMediaDetailList', {
          page: 0,
          pageSize: this.get('pageSize'),
          action: this.loadVendorMediaDetailTask,
          result: null,
          refresh: true,
          type: "emberdata"
        });
      } catch (e) {
        adkToast.display("Failed to create media", "error");
      }
    }),
    //createMediaDetail
    deleteMediaDetailTask: (0, _emberConcurrency.task)(function* () {
      let mediaDetail = this.get('mediaDetail');
      let adkToast = this.get('adkToast');
      yield this.loadMediaDetailLocationTask.perform(mediaDetail);
      let parentId = mediaDetail.id;
      let parentField = "vendorMediaDetail";
      let locationList = this.get('locationList');

      try {
        for (let i = 0; i < locationList.length; i++) {
          let id = parentField + '!!!' + parentId + '!!!' + locationList[i].constructor.modelName + '!!!' + locationList[i].id;
          let record = yield this.store.findRecord('location', id);
          yield record.destroyRecord();
        }

        yield mediaDetail.destroyRecord();
        this.set('openConfirmationDialogBox', false);
        adkToast.display("Media deleted successfully");
        this.set('vendorMediaDetailList', {
          page: 0,
          pageSize: this.get('pageSize'),
          action: this.loadVendorMediaDetailTask,
          result: null,
          refresh: true,
          type: "emberdata"
        });
      } catch (e) {
        adkToast.display("Failed to delete media", "error");
      }

      this.actions.removeActiveClass.apply(this);
    }),

    //deleteMediaDetailTask
    setActiveVendorDetail(mdCard) {
      let nextMdCard = mdCard;
      this.actions.removeActiveClass.apply(this);
      mdCard.classList.add('adkListCardActive');
      this.set('activeMdClass', mdCard);
      let vendorMedia = document.getElementById("vendorMedia");
      nextMdCard.parentElement.insertBefore(vendorMedia, nextMdCard.nextSibling);
      vendorMedia.style.display = "block";
      vendorMedia.classList.add('flex-100');
      nextMdCard.scrollIntoView();
    },

    //setActiveVendorDetail
    actions: {
      editMedia(mediaDetail, event) {
        let currentMdCard = event.currentTarget.parentElement.parentElement;
        this.setActiveVendorDetail(currentMdCard);
        this.set("mediaDetail", mediaDetail);
        this.set('showEditVendorMediaCard', true);
        this.loadMediaDetailLocationTask.perform(mediaDetail);
        this.set("removeList", Ember.A());
        this.set('selectedMediaType', mediaDetail.mediaType); // close the create form when editing new media

        this.set("isNew", false);
        this.set("showAddMediaDetail", false);
      },

      //editMedia 
      cancelEdit() {
        let mediaDetail = this.get("mediaDetail");
        mediaDetail.rollback();
        this.set('showEditVendorMediaCard', false);
        this.actions.removeActiveClass.apply(this);
      },

      //cancelEdit
      removeActiveClass() {
        if (this.activeMdClass) {
          this.activeMdClass.classList.remove("adkListCardActive");
        }

        this.set('activeMdClass', null);
      },

      //removeActiveClass
      doneEdit() {
        this.set('showEditVendorMediaCard', false);
        this.actions.removeActiveClass.apply(this);
      },

      //doneEdit
      cancelCreate() {
        this.set('showAddMediaDetail', false);
        this.set('isNew', false);
      },

      //cancelCreate
      cancelDeleteVendorMedia() {
        this.set('openConfirmationDialogBox', false);
      },

      //cancelDeleteVendorMedia
      deleteVendorMedia(mediaDetail, event) {
        this.set("mediaDetail", mediaDetail);
        this.set('dialogOrigin', event.currentTarget);
        this.set('openConfirmationDialogBox', true);
      } //deleteVendorMedia


    } //actions

  });

  _exports.default = _default;
});