define("adtikir3v1/pods/components/adk-locations/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('countryOptions', []); //set removeList
      // this.set('removeList', []);

      this.clearCountry();
      this.clearState();
      this.clearCity();
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.loadLocationCountryTask.perform();
    },

    loadLocationCountryTask: (0, _emberConcurrency.task)(function* () {
      // let countries = yield this.store.findAll('location-country');
      let countries = this.get('countries');
      let locationList = this.get('locationList');
      let countriesArray = countries.toArray();
      let countryOptions = yield countriesArray.filter(selected => !locationList.includes(selected));
      this.set('countryOptions', countryOptions);
    }),
    loadLocationStateTask: (0, _emberConcurrency.task)(function* (loadDb = true) {
      let locationList = this.get('locationList');
      let stateArray;

      if (loadDb) {
        let states = yield this.store.query('location-state', {
          country: this.get('selectedCountry').id
        });
        stateArray = states.toArray();
      } else {
        stateArray = [...this.get('stateOptions')];
      }

      let stateOptions = yield stateArray.filter(selected => !locationList.includes(selected));
      this.set('stateOptions', stateOptions);
    }),
    loadLocationCityTask: (0, _emberConcurrency.task)(function* (loadDb = true) {
      let locationList = this.get('locationList');
      let cityArray;

      if (loadDb) {
        let cities = yield this.store.query('location-city', {
          state: this.get('selectedState').id
        });
        cityArray = cities.toArray();
      } else {
        cityArray = [...this.get('cityOptions')];
      }

      let cityOptions = yield cityArray.filter(selected => !locationList.includes(selected));
      this.set('cityOptions', cityOptions);
    }),

    clearCountry() {
      this.set('selectedCountry', '');
    },

    clearState() {
      this.set('selectedState', '');
      this.set('stateOptions', []);
    },

    clearCity() {
      this.set('selectedCity', '');
      this.set('cityOptions', []);
    },

    updateCountryTask: (0, _emberConcurrency.task)(function* (country) {
      this.clearState();
      this.clearCity();
      this.set('selectedCountry', country);
      yield this.loadLocationStateTask.perform();
    }).restartable(),
    updateStateTask: (0, _emberConcurrency.task)(function* (state) {
      this.clearCity();
      this.set('selectedState', state);
      yield this.loadLocationCityTask.perform();
    }).restartable(),
    dragLocationTask: (0, _emberConcurrency.task)(function* (record, event) {
      let locationList = this.get('locationList');
      let removeList = this.get('removeList');
      let foundFlag = false;

      for (let i = 0; i < removeList.length; i++) {
        if (record.constructor.modelName === removeList[i].constructor.modelName && record.id === removeList[i].id) {
          foundFlag = true;
          removeList.removeObject(removeList[i]);
          break;
        }
      }

      if (!foundFlag) {
        record.set('adkNewFlag', true);
      }

      foundFlag = false;

      for (let i = 0; i < locationList.length; i++) {
        if (record.constructor.modelName === 'location-country') {
          //check for state and city
          if (locationList[i].constructor.modelName !== 'location-country') {
            let country = yield locationList[i].get('country');

            if (country.id === record.id) {
              foundFlag = true;
              alert('you cannot add this country to the list, as some states or cities already in the list');
              return;
            }
          }
        } else if (record.constructor.modelName === "location-state") {
          //check for city only
          if (locationList[i].constructor.modelName === 'location-city') {
            let state = yield locationList[i].get('state');

            if (state.id === record.id) {
              alert('you cannot add this state the list, as some cities already in the list');
              foundFlag = true;
              return;
            }
          }
        }
      } //for


      locationList.pushObject(record);

      if (record.constructor.modelName === "location-country") {
        yield this.loadLocationCountryTask.perform();
        this.set("selectedCountry", '');
        this.clearState();
        this.clearCity();
      } else if (record.constructor.modelName === "location-state") {
        yield this.loadLocationStateTask.perform(false);
        this.set("selectedState", '');
        this.clearCity();
      } else if (record.constructor.modelName === "location-city") {
        yield this.loadLocationCityTask.perform(false);
        this.set("selectedCity", '');
      } //console.dir(record); 


      let element = event.target.element.children[0];
      Ember.run.later(this, function () {
        //run later .. wait ember refresh the list first
        element.scrollTop = element.scrollHeight;
      }, 500); //  elementList[elementList.length-1].scrollIntoView();  
      //  event.target.element.children[0].scrollIntoView();
      //  console.dir(event.target.element.children);
      //  console.dir(event.target.element.children[0].children[]);
      //  console.dir(event.target);
      //  console.dir(event);
    }).restartable(),
    // saveTask: task(function *() {
    //   let adkToast = this.get('adkToast');
    //   let locationList = this.get('locationList')
    //   let removeList = this.get('removeList');
    //   let parentField = this.get('parentField');
    //   let parentId = this.get('mediaDetail').id;
    //   for(let i=0; i< locationList.length; i++) {
    //     if(locationList[i].adkNewFlag) {
    //       let location = this.store.createRecord('location', {
    //           parentId: parentId,
    //           parentField: parentField,
    //           locationableType: locationList[i].constructor.modelName,
    //           LocationableId: locationList[i].id
    //       })
    //       yield location.save();
    //       locationList[i].set('adkNewFlag', false);
    //     }
    //   }
    //   for (let i = removeList.length -1; i>= 0; i--) {
    //     let id = parentField + '!!!' +
    //               parentId + '!!!' +
    //               removeList[i].constructor.modelName + '!!!' +
    //               removeList[i].id;
    //       let record = yield this.store.findRecord('location', id);
    //       yield record.destroyRecord();
    //       removeList.removeObject(removeList[i]);
    //   }
    //   adkToast.display("Location updated successfully", "warning", 3000);
    // }).restartable(),
    actions: {
      removeLocation(location) {
        let locationList = this.get('locationList');
        let removeObj = locationList.filter(function (item) {
          return item.get('id') === Ember.get(location, 'id');
        });

        if (!removeObj[0].adkNewFlag) {
          this.get('removeList').pushObject(removeObj[0]);
        }

        this.get('locationList').removeObject(removeObj[0]);
        this.loadLocationCountryTask.perform();
        this.clearCountry();
        this.clearState();
        this.clearCity();
      },

      noop() {}

    }
  });

  _exports.default = _default;
});