define("adtikir3v1/pods/components/adkcard-ad-status/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    showConfirmationDialog: false,
    adComplete: (0, _emberConcurrency.task)(function* (ad) {
      let adkToast = this.get('adkToast');
      ad.set('action', "completeAd"); // try {
      //     yield ad.save();
      //     yield ad.reload();
      //     adkToast.display("Ad mark as completed", "warning", 3000);
      // } catch (e) {
      //     if(ad.get('hasDirtyAttributes')) {
      //         ad.rollbackAttributes();
      //     }
      //     adkToast.display("Ad failed to mark as complete", "error", 3000);
      // }

      yield ad.save();
      adkToast.display("Ad mark as completed", "warning", 3000);
      ad.reload();
      this.actions.closeConfirmationDialog.apply(this);
    }),
    adCancel: (0, _emberConcurrency.task)(function* (ad) {
      let adkToast = this.get('adkToast');
      ad.set('action', "cancelAd"); // try {
      //     yield ad.save();
      //     yield ad.reload();
      //     adkToast.display("Ad mark as canceled", "warning", 3000);
      // } catch (e) {
      //     if(ad.get('hasDirtyAttributes')) {
      //         ad.rollbackAttributes();
      //     }
      //     adkToast.display("Ad failed to mark as cancel", "error", 3000);
      // }

      yield ad.save();
      adkToast.display("Ad mark as canceled", "warning", 3000);
      ad.reload();
      this.actions.closeConfirmationDialog.apply(this);
    }),
    adDelete: (0, _emberConcurrency.task)(function* (ad) {
      let adkToast = this.get('adkToast');
      ad.set('action', "deleteAd"); // try {
      //     yield ad.save();
      //     yield ad.reload();
      //     adkToast.display("Ad deleted successfully", "warning", 3000);
      // } catch (e) {
      //     if(ad.get('hasDirtyAttributes')) {
      //         ad.rollbackAttributes();
      //     }
      //     adkToast.display("Ad failed to delete", "error", 3000);
      // }

      yield ad.save();
      adkToast.display("Ad deleted successfully", "warning", 3000);
      ad.reload();
      this.actions.closeConfirmationDialog.apply(this);
    }),
    actions: {
      showConfirmationDialog(record) {
        if (record == "complete") {
          this.set('showConfirmationDialog', 1);
        } else if (record == "cancel") {
          this.set('showConfirmationDialog', 2);
        } else if (record == "delete") {
          this.set('showConfirmationDialog', 3);
        }
      },

      closeConfirmationDialog() {
        this.set('showConfirmationDialog', false);
      },

      activateAdInterAction() {
        let l = confirm("Once assigned, it cannot be undone. Please confirm");

        if (l === false) {
          return;
        }

        let ad = this.get('ad');
        this.assignToInternalAction(true, ad);
      },

      activateApproveMiscBudgetAction() {
        let l = confirm("Approved the misc budget, once confirm, it cannot be undone. Please confirm");

        if (l === false) {
          return;
        }

        let ad = this.get('ad');
        this.approveMiscBudgetAction(true, ad);
      }

    }
  });

  _exports.default = _default;
});