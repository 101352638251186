define("adtikir3v1/components/timeline-view-item", ["exports", "@abcum/ember-charts/components/timeline-view-item"], function (_exports, _timelineViewItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _timelineViewItem.default;
    }
  });
});