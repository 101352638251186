define("adtikir3v1/pods/campaign/details/vendor/index/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    adkSessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),

    // Transition to first menu item
    beforeModel() {
      this.get('router').transitionTo('campaign.details.vendor.agency');
    }

  });

  _exports.default = _default;
});