define("adtikir3v1/pods/components/adk-models-table-add-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      addAction() {
        if (this.get('componentAddAction')) {
          this.get('componentAddAction')(this.get("record"));
        }
      }

    }
  });

  _exports.default = _default;
});