define("adtikir3v1/pods/campaign/details/vendor/agency/clientbrief/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OWw0v0kz",
    "block": "{\"symbols\":[],\"statements\":[[5,\"adk-vendor-clientbrief\",[],[[\"@adCampaign\",\"@adCampaignDetail\",\"@adCampaignRfpSetting\",\"@targeting\",\"@saveMe\",\"@fileFor\",\"@saveAttachmentTask\",\"@loadAttachmentTask\"],[[22,\"adCampaign\"],[22,\"adCampaignDetail\"],[22,\"adCampaignRfpSetting\"],[22,\"targeting\"],[28,\"action\",[[23,0,[]],\"saveAndCreateRfp\"],null],\"campaignRfp\",[23,0,[\"saveAttachmentTask\"]],[23,0,[\"loadAttachmentTask\"]]]]],[0,\" \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/vendor/agency/clientbrief/template.hbs"
    }
  });

  _exports.default = _default;
});