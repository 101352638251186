define("adtikir3v1/models/email-group-owner", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    CompanyId: attr('string'),
    email: attr('string')
  });

  _exports.default = _default;
});