define("adtikir3v1/pods/campaign/details/dashboard/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {
  //   typeOf
  // } from '@ember/utils';
  // import EmberError from '@ember/error';
  var _default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    loadCompanyRolesTask: (0, _emberConcurrency.task)(function* (component) {
      let adkSessionAccount = this.get('adkSessionAccount');
      let myCompany = yield adkSessionAccount.get('myCompany');
      yield myCompany.reload();
      let companyRoles = yield myCompany.get('companyRoles');
      component.set("companyRoles", companyRoles);
    }),
    loadAdCampaignUserRoleTask: (0, _emberConcurrency.task)(function* (component) {
      let adCampaign = yield this.get('adCampaign');
      let adCampaignUserRoles = yield this.store.query('adcampaign-userrole', {
        AdCampaignId: adCampaign.id
      });
      component.set("adCampaignUserRoles", adCampaignUserRoles);
    }),
    loadVendorRolesTask: (0, _emberConcurrency.task)(function* (component) {
      let adCampaign = yield this.get('adCampaign');
      let vendorRoles = yield this.store.query('vendorRole', {
        adCampaign: adCampaign.id
      });
      component.set("vendorRoles", vendorRoles);
    }),
    steps: Ember.A([{
      title: "Steps to Manage a Campaign",
      element: '#dashboard',
      intro: 'Dashboard to document Goals, Customers, Success Factors.'
    }, {
      element: '#targeting',
      intro: 'Targeting to identify who are your customer.'
    }, {
      element: '#ads',
      intro: 'Create initial ads, estimated budgets, timelines.'
    }, {
      element: '#vendor',
      intro: 'Assign the project to internal or send out to outside vendors for bidding for the entire campaign.'
    }, {
      element: '#timeline',
      intro: 'Review the Ads timeline.'
    }, {
      element: '#financials',
      intro: 'Get budget to be reviewed and approved by Management'
    } // {
    //   element: '#activities',
    //   intro:'Click here to create or add a new campaign.'
    // },
    // {
    //   element: '#reports',
    //   intro:'Click here to create or add a new campaign.'
    // },
    ]),
    actions: {
      updateAdCampaign: function (action, saveToDb, adCampaign) {
        let adkToast = this.get('adkToast'); //let controller = this;

        if (!saveToDb) {
          return;
        }

        if (!adCampaign) {
          adCampaign = this.get('adCampaign');
        } //save the action here


        adCampaign.set('action', action);
        return adCampaign.save().then(function () {
          adkToast.display("Data Saved Success", "warning");
          return true; //controller.transitionToRoute('campaign.details', adCampaign`);
        }).catch(function (e) {
          let title = '';

          if (adCampaign.get('hasDirtyAttributes')) {
            adCampaign.rollbackAttributes();
          }

          if (e.errors && e.errors.length > 0) {
            e.errors.map(function (eitem) {
              title += eitem.title + '\n';
            });
            title = ": " + title;
            adkToast.display("Campaign Save Failed" + title, "error");
            return false;
          } // console.log(arguments);
          // console.log(e);
          // controller.transitionToRoute('error', e);


          return false;
        } //funcition fail
        ); //catch
      },
      //updateAdCampaign
      updateAdCampaignDetail: function (action, saveToDb, adCampaignDetail, fieldName) {
        let adkToast = this.get('adkToast');

        if (!saveToDb) {
          return false;
        }

        if (!adCampaignDetail) {
          adCampaignDetail = this.get('adCampaignDetail');
        } //save the action here


        adCampaignDetail.set('action', action);

        if (fieldName) {
          adCampaignDetail.set(fieldName, adCampaignDetail.get(fieldName));
        }

        return adCampaignDetail.save().then(function () {
          adkToast.display("Data Saved Success", "warning");
          return true; //controller.transitionToRoute('campaign.details', adCampaign`);
        }).catch(function (e) {
          let title = '';

          if (adCampaignDetail.get('hasDirtyAttributes')) {
            adCampaignDetail.rollbackAttributes();
          }

          if (e.errors && e.errors.length > 0) {
            e.errors.map(function (eitem) {
              title += eitem.title + '\n';
            });
            title = ": " + title;
            adkToast.display("Campaign Save Failed" + title, "error");
            return false;
          } // console.log(arguments);
          // console.log(e);
          // controller.transitionToRoute('error', e);


          return false;
        } //funcition fail
        ); //catch
      },
      //updateAdCampaignDetail
      cancelModification: function () {
        if (this.adCampaign.get('hasDirtyAttributes')) {
          // this.myCompany.rollbackAttributes();
          this.adCampaign.rollbackAttributes();
        }

        if (this.adCampaignDetail.get('hasDirtyAttributes')) {
          this.adCampaignDetail.rollbackAttributes();
        }
      },

      //cancelModification
      loadVendorRolesAction() {
        return this.loadVendorRolesTask.perform();
      },

      introComplete() {
        let me = this.get('adkSessionAccount').me;
        me.set("action", "updateTraining");
        me.set("training", "2");
        me.save();
      },

      parentSaveMedia: function () {}
    } //action

  });

  _exports.default = _default;
});