define("adtikir3v1/pods/components/adk-vendor-agency-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    saveIndustryTypeTask: (0, _emberConcurrency.task)(function* () {
      let myVendor = this.get('myVendor');
      let adkToast = this.get('adkToast');

      try {
        yield myVendor.save();
        this.set('editMode', false);
        adkToast.display("Data updated successfully");
      } catch (e) {
        adkToast.display("Failed to update.");
      }
    }),
    actions: {
      cancelEdit() {
        let myVendor = this.get('myVendor');
        myVendor.rollback();
        this.set('editMode', false);
      },

      addNewIndustryType() {
        this.set('editMode', true);
      },

      editAgency() {
        let myVendor = this.get('myVendor');
        myVendor.startTrack();
        this.set('editMode', true);
      }

    }
  });

  _exports.default = _default;
});