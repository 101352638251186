define("adtikir3v1/adapters/application", ["exports", "ember-data/adapters/json-api", "@jftechnology/ember-keycloak-auth/mixins/keycloak-adapter", "adtikir3v1/config/environment"], function (_exports, _jsonApi, _keycloakAdapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {
  //   inject as service
  // } from '@ember/service';
  // import RSVP from "rsvp";
  var _default = _jsonApi.default.extend(_keycloakAdapter.default, {
    //adkSessionAccount: service(),
    // host: 'http://localhost:3000',
    host: _environment.default.apiHost // this is keycloak auth 1.0.2 code.. not working... 
    // ajax(url, type, options)  {
    //   //return this._super(url, type, options);
    //   console.log(this.adkSessionAccount.session.wrappedCall);
    //   console.log(this.adkSessionAccount.session.wrappedCall(() => this._super(url, type, options)));
    //   return this.adkSessionAccount.session.wrappedCall(() => this._super(url, type, options));
    // }

  });

  _exports.default = _default;
});