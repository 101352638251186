define("adtikir3v1/pods/campaign/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { getOwner } from '@ember/application';
  var _default = Ember.Controller.extend({
    adkSessionAccount: Ember.inject.service(),
    queryParams: ['pageSize', 'page', 'query'],
    //router: service(),
    searchText: '',
    pageSize: 8,
    page: 0,
    query: '',
    // showCampaignMgrDialog: 'false',
    // dialogOrigin:'',
    totalPage: Ember.computed('model.adCampaigns.meta.page.total', 'pageSize', function () {
      let total = 0;

      if (this.model.adCampaigns.meta && this.model.adCampaigns.meta.page.total) {
        total = this.model.adCampaigns.meta.page.total;
      }

      let pageSize = 1;

      if (this.pageSize > 0) {
        pageSize = this.pageSize;
      }

      total = Math.ceil(total / pageSize);
      return total;
    }),
    pageArray: Ember.computed("totalPage", function () {
      let totalPage = this.get('totalPage');
      if (totalPage == 0) return [];
      return Array.from(Array(totalPage).keys(), x => x + 1);
    }),
    steps: Ember.computed(function () {
      return [{
        element: '#campaignManager',
        intro: 'Your authorized campaigns are all listed below.'
      }, {
        element: '#agencyMenu',
        intro: 'Agency Menu is visible for companies registered as Agency.'
      }, {
        element: '.companies',
        intro: 'You can belong to multiple companies. Switch companies here.'
      }, {
        element: '#addCompany',
        intro: 'Add a new company here.'
      }, {
        element: '#profile',
        intro: 'Click here to change your profile and company settings.'
      }, {
        element: '#page',
        intro: 'Only 8 campaigns are displayed at a time. Click here to see the next 8 or jump to any number.'
      }, {
        element: '#addCampaign',
        intro: 'Click here to create or add a new campaign.',
        position: 'left'
      }];
    }),
    actions: {
      setPage(number) {
        number = number - 1;

        if (number >= this.totalPage) {
          number = this.totalPage - 1;
        } else if (number < 0) {
          number = 0;
        }

        this.set('page', number);
      },

      nextPage() {
        this.actions.setPage.call(this, this.get('page') + 2); //this.actions.setPage.call(this, this.get('page') + 1);
      },

      prevPage() {
        this.actions.setPage.call(this, this.get('page')); //this.actions.setPage.call(this, this.get('page')-1);
      },

      searchMe() {
        this.set('query', this.get('searchText'));
      },

      clearSearch() {
        this.set('query', '');
        this.set('searchText', '');
      },

      introComplete() {
        let me = this.get('adkSessionAccount').me;
        me.set("action", "updateTraining");
        me.set("training", "1");
        me.save();
      },

      createTemplate() {
        console.log("****######*****");
        console.log('campaign createTemplate');
        console.log("****######*****");
      },

      deleteCampaign(adcampaign) {
        adcampaign.destroyRecord();
        this.set('showCampaignMgrDialog', false);
      },

      openDialogDeleteCampaign(adcampaign, event) {
        this.set('adcampaign', adcampaign);
        this.set('dialogOrigin', event.currentTarget);
        this.set('showCampaignMgrDialog', true);
      },

      closeDialogDeleteCampaign() {
        this.set('showCampaignMgrDialog', false);
      },

      cancelAction() {}

    },
    cardlayout: false,
    selectedBudgetStatus: 'approved',
    selectedAssetAction: 'review'
  });

  _exports.default = _default;
});