define("adtikir3v1/pods/components/adk-vendor-rfp-agency-selection-tbd/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import adkDateTime  from '../../../utils/adk-date-time';
  //import moment from 'moment'
  // import {
  //   computed
  // } from '@ember/object';
  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),

    didReceiveAttrs() {
      //Need to initialize the values of the selectedItems first.
      this._super(...arguments);

      this.set('columns', [{
        propertyName: 'vendor.companyName',
        title: 'Company Name'
      }, // {
      //   propertyName: 'country'
      // },
      // {
      //   propertyName: 'languages'
      // },
      // {
      //   propertyName: 'industry'
      // },
      // {
      //   propertyName: 'ratings'
      // },
      {
        propertyName: 'vendor.briefDescription',
        title: 'Description'
      }, {
        "component": "row-add",
        "useFilter": false,
        "mayBeHidden": false,
        "className": "adkCenter adkModelTableAddButtonWidth" // "componentForSortCell": "select-all-rows-checkbox"

      }]); //set columns
      //     this.set('alreadySentInvitees', this.get('alreadySentInviteesTask').perform(this.get('proposal')));
      //     this.get('getRFPTask').perform();
    },

    //didReceiveAttrs
    actions: {
      addAction(vendorRecord) {
        //put the promise resolve here because this is specific to this component...
        //should not put in the add button compoent    
        let thisCompoenent = this;
        vendorRecord.get('vendor').then(function (vendor) {
          thisCompoenent.get('addList')(vendor);
        });
      } //     sendRFP(selectedItems) {
      //       let adkToast = this.get('adkToast');
      //       let component = this;
      //       let proposal = this.get('proposal');
      //       let sessionAccount = this.get('sessionAccount');
      //       let user = sessionAccount.get('user');
      //       let alreadySentInvitees = this.get('alreadySentInvitees');
      //       selectedItems.forEach(function (inviteeCompany) {
      //         //loop the hasmany relationship in proposal
      //         //array of id;
      //         let flag=true;
      //         //checking on duplication... 
      //         for (let i=0;i<alreadySentInvitees.value.length;i++) {
      //           if (alreadySentInvitees.value.objectAt(i).belongsTo('company').id() ==inviteeCompany.id) {
      //              flag = false;
      //              break;
      //           }
      //         }
      //         if (!flag) {
      //           adkToast.display('Company ' + inviteeCompany.companyName + ' already in invitee list', 'error', 800);
      //         } else 
      //         if (proposal.id) {
      //           //proposal invitedd
      //           //checks if proposal.id+proposal exists
      //           let proposalInviteeID = proposal.id.concat((inviteeCompany.companyName).replace(/\s+/g, ''));
      //           //console.log("adktable-agency-selection proposalInvitedID: ", proposalInvitedID);
      //           let proposalInvitee = component.store.createRecord('proposal-invitee', {
      //             id: proposalInviteeID,
      //             company: inviteeCompany,
      //             // company  : component.get('item.id'),
      //             proposal: proposal,
      //             invitedBy: user,
      //             // invitedDate : moment(new Date().getTime()).format('LLL'),
      //             invitedDate: new Date(),
      //           });
      //           // newProposalInvite.set('proposal',proposal);
      //           if (proposal.get('proposalInvitees') == null || proposal.get('proposalInvitees') == undefined) {
      //             proposal.set('proposalInvitees', A())
      //           }
      //           proposal.get('proposalInvitees').pushObject(proposalInvitee);
      //           proposalInvitee.save();
      //           //now do the proposal invited part...
      //           let proposalInvitedID = proposal.id.concat((inviteeCompany.companyName).replace(/\s+/g, '')).concat('invitation');
      //           let proposalInvited = component.store.createRecord('proposal-invited', {
      //               id: proposalInvitedID,
      //               advertiserID: sessionAccount.get('company').get('id'),
      //               advertiserName: sessionAccount.get('company').get('companyName'),         
      //           }); 
      //           let rfp = component.get('rfp');
      //           proposalInvited.set('deadlineProposal', proposal.get('deadlineProposal'));
      //           proposalInvited.set('rfp', rfp);
      //           proposalInvited.set('company',inviteeCompany);
      //           proposalInvited.save();
      //           let proposalInviteds = inviteeCompany.get('proposalInviteds');
      //           if (proposalInviteds == null || proposalInviteds == undefined) {
      //             proposalInviteds = A();
      //           }
      //           proposalInviteds.addObject(proposalInvited);
      //           inviteeCompany.save();
      //           adkToast.display('Invitation sent to ' + inviteeCompany.companyName, 'warning');
      //         }  //if proposal id
      //       });  //for each
      //       proposal.save();  //proposal save put last to save all at one time...
      //     },  //sendRFP


    } //actions 

  });

  _exports.default = _default;
});