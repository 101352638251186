define("adtikir3v1/models/activity", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com
  const {
    Model,
    attr // belongsTo,
    // hasMany

  } = _emberData.default;

  var _default = Model.extend({
    username: attr('string'),
    CompanyId: attr('string'),
    status: attr('string'),
    description: attr('string'),
    createdAt: attr('date'),
    displayActivityDate: Ember.computed('createdAt', function () {
      return (0, _moment.default)(this.get('activityDate')).format('lll');
    })
  });

  _exports.default = _default;
});