define("adtikir3v1/pods/components/adk-display-vendor-company-info/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    didReceiveAttrs() {
      this.loadCompanyInfoTask.perform();
    },

    loadCompanyInfoTask: (0, _emberConcurrency.task)(function* () {
      let companyInfo = yield this.store.findRecord('company', this.get('vendor').get('company').get('id'));
      this.set("companyInfo", companyInfo);
    }).restartable()
  });

  _exports.default = _default;
});