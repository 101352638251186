define("adtikir3v1/models/vendor-media-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    //    isMedia: attr('boolean'),
    //    isAgency: attr('boolean'),
    name: attr('string'),
    networkOrUrl: attr('string'),
    frequency: attr('string'),
    male1: attr('boolean'),
    male2: attr('boolean'),
    male3: attr('boolean'),
    male4: attr('boolean'),
    male5: attr('boolean'),
    male6: attr('boolean'),
    male7: attr('boolean'),
    male8: attr('boolean'),
    female1: attr('boolean'),
    female2: attr('boolean'),
    female3: attr('boolean'),
    female4: attr('boolean'),
    female5: attr('boolean'),
    female6: attr('boolean'),
    female7: attr('boolean'),
    female8: attr('boolean'),
    impressions: attr('number'),
    subscribers: attr('number'),
    coverage: attr('string'),
    userAvgRating: attr('number'),
    //portfolio:      attr('string'),
    description: attr('string'),
    isMarrried: attr('boolean'),
    hasChildren: attr('boolean'),
    incomeLevel: attr('boolean'),
    lowIncome: attr('boolean'),
    middleIncome: attr('boolean'),
    upperMiddleIncome: attr('boolean'),
    highIncome: attr('boolean'),
    targetIndustry: attr('boolean'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    mediaFormat: attr('string'),
    mediaFormatDetails: attr('string'),
    // vendorLocations:  hasMany('vendorLocation', { polymorphic: true}),
    locations: hasMany('location', {
      polymorphic: true
    }),
    targetIndustrySelection: hasMany('industry-type', {
      async: true
    }),
    //chnage to
    //targetIndustries : hasMany('industry-type', { async: true }),
    languages: hasMany('language', {
      async: true
    }),
    //dont use this... use mediaType
    mediaTypeId: attr('string'),
    mediaType: belongsTo('mediaType'),
    modelname: 'vendor media detail'
  });

  _exports.default = _default;
});