define("adtikir3v1/pods/campaign/details/vendor/agency/rfp/controller", ["exports", "ember-concurrency", "moment", "adtikir3v1/utils/adk-date-time"], function (_exports, _emberConcurrency, _moment, _adkDateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //controller for campaign.detail.vemdor.rfp.agency
  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    showDialogProposalDeadline: false,
    showDialogInvitee: false,
    showDialogCheckout: false,
    inviteeList: null,
    byBatch: false,
    firstTime: true,
    //in toggleByBatch.. trigger to load aProposalInviteeByCompany
    sentPanelFlag: true,
    sendPanelFlag: true,

    init() {
      this._super(...arguments);

      this.set('adCampaignProposalRequestList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAdCampaignProposalRequestListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('firstTime', true);
      this.set('aProposalInviteeByCompany', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAProposalInviteeByCompanyTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      }); //syeo disable for new interface
      // this.set('preferVendorList', {
      //   page: 0,
      //   pageSize: 20,
      //   action: this.get("loadPreferVendorTask"),
      //   result: null,
      //   refresh: false,
      //   type: "emberdata"
      // });
      // this.set('otherVendorList', {
      //   page: 0,
      //   pageSize: 20,
      //   action: this.get("loadOtherVendorTask"),
      //   result: null,
      //   refresh: false,
      //   type: "emberdata"
      // });

      this.set('inviteeList', []);
    },

    //init
    proposalDeadlineSetFlag: Ember.computed("adCampaign.proposalDeadline", function () {
      let adCampaign = this.get('adCampaign');

      if (adCampaign.get("proposalDeadline") === null) {
        return false;
      }

      return true;
    }),

    calDateDiff(startDate, endDate, percentage) {
      let totalDayDiff = endDate.getDate() - startDate.getDate();
      let dayDiff = 0;

      if (totalDayDiff > 1) {
        dayDiff = Math.floor(totalDayDiff * percentage / 100);
      }

      let returnDate = new Date();
      returnDate.setDate(startDate.getDate() + dayDiff);
      return (0, _moment.default)(returnDate);
    },

    inviteeListSearch(vendorCompanyId) {
      let inviteeList = this.get('inviteeList');
      return inviteeList.findIndex(obj => {
        if (obj.id === vendorCompanyId) {
          return true;
        }
      });
    },

    resetProposalDialog() {
      this.set('campaignProposalDeadline', '');
      this.set('campaignProposalDeadlineCenter', '');
      this.set('campaignProposalDeadlineLocal', '');
    },

    loadAdCampaignProposalRequestListTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let adCampaignProposalRequestListObj = this.get('adCampaignProposalRequestList');
      let result = yield this.store.query('ad-campaign-proposal-request-list', {
        adCampaign: this.adCampaign.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  
    loadAProposalInviteeTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let aProposalInviteeObj = this.get('aProposalInvitee');
      let result = yield this.store.query('a-proposal-invitee', {
        adCampaignProposalRequestList: this.get('respondList').id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  
    loadAProposalInviteeByCompanyTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let aProposalInviteeByCompanyObj = this.get('aProposalInviteeByCompany');
      let result = yield this.store.query('a-proposal-invitee', {
        adCampaign: this.get('adCampaign').id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  
    loadCreateInviteeListTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let inviteeListObj = this.get('createInviteeList');
      let inviteeList = yield this.get('inviteeList');
      Ember.set(pagingObj, 'result', inviteeList);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  
    //syeo disable for new interface
    // loadPreferVendorTask: task(function* (page, pagingObj) {
    //   let result = yield this.store.query('prefer-vendor', {
    //          page: page,
    //          pageSize: pagingObj.pageSize,
    //   }); //this.store    
    //   set(pagingObj, 'result', result);
    //   set(pagingObj, 'page', page);
    //   return true;
    // }).restartable(), 
    // loadOtherVendorTask: task(function* (page, pagingObj) {
    //   let result = yield this.store.query('list-vendor', {
    //          page: page,
    //          pageSize: pagingObj.pageSize,
    //          mediaType: ["campaign", "print"]
    //   }); //this.store    
    //   set(pagingObj, 'result', result);
    //   set(pagingObj, 'page', page);
    //   return true;
    // }).restartable(), 
    saveInviteeListTask: (0, _emberConcurrency.task)(function* () {
      let store = this.get('store');
      let adkToast = this.get('adkToast');
      let inviteeList = this.get('inviteeList');
      let adCampaignRfpSetting = this.get('adCampaignRfpSetting');
      let proposalRequestList = store.createRecord('adCampaignProposalRequestList', {
        countOfList: 0,
        adCampaignRfpSetting: adCampaignRfpSetting
      });

      try {
        yield proposalRequestList.save();
      } catch (e) {
        //if this fail... exit
        adkToast.display("Cannot create proposal request list", "error", 3000);
        this.set("inviteeList", []);
        this.set('showDialogCheckout', false);
        console.error(e);
        return false;
      }

      for (let i = inviteeList.length - 1; i >= 0; i--) {
        let invitee = store.createRecord('aProposalInvitee', {
          isPrefer: inviteeList[i].isPrefer,
          company: inviteeList[i].vendor,
          adCampaignProposalRequestList: proposalRequestList
        }); //put try here... if error occur.. staill can move to next invitee

        try {
          yield invitee.save();
          inviteeList.splice(i, 1); //by end.. the inviteeList should be empty
        } catch (e) {
          adkToast.display("Invitee " + invitee.vendor.companyName + " cannot be saved", "error", 3000);
          console.error(e);
        } //adkToast display tq note..     

      } //for


      if (inviteeList.length != 0) {
        adkToast.display("That is " + inviteeList.length + " invitee(s) cannot be saved", "error", 3000);
      } //clear everything.. 


      this.set('inviteeList', []);
      this.set('showDialogCheckout', false); //reload requestList

      Ember.set(this.get('adCampaignProposalRequestList'), "refresh", true); //done
      //first..
    }),
    actions: {
      //open dialog functions
      openDialogInvitee(respondList) {
        this.set('showDialogInvitee', true);
        this.set('respondList', respondList);
        this.set('aProposalInvitee', {
          page: 0,
          pageSize: 10,
          result: null,
          action: this.get('loadAProposalInviteeTask'),
          refresh: false,
          type: "emberdata"
        });
      },

      closeDialogInvitee() {
        this.set('showDialogInvitee', false);
        this.set('respondList', null);
        this.set('aProposalInvitee', {});
      },

      openDialogProposalDeadline(mode, event) {
        let adCampaign = this.get('adCampaign');
        this.set('dialogOrigin', event.currentTarget); //this.makeCopy();

        this.set('dialogProposalDeadlineMode', mode);

        if (mode == "new") {
          this.set('campaignProposalDeadline', this.calDateDiff(adCampaign.campaignStartDate, adCampaign.campaignEndDate, 10));
        } else {
          // if (mode == 'edit')
          this.set('campaignProposalDeadline', (0, _moment.default)(adCampaign.proposalDeadline));
        }

        this.set('campaignProposalDeadlineCenter', this.get('campaignProposalDeadline'));
        this.set('campaignProposalDeadlineLocal', this.get('campaignProposalDeadline'));
        this.set('showDialogProposalDeadline', true);
      },

      closeDialogProposalDeadline() {
        //this.resetCopy();
        this.resetProposalDialog();
        this.set('showDialogProposalDeadline', false);
      },

      openDialogCheckout() {
        let adkToast = this.get('adkToast');
        let inviteeList = this.get('inviteeList');

        if (inviteeList.length == 0) {
          //alert("Please select some companies first");
          adkToast.display('Please select a vendor first', "error", 2000);
          return false;
        }

        if (!this.get('createInviteeList')) {
          this.set('createInviteeList', {
            page: 0,
            pageSize: 5,
            result: null,
            action: this.get("loadCreateInviteeListTask"),
            refresh: false,
            type: "array"
          });
        }

        this.set('showDialogCheckout', true);
      },

      closeDialogCheckout() {
        this.set('showDialogCheckout', false);
      },

      //end of dialog functions
      //proposal deadline related functions section
      updateDate(selected) {
        let adkToast = this.get('adkToast');
        let newDate = (0, _moment.default)(selected.date);
        let startDate = (0, _moment.default)(this.get('adCampaign').campaignStartDate); //assume is a moment obj

        let endDate = (0, _moment.default)(this.get('adCampaign').campaignEndDate); //assume is a moment obj
        //check newCenterDate > endDate
        //compare with endDate...

        if (_adkDateTime.default.compareDate(newDate, endDate) >= 0) {
          newDate = '';
          adkToast.display("Date should not be later than the end date, it will not be set", "warning", 2000);
          return false;
        } //cannot put else.. cause productLaunchDate need check both
        //compare with startDate


        if (_adkDateTime.default.compareDate(newDate, startDate) <= 0) {
          newDate = '';
          adkToast.display("Date should not be earlier than the start date, it will not be set", "warning", 2000);
          return false;
        }

        if (newDate) {
          this.set('campaignProposalDeadlineLocal', newDate);
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          this.set('campaignProposalDeadline', (0, _moment.default)(new Date(newDateStr)));
        }
      },

      // updateDate()
      checkCenter(newCenterDate) {
        newCenterDate = (0, _moment.default)(newCenterDate.date);
        let startDate = (0, _moment.default)(this.get('adCampaign').campaignStartDate); //assume is a moment obj

        let endDate = (0, _moment.default)(this.get('adCampaign').campaignEndDate); //assume is a moment obj
        // let productLaunchDate = this.get('productLaunchDate');
        //check newCenterDate > endDate
        //compare with endDate...

        if (_adkDateTime.default.compareYearMonth(newCenterDate, endDate) >= 0) {
          newCenterDate = endDate;
        } //compare with startDate


        if (_adkDateTime.default.compareYearMonth(newCenterDate, startDate) <= 0) {
          newCenterDate = startDate;
        }

        this.set("campaignProposalDeadlineCenter", newCenterDate);
      },

      //checkCenter()
      saveProposalDeadline() {
        let adkToast = this.get('adkToast');
        let adCampaign = this.get('adCampaign');
        let controller = this;
        adCampaign.set('proposalDeadline', this.get('campaignProposalDeadline').toDate());
        adCampaign.set('action', 'updateProposalDeadline');
        adCampaign.save().then(function () {
          controller.get('proposalDeadlineSetFlag');
          controller.resetProposalDialog();
          controller.set('showDialogProposalDeadline', false);
          adkToast.display("Campagin deadline saved", "warn", 2000);
        }, function () {
          //console.log(arguments);
          adCampaign.rollbackAttributes();
          controller.resetProposalDialog();
          controller.set('showDialogProposalDeadline', false);
          adkToast.display("Save error", "error", 2000);
          return false;
        });
      },

      //end of proposal deadline section
      //vendor listing, add, remove and save section
      // loadPreferVendor: function (pageNo, pageSize) {
      //   return this.loadPreferVendorTask.perform(pageNo, pageSize);
      // },
      // loadOtherVendor: function (pageNo, pageSize) {
      //   return this.loadOtherVendorTask.perform(pageNo, pageSize);
      // },
      addList(isPrefer, vendor) {
        let adkToast = this.get('adkToast');
        let inviteeList = this.get('inviteeList');

        if (this.inviteeListSearch(vendor.id) == -1) {
          inviteeList.pushObject({
            id: vendor.id,
            isPrefer: isPrefer,
            vendor: vendor
          });
          adkToast.display("Vendor " + vendor.companyName + " added to the list", "warning", 2000);
        } else {
          adkToast.display("Vendor " + vendor.companyName + " already in the list", "error", 2000);
        }

        if (!this.get('createInviteeList')) {
          this.set('createInviteeList', {
            page: 0,
            pageSize: 5,
            result: null,
            action: this.get("loadCreateInviteeListTask"),
            refresh: false,
            type: "array"
          });
        }
      },

      //addList
      removeList(idx) {
        let inviteeList = this.get('inviteeList'); //let index = this.inviteeListSearch(vendor.id);
        //if (index > -1) {

        this.set('inviteeList', inviteeList.slice(0, idx).concat(inviteeList.slice(idx + 1, inviteeList.length)));
        let createInviteeList = this.get("createInviteeList");
        Ember.set(createInviteeList, "refresh", true);

        if (inviteeList.length == 1) {
          this.set('showDialogCheckout', false);
        }
      },

      //removeList
      toggleByBatch() {
        this.set("byBatch", !this.get('byBatch'));

        if (this.firstTime) {
          this.set('firstTime', false);
        }
      }

    } //actions

  });

  _exports.default = _default;
});