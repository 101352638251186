define("adtikir3v1/pods/components/adkpost-filter/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { later } from '@ember/runloop';
  var _default = Ember.Component.extend({
    classNames: ['post-filter'],
    value: '',

    init() {
      this._super(...arguments);

      this.set('value', ''); //this.get('handleFilterEntry').perform();
    },

    handleFilterEntry: (0, _emberConcurrency.task)(function* () {
      let filterInputValue = this.get('value');
      console.log("filterInputValue: ", filterInputValue);

      if (filterInputValue.length > 0 && filterInputValue.length < 2) {
        return;
      }

      let filterAction = this.get('filter');
      console.log("filterAction: ", filterAction);
      yield filterAction(filterInputValue); //this.set('results', results.sortBy('title'));
    })
  });

  _exports.default = _default;
});