define("adtikir3v1/pods/components/adk-display-vendor-media-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O+xKrLhF",
    "block": "{\"symbols\":[\"vendorMediaListing\",\"vendorMedia\"],\"statements\":[[4,\"adk-standard-paging\",null,[[\"pagingObj\"],[[24,[\"vendorMediaList\"]]]],{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"layout-wrap layout-row adkVendorMediaCard\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n                \"],[7,\"b\",true],[8],[0,\"Product Name:\"],[9],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n                \"],[1,[23,2,[\"name\"]],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n                \"],[7,\"b\",true],[8],[0,\"Media Type:\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n                \"],[1,[28,\"get\",[[28,\"get\",[[23,2,[]],\"mediaType\"],null],\"mediaTypeName\"],null],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n                \"],[5,\"paper-button\",[],[[\"@raised\",\"@class\",\"@onClick\"],[true,\"adkEditButton rightAlign\",[28,\"action\",[[23,0,[]],\"showVendorMediaDetail\",[23,2,[]]],null]]],{\"statements\":[[0,\"\\n                    Additional Info\\n                \"]],\"parameters\":[]}],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-display-vendor-media-list/template.hbs"
    }
  });

  _exports.default = _default;
});