define("adtikir3v1/initializers/accounting", ["exports", "accounting/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'accounting.js',
    initialize: function () {
      // currency.symbol = "€";
      _settings.number.decimal = ".";
      _settings.number.thousand = ",";
    }
  };
  _exports.default = _default;
});