define("adtikir3v1/pods/components/adk-models-table-add-user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jrx2N/wJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-button\",null,[[\"onClick\",\"raised\",\"primary\",\"iconButton\"],[[28,\"action\",[[23,0,[]],\"addMe\"],null],true,true,true]],{\"statements\":[[1,[28,\"paper-icon\",[\"add\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-models-table-add-user/template.hbs"
    }
  });

  _exports.default = _default;
});