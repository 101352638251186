define("adtikir3v1/pods/components/adk-models-table-textbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UZbXqhjT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-input\",null,[[\"value\",\"onChange\",\"class\"],[[28,\"get\",[[24,[\"record\"]],[24,[\"field\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[28,\"get\",[[24,[\"record\"]],[24,[\"field\"]]],null]],null]],null],\"adkRemoveMargin\"]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-models-table-textbox/template.hbs"
    }
  });

  _exports.default = _default;
});