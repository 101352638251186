define("adtikir3v1/pods/campaign/details/ads/ad/financials/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    selectedBasicTab: Ember.computed('router.currentRouteName', function () {
      let router = this.get('router');
      let routeArray = ["budget", "account", "invoice"];
      let currentRouteName = router.get('currentRouteName');
      let lastPath = currentRouteName.split('.').pop();
      let rtnVal = routeArray.indexOf(lastPath);

      if (rtnVal == -1) {
        rtnVal = 1;
      }

      return rtnVal;
    }),
    actions: {
      noop() {}

    }
  });

  _exports.default = _default;
});