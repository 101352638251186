define("adtikir3v1/pods/components/select-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yJ/JPESL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-checkbox\",null,[[\"value\",\"disabled\",\"onChange\"],[[24,[\"record\",\"isSelected\"]],[24,[\"record\",\"isDisabled\"]],[28,\"action\",[[23,0,[]],\"clickOnRow\",[24,[\"index\"]],[24,[\"record\"]]],null]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/select-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});