define("adtikir3v1/pods/campaign/details/ads/timeline/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DS from 'ember-data';
  var _default = Ember.Controller.extend({
    adGroupByType: Ember.computed('ads.@each.mediatype', function () {
      // let thisController = this;
      let ads = this.get('ads');
      let groups = Ember.Object.create();

      if (ads) {
        ads.forEach(function (ad) {
          let mediaType = ad.get('mediatype');

          if (mediaType) {
            if (!Ember.isPresent(groups.get(mediaType))) {
              groups.set(mediaType, Ember.A());
            }

            groups.get(mediaType).push(ad);
          }
        }); //forEach
      } //if ads


      return groups;
    }),
    workFlowStatus: Ember.computed('acWorkFlows.[]', function () {
      let acWorkFlows = this.get("acWorkFlows").toArray();
      let returnObj = {};

      for (let i = 0; i < acWorkFlows.length; i++) {
        returnObj[acWorkFlows[i].id] = acWorkFlows[i].workFlowStatus;
      }

      return returnObj;
    })
  });

  _exports.default = _default;
});