define("adtikir3v1/pods/components/adk-budget-summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),

    // For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);

      this.setProperties({
        foreignCurrency: this.get('adkSessionAccount.myCompany.foreignCurrency'),
        localCurrency: this.get('adkSessionAccount.myCompany.currency')
      }); // let adCampaign = this.get('adCampaign');
      // let cntrl = this;
      // let campaignId = adCampaign.id;
      // let adTotalMediatype = this.store.query('adTotalMediatype', {'adCampaign':adCampaign.id});
      // let adTotalMediatype = this.store.query('adTotalMediatype', {
      //   filter: {
      //     query: adCampaign.id
      //    }
      //   });
      // .then(() => {
      // cntrl.set('adTotalMediatype', adTotalMediatype);
      // });
      // let adTotalMediatype = this.store.findRecord('adTotalMediatype', adCampaign.id);
      // let adTotalMediatype = yield this.store.findRecord('adTotalMediatype', campaignId);
      // let adTotalMediatype = this.store.query('adTotalMediatype', {'adCampaign':adCampaign.id});
      // this.set('adTotalMediatype', adTotalMediatype);
    },

    data: Ember.computed("adTotalMediatype.@each.totalAmount", function () {
      let adTotalMediatype = this.get('adTotalMediatype');
      let dataArray = [];
      let dataTable = new google.visualization.DataTable();
      dataTable.addColumn({
        type: 'string',
        label: 'Media Type'
      });
      dataTable.addColumn({
        type: 'number',
        label: 'Total Amount'
      });
      adTotalMediatype.forEach(mediaType => {
        let d = [];
        d.pushObject(mediaType.id);
        d.pushObject(mediaType.plannedTotal);
        dataArray.pushObject(d);
      });
      dataTable.addRows(dataArray);
      return dataTable;
    }),
    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      },

      showChart(type) {
        if (type === 'bar') this.set('type', 'bar');else if (type === 'pie') this.set('type', 'pie');else this.set('type', 'table');
      }

    }
  });

  _exports.default = _default;
});