define("adtikir3v1/pods/components/adk-media-proposal-item-editor/component", ["exports", "ember-concurrency", "moment", "adtikir3v1/utils/adk-date-time"], function (_exports, _emberConcurrency, _moment, _adkDateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    isNewFlag: true,
    selectedTab: "mediainfo",
    init: function () {
      this._super(...arguments);

      this.set('tabObject', ["mediainfo", "dateNcosting", "noteNpromises", "vendorMediaDetail"]);
    },

    didReceiveAttrs() {
      this.set('mediaProposalItemLists', {
        page: 0,
        pageSize: 10,
        action: this.get("loadMediaProposalItemTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      }); //patch for vProposalMedia to vMediaProposal

      if (this.get('vProposalMedia')) {
        this.set('vMediaProposal', this.get('vProposalMedia'));
      }

      let vMediaProposal = this.get('vMediaProposal');
      this.set('adCampaignMediaRfp', vMediaProposal.get('adCampaignMediaRfp'));
      this.set('adCampaignMediaRfpBody', vMediaProposal.get('adCampaignMediaRfpBody'));
      let adCampaignMediaRfp = vMediaProposal.get('adCampaignMediaRfp');
      this.set('campaignStartDate', adCampaignMediaRfp.get('campaignStartDate'));
      this.set('campaignEndDate', adCampaignMediaRfp.get('campaignEndDate'));
      this.set('openConfirmationDialogBox', false);
    },

    didInsertElement() {
      let mediaProposalCostItemEditor = document.getElementById("mediaProposalCostItemEditor");
      mediaProposalCostItemEditor.style.display = "none";
    },

    saveFormActionTask: (0, _emberConcurrency.task)(function* (toClose = false) {
      let mediaProposalItem = this.get('mediaProposalItem');
      Ember.set(mediaProposalItem, 'mediaType', this.get('selectedMediaType'));
      Ember.set(mediaProposalItem, 'vendorMediaDetail', this.get('selectedVendorMediaDetail'));
      yield mediaProposalItem.save();
      this.adkToast.display("Record save", "warning", 2000);
      Ember.set(this.get('mediaProposalItemLists'), 'refresh', true);
      let vMediaProposal = this.get('vMediaProposal');
      yield vMediaProposal.reload();

      if (toClose) {
        if (this.activeDiv) {
          this.activeDiv.classList.remove('adkListCardActive');
          this.activeDiv = null;
        }

        this.resetForm();
      }
    }),
    checkMediainfo: function () {
      let adkToast = this.get('adkToast');
      let mediaProposalItem = this.get('mediaProposalItem');

      if (!mediaProposalItem.mediaName || mediaProposalItem.mediaName.trim().length === 0) {
        adkToast.display("Media name must be filled", "error", 1500);
        return false;
      }

      let mediaType = this.get('selectedMediaType');

      if (!mediaType) {
        adkToast.display("Media type must be selected", "error", 1500);
        return false;
      }

      return true;
    },
    checkDateNcosting: function () {
      let adkToast = this.get('adkToast');
      let mediaProposalItem = this.get('mediaProposalItem');

      if (!mediaProposalItem.cost || mediaProposalItem.cost.trim && mediaProposalItem.cost.trim().length === 0) {
        adkToast.display("Media cost must be filled", "error", 1500);
        return false;
      }

      let re = new RegExp('(:?^|\\s)(?=.)((?:0|(?:[1-9](?:\\d*|\\d{0,2}(?:,\\d{3})*)))?(?:\\.\\d*[0-9])?)(?!\\S)');

      if (!re.test(mediaProposalItem.cost)) {
        adkToast.display("Media cost should be a number", "error", 1500);
        return false;
      }

      return true;
    },
    checkFields: function (tab) {
      if (tab === 'mediainfo') {
        return this.checkMediainfo();
      } else if (tab === "dateNcosting") {
        return this.checkDateNcosting();
      }

      return true;
    },

    delayAWhileActive(mediaProposalItemId) {
      let parentDiv = document.getElementById('div-' + mediaProposalItemId);
      let mediaProposalCostItemEditor = document.getElementById("mediaProposalCostItemEditor"); //let parentDiv = divParent.previousElementSibling;

      parentDiv.classList.add('adkListCardActive');
      this.set('activeDiv', parentDiv);
      parentDiv.parentElement.insertBefore(mediaProposalCostItemEditor, parentDiv.nextElementSibling);
      mediaProposalCostItemEditor.style.display = "block";
      mediaProposalCostItemEditor.scrollIntoView();
    },

    resetForm() {
      //clear form variable
      this.set('selectedMediaType', null);
      this.set('adStartDate', this.adkSessionAccount.get('myNow'));
      this.set('adStartDateCenter', this.adkSessionAccount.get('myNow'));
      this.set('adEndDate', this.adkSessionAccount.get('myNow'));
      this.set('adEndDateCenter', this.adkSessionAccount.get('myNow')); //reset the tab

      this.set('selectedTab', this.tabObject[0]); //clear flag

      this.set('isNewFlag', false);
      this.set('editorIsOpen', false);
      this.set('activeDiv', null);
      this.set('selectedVendorMediaDetail', null); //move div and hide

      let mediaProposalCostItemEditor = document.getElementById("mediaProposalCostItemEditor");
      mediaProposalCostItemEditor.style.display = "none";
      this.get('element').appendChild(mediaProposalCostItemEditor);
    },

    //reset form, move div to end
    deleteMediaProposalItemTask: (0, _emberConcurrency.task)(function* () {
      let mediaProposalItem = this.get('mediaProposalItem');
      let adkToast = this.get('adkToast');

      try {
        //move here... b4 the html code get deleted....
        let el = this.get('dialogOrigin').parentElement.parentElement.parentElement;
        yield mediaProposalItem.destroyRecord();
        this.set('openConfirmationDialogBox', false);
        adkToast.display("Media deleted successfully");
        let vMediaProposal = this.get('vMediaProposal');
        yield vMediaProposal.reload();
        el.scrollIntoView();
        Ember.set(this.get('mediaProposalItemLists'), 'refresh', true);
        this.set('mediaProposalItem', null);
      } catch (e) {
        adkToast.display("Failed to delete media", "error");
      }
    }),

    //deleteMediaProposalItemTask
    checkActive() {
      if (this.editorIsOpen) {
        alert('Editor form already open, close it first');
        let mediaProposalCostItemEditor = document.getElementById("mediaProposalCostItemEditor");
        mediaProposalCostItemEditor.scrollIntoView();
        return false;
      }

      return true;
    },

    actions: {
      deleteMediaProposalItem: function (mediaProposalItem, event) {
        event.preventDefault();

        if (!this.checkActive()) {
          return false;
        }

        this.set('dialogOrigin', event.currentTarget);
        this.set('openConfirmationDialogBox', true);
        this.set('mediaProposalItem', mediaProposalItem);
      },
      cancelDeleteVendorMedia: function () {
        this.set('openConfirmationDialogBox', false);
      },
      updateMediaProposalItem: function (mediaProposalItem, event) {
        event.preventDefault();
        let thisComponent = this;

        if (!this.checkActive()) {
          return false;
        }

        this.set('mediaProposalItem', mediaProposalItem);
        mediaProposalItem.get('mediaType').then(function (mediaType) {
          thisComponent.set('selectedMediaType', mediaType);
        });
        this.set('adStartDate', mediaProposalItem.adStartDate);
        this.set('adStartDateCenter', mediaProposalItem.adStartDate);
        this.set('adEndDate', mediaProposalItem.adEndDate);
        this.set('adEndtDateCenter', mediaProposalItem.adEndDate);
        mediaProposalItem.get('vendorMediaDetail').then(function (item) {
          thisComponent.set('selectedVendorMediaDetail', item);
        });
        this.set('isNewFlag', false);
        this.set('editorIsOpen', true); //something wrong with the ember component..
        //i need to use a later to activate the editor later...
        //maybe ember latest version dont have this bug 

        Ember.run.later(this, function () {
          thisComponent.delayAWhileActive(mediaProposalItem.id);
        }, 250); // let i=0;
        // let el=parentDiv;
        // while (el) {
        //   console.log(i);
        //   console.dir(el);
        //   el = el.nextSibling;
        //   i++;
        // }
      },
      addNewMediaProposalItem: function (event) {
        if (!this.checkActive()) {
          return false;
        }

        let mediaProposalItem = this.store.createRecord('media-proposal-item', {
          vMediaProposal: this.get('vMediaProposal'),
          mediaType: null,
          aMediaProposalResponse: null,
          vendorMedialDetail: null
        });
        this.set('mediaProposalItem', mediaProposalItem);
        this.set('selectedMediaType', null);
        this.set('selectedVendorMediaDetail', null); //let el=this.get('element');

        let myNow = this.adkSessionAccount.get('myNow');
        this.set('adStartDate', myNow);
        this.set('adStartDateCenter', myNow);
        this.set('adEndDate', myNow);
        this.set('adEndDateCenter', myNow);
        let newEndDateStr = (0, _moment.default)(myNow).format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
        mediaProposalItem.set('adStartDate', new Date(newEndDateStr));
        mediaProposalItem.set('adEndDate', new Date(newEndDateStr));
        let mediaProposalCostItemEditor = document.getElementById("mediaProposalCostItemEditor");
        let buttonDiv = event.currentTarget.parentElement;
        buttonDiv.parentElement.insertBefore(mediaProposalCostItemEditor, buttonDiv.nextElementSibling);
        mediaProposalCostItemEditor.style.display = "block";
        mediaProposalCostItemEditor.scrollIntoView();
        this.set('isNewFlag', true);
        this.set('editorIsOpen', true);
      },

      unsetSelectedVendorMediaDetail() {
        this.set('selectedVendorMediaDetail', null);
      },

      cancelEdit() {
        let mediaProposalItem = this.get('mediaProposalItem');

        if (!(this.get('isNew') && mediaProposalItem.hasDirtyAttributes)) {
          mediaProposalItem.rollbackAttributes();
        }

        if (this.activeDiv) {
          this.activeDiv.classList.remove('adkListCardActive');
          this.activeDiv = null;
        }

        this.resetForm();
        this.element.scrollIntoView(); //cancel edit here
      },

      updateDate(field, selected) {
        let adkToast = this.get('adkToast');
        let newDate = (0, _moment.default)(selected.date);
        let startDate = (0, _moment.default)(this.get('campaignStartDate')); //assume is a moment obj

        let endDate = (0, _moment.default)(this.get('campaignEndDate')); //assume is a moment 

        let mediaProposalItem = this.get('mediaProposalItem'); //check newCenterDate > endDate
        //compare with endDate...

        if (_adkDateTime.default.compareDate(newDate, endDate) > 0) {
          newDate = '';
          adkToast.display("Date should not be later than the end date, it will not be set", "error", 1500);
          return false;
        } //cannot put else.. cause productLaunchDate need check both
        //compare with startDate


        if (_adkDateTime.default.compareDate(newDate, startDate) < 0) {
          newDate = '';
          adkToast.display("Date should not be earlier than the start date, it will not be set", "error", 1500);
          return false;
        }

        if (field === "adEndDate") {
          let adStartDate = this.get('adStartDate');

          if (_adkDateTime.default.compareDate(newDate, adStartDate) <= 0) {
            newDate = '';
            adkToast.display("Date should not be earlier than the start date, it will not be set", "error", 1500);
            return false;
          }
        } else {
          let adEndDate = this.get('adEndDate');

          if (_adkDateTime.default.compareDate(newDate, adEndDate) >= 0) {
            this.set('adEndDate', newDate);
            this.set('adEndDateCenter', newDate);
            let newEndDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
            mediaProposalItem.set('adEndDate', new Date(newEndDateStr)); // newDate = '';
            // adkToast.display("Date should not be later than the end date, it will not be set", "error", 1500);
            //return false;
          }
        }

        if (newDate) {
          this.set(field, newDate);
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          mediaProposalItem.set(field, new Date(newDateStr));
        }
      },

      // updateDate()
      checkCenter(field, newCenterDate) {
        newCenterDate = (0, _moment.default)(newCenterDate.date);
        let startDate = (0, _moment.default)(this.get('campaignStartDate')); //assume is a moment obj

        let endDate = (0, _moment.default)(this.get('campaignEndDate')); //assume is a moment 
        // let productLaunchDate = this.get('productLaunchDate');
        //check newCenterDate > endDate
        //compare with endDate...

        if (_adkDateTime.default.compareYearMonth(newCenterDate, endDate) >= 0) {
          newCenterDate = endDate;
        } //compare with startDate


        if (_adkDateTime.default.compareYearMonth(newCenterDate, startDate) <= 0) {
          newCenterDate = startDate;
        }

        this.set(field, newCenterDate);
      },

      //checkCenter()    
      // setMediaTypeAction(mediaType) {
      //   let mediaProposalItem = this.get('mediaProposalItem');
      //   mediaProposalItem.set('mediaType', mediaType);
      // }
      backAction: function (tab) {
        if (!this.checkFields(tab)) {
          return false;
        }

        let idx = this.tabObject.indexOf(tab);

        if (idx != 0) {
          this.set('selectedTab', this.tabObject[idx - 1]);
        }
      },
      nextAction: function (tab) {
        if (!this.checkFields(tab)) {
          return false;
        }

        let idx = this.tabObject.indexOf(tab);

        if (idx < this.tabObject.length - 1) {
          this.set('selectedTab', this.tabObject[idx + 1]);
        }
      },
      changeTabAction: function (currentTab, moveToTab) {
        if (!this.checkFields(currentTab)) {
          return false;
        }

        this.set('selectedTab', moveToTab);
      }
    }
  });

  _exports.default = _default;
});