define("adtikir3v1/pods/campaign/details/ads/ad/vendors/media/rfp/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import moment from 'moment';
  // import adkDateTime from 'adtikir3v1/utils/adk-date-time';
  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    sentPanelFlag: true,

    //listRefresh: false,
    init() {
      this._super(...arguments);
    },

    //init
    loadAdMediaRfpBodyListTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adRfpId = this.get('adRfp').id;
      let adMediaRfpBodies = yield this.store.query('ad-media-rfp-body', {
        adRfp: adRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", adMediaRfpBodies); //return attachments;
    }),
    //loadAdMediaRfpBodyListTask
    loadAdMediaProposalRequestListTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let adCampaignProposalRequestListObj = this.get('adCampaignProposalRequestList');
      let result = yield this.store.query('ad-media-proposal-request-list', {
        adMediaRfpBody: this.adMediaRfpBody.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  
    loadAdMediaProposalInviteeTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let aProposalInviteeObj = this.get('aProposalInvitee');
      let result = yield this.store.query('ad-media-proposal-invitee', {
        adMediaProposalRequestList: this.get('adMediaProposalRequestList').id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  
    loadAdMediaProposalInviteeByCompanyTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      //let aProposalInviteeByCompanyObj = this.get('aProposalInviteeByCompany');    
      let result = yield this.store.query('ad-media-proposal-invitee', {
        adMediaRfpBody: this.get('adMediaRfpBody').id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    //task  
    loadNewAdMediaInviteeListTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let newAdMediaInviteeList = yield this.get('newAdMediaInviteeList');
      Ember.set(pagingObj, 'result', newAdMediaInviteeList);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),

    //task  
    newAdMediaInviteeListSearch(vendorCompanyId) {
      let newAdMediaInviteeList = this.get('newAdMediaInviteeList');
      return newAdMediaInviteeList.findIndex(obj => {
        if (obj.id === vendorCompanyId) {
          return true;
        }
      });
    },

    //** to check
    saveNewAdMediaInviteeListTask: (0, _emberConcurrency.task)(function* () {
      let store = this.get('store');
      let adkToast = this.get('adkToast');
      let newAdMediaInviteeList = this.get('newAdMediaInviteeList');
      let adMediaRfpBody = this.get('adMediaRfpBody');
      let adMediaProposalRequestList = store.createRecord('adMediaProposalRequestList', {
        countOfList: 0,
        adMediaRfpBody: adMediaRfpBody
      });

      try {
        yield adMediaProposalRequestList.save();
      } catch (e) {
        //if this fail... exit
        adkToast.display("Cannot create proposal request list", "error", 3000);
        this.set("inviteeList", []);
        this.set('showDialogCheckout', false);
        console.error(e);
        return false;
      }

      for (let i = newAdMediaInviteeList.length - 1; i >= 0; i--) {
        let invitee = store.createRecord('adMediaProposalInvitee', {
          isPrefer: newAdMediaInviteeList[i].isPrefer,
          company: newAdMediaInviteeList[i].vendor,
          adMediaProposalRequestList: adMediaProposalRequestList
        }); //put try here... if error occur.. staill can move to next invitee

        try {
          yield invitee.save();
          newAdMediaInviteeList.splice(i, 1); //by end.. the inviteeList should be empty
        } catch (e) {
          adkToast.display("Invitee " + invitee.vendor.companyName + " cannot be saved", "error", 2000);
          console.error(e);
        } //adkToast display tq note..     

      } //for


      if (newAdMediaInviteeList.length != 0) {
        adkToast.display("That is " + newAdMediaInviteeList.length + " invitee(s) cannot be saved", "error", 3000);
      } //clear everything.. 


      this.set('adMediaInviteeList', []);
      this.set('showDialogCheckout', false); //reload requestList
      //set(this, "listRefresh", true);

      Ember.set(this.adMediaProposalRequestList, "refresh", true);
      Ember.set(this.adMediaProposalInviteeByCompany, "refresh", true); //done
      //first..
    }),
    //saveMediaInviteeListTask
    cleanUp: function () {
      this.set('adMediaRfpBodyList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAdMediaRfpBodyListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('adMediaRfpBody', null);
      this.set('firstTime', false);
      this.set('showDialogInvitee', false);
      this.set('adMediaRespondList', null);
      this.set('adMediaInvitee', {});
      this.set('newAdMediaInviteeList', []);
      this.set('newAdMediaInviteeListPageObj', null);
    },
    //actions section start
    actions: {
      openDialogInvitee(adMediaRespondList) {
        this.set('showDialogInvitee', true);
        this.set('adMediaRespondList', adMediaRespondList);
        this.set('adMediaProposalInvitees', {
          page: 0,
          pageSize: 10,
          result: null,
          action: this.get('loadAdMediaProposalInviteeTask'),
          refresh: false,
          type: "emberdata"
        });
      },

      closeDialogInvitee() {
        this.set('showDialogInvitee', false);
        this.set('respondList', null);
        this.set('adMediaProposalInvitee', {});
      },

      openDialogCheckout() {
        let adkToast = this.get('adkToast');
        let newAdMediaInviteeList = this.get('newAdMediaInviteeList');

        if (newAdMediaInviteeList.length == 0) {
          //alert("Please select some companies first");
          adkToast.display('Please select a vendor first', "error", 2000);
          return false;
        }

        if (!this.get('newAdMediaInviteeListPageObj')) {
          alert;
          this.set('newAdMediaInviteeListPageObj', {
            page: 0,
            pageSize: 5,
            result: null,
            action: this.get("loadNewAdMediaInviteeListTask"),
            refresh: false,
            type: "array"
          });
        }

        this.set('showDialogCheckout', true);
      },

      closeDialogCheckout() {
        this.set('showDialogCheckout', false);
      },

      setAdMediaRfpBody: function (adMediaRfpBody) {
        //this is clear the new invitee list 
        let newAdMediaInviteeList = this.get('newAdMediaInviteeList');

        if (newAdMediaInviteeList && newAdMediaInviteeList.length > 0) {
          let answer = confirm("You have selection in the list, do you want to continue? it will clear the list");

          if (!answer) {
            return;
          }
        } //reset createInviteeList to null


        this.set('newAdMediaInviteeList', []);
        this.set('adMediaRfpBody', adMediaRfpBody);
        this.set('adMediaProposalRequestList', {
          page: 0,
          pageSize: 20,
          action: this.get("loadAdMediaProposalRequestListTask"),
          result: null,
          refresh: false,
          type: "emberdata"
        });
        this.set('adMediaProposalInviteeByCompany', {
          page: 0,
          pageSize: 20,
          action: this.get("loadAdMediaProposalInviteeByCompanyTask"),
          result: null,
          refresh: false,
          type: "emberdata"
        });
        this.set('byBatch', true);
      },

      //setAdMediaRfpBody
      toggleByBatch() {
        this.set("byBatch", !this.get('byBatch'));

        if (this.firstTime) {
          this.set('firstTime', false);
        }
      },

      addList(isPrefer, company) {
        let adkToast = this.get('adkToast');
        let newAdMediaInviteeList = this.get('newAdMediaInviteeList');

        if (this.newAdMediaInviteeListSearch(company.id) == -1) {
          newAdMediaInviteeList.pushObject({
            id: company.id,
            isPrefer: isPrefer,
            vendor: company
          });
          adkToast.display("Vendor " + company.companyName + " added to the list", "warning", 2000);
        } else {
          adkToast.display("Vendor " + company.companyName + " already in the list", "error", 2000);
        } //if newAdMediaInviteeListPAgeObj.. not exist.. create new
        // if (!this.get('newAdAgnecyInviteeListPageObj')) {
        //   this.set('newAdAgnecyInviteeListPageObj', {
        //     page: 0,
        //     pageSize: 5,
        //     result: null,
        //     action: this.get("loadNewAdMediaInviteeListTask"),
        //     refresh: false,
        //     type: "array"
        //   });
        // }

      },

      //addList
      removeList(idx) {
        let newAdMediaInviteeList = this.get('newAdMediaInviteeList'); //let index = this.inviteeListSearch(vendor.id);
        //if (index > -1) {

        this.set('newAdMediaInviteeList', newAdMediaInviteeList.slice(0, idx).concat(newAdMediaInviteeList.slice(idx + 1, newAdMediaInviteeList.length))); //let createInviteeList = this.get("createInviteeList");

        Ember.set(newAdMediaInviteeList, "refresh", true);

        if (newAdMediaInviteeList.length == 1) {
          this.set('showDialogCheckout', false);
        }
      } //removeList    
      // clickRefresh() {
      //   //this.listRefresh=true;
      //   set(this, 'listRefresh', true);
      // }


    }
  });

  _exports.default = _default;
});