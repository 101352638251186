define("adtikir3v1/models/ad-media-rfp-body", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    adkSessionAccount: Ember.inject.service(),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    proposalDeadline: attr('date'),
    name: attr('string'),
    note: attr('string'),
    createdByRole: attr('string'),
    createdByFirstName: attr('string'),
    createdByLastName: attr('string'),
    updatedByRole: attr('string'),
    updatedByFirstName: attr('string'),
    updatedByLastName: attr('string'),
    isDeleted: attr('boolean'),
    // proposalReceived: attr('number'),
    proposalReceived: attr('number', {
      defaultValue: 0
    }),
    rfpSent: attr('number', {
      defaultValue: 0
    }),
    mediaTypes: hasMany('media-type'),
    adRfp: belongsTo('ad-rfp'),
    daysToDeadline: Ember.computed('proposalDeadline', function () {
      let now = this.get('adkSessionAccount').get('myNow');
      let deadline = (0, _moment.default)(this.get('proposalDeadline'));
      let diffDay = deadline.diff(now, "seconds");

      if (diffDay <= 86400 && diffDay > 0) {
        diffDay = 'Today is the deadline';
      } else if (diffDay > 86400) {
        diffDay = deadline.diff(now, "days");
        diffDay += " Days"; //do nothing;
        // } else if (diffDay == 0) {
        //   diffDay = 'Today is the deadline';
        // } else if (diffDay < 0) {
      } else {
        diffDay = 'Past Deadline';
      }

      return diffDay;
    }),
    passDeadline: Ember.computed("daysToDeadline", 'proposalDeadline', function () {
      let daysToDeadline = this.get('daysToDeadline');

      if (daysToDeadline === 'Past Deadline') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});