define("adtikir3v1/pods/campaign/details/financials/invoicing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OqQiOsCA",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex layout-row layout-align-start-stretch\"],[10,\"style\",\"min-height: 800px;\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"flex\"],[10,\"style\",\"min-height: 800px;\"],[8],[0,\"\\n      \"],[5,\"adk-campaign-invoice\",[],[[\"@adCampaign\",\"@adCampaignInvoice\",\"@saveNewInvoice\",\"@loadCampaignInvoiceTask\",\"@fieldTitle\"],[[22,\"adCampaign\"],[22,\"adCampaignInvoice\"],[28,\"action\",[[23,0,[]],\"createNewInvoice\"],null],[22,\"loadCampaignInvoiceTask\"],\"Invoices\"]]],[0,\" \\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/financials/invoicing/template.hbs"
    }
  });

  _exports.default = _default;
});