define("adtikir3v1/helpers/reject", ["exports", "@abcum/ember-helpers/helpers/reject"], function (_exports, _reject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _reject.default;
    }
  });
  Object.defineProperty(_exports, "reject", {
    enumerable: true,
    get: function () {
      return _reject.reject;
    }
  });
});