define("adtikir3v1/pods/campaign/details/ads/ad/financials/index/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    adkSessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),

    afterModel() {
      let parentController = this.controllerFor('campaign.details.ads.ad.financials');
      parentController.set('selectedBasicTab', 1);
      this.get('router').transitionTo('campaign.details.ads.ad.financials.budget');
    }

  });

  _exports.default = _default;
});