define("adtikir3v1/pods/components/adkcard-dashboard-campaign-media/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    // classNames: this.get('fieldname'),
    resetForm: function () {
      this.set('adcampaignDetail', '');
    },
    actions: {
      openDialogWithParent(event) {
        this.set('dialogOrigin', Ember.$(event.currentTarget));
        this.set('showDialogWithParent', true);
      },

      closeDialogWithParent(result) {
        this.set('result', result);
        this.set('showDialogWithParent', false);
      },

      editSubmit() {
        var ctrl = this;
        let adcampaignDetail = this.get('adcampaignDetail');
        adcampaignDetail.save().then(onEditOK, onEditFailed);

        function onEditOK() {
          let adkToast = ctrl.get('adkToast'); // ctrl.resetForm();

          adkToast.display("Template Description successfully updated", "warning");
          ctrl.set('showDialogWithParent', false);
        }

        function onEditFailed() {
          let adkToast = this.get('adkToast');
          adkToast.display("Template Description not saved", "error");
          ctrl.set('showDialogWithParent', false);
        }
      },

      uploadImage(attrs) {
        if (attrs.filesid.length != 0) {
          this.set("filesid", attrs.filesid);
        }

        console.log("#############");
        console.log("profile index - campaignPic");
        console.log("#############"); // let controller = this;

        if (this.filesid) {
          this.model.set('campaignPic', this.filesid[0]);
          this.model.set("action", "saveCampaignPic");
        }

        this.model.save();
      },

      saveCampaignPic() {},

      cancelcampaignPicUpdate() {}

    }
  });

  _exports.default = _default;
});