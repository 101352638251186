define("adtikir3v1/pods/campaign/details/vendor/agency/rfp/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //route for campaign.detail.vemdor.rfp.agency
  var _default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    adkSessionAccount: Ember.inject.service(),
    loadModelTask: (0, _emberConcurrency.task)(function* () {
      let adCampaign = yield this.modelFor('campaign.details').adCampaign;
      let adCampaignDetail = yield adCampaign.get('adCampaignDetail');
      let adCampaignRfpSetting = yield this.store.findRecord('ad-campaign-rfp-setting', adCampaign.id + "_rfpSetting");
      return {
        adCampaign: adCampaign,
        adCampaignDetail: adCampaignDetail,
        adCampaignRfpSetting: adCampaignRfpSetting
      };
    }),

    model() {
      return this.get('loadModelTask').perform();
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('adCampaignRfpSetting', model.adCampaignRfpSetting);
    }
  });

  _exports.default = _default;
});