define("adtikir3v1/pods/campaign/details/reports/campaignaddetails/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U6I/XJiR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex layout-align-start-stretch\"],[8],[0,\"\\n\"],[5,\"adk-report-campaign-ads\",[],[[\"@model\",\"@adCampaign\",\"@adCampaignDetail\",\"@ads\",\"@acWorkFlows\",\"@fieldTitle\"],[[22,\"model\"],[24,[\"model\",\"adCampaign\"]],[24,[\"model\",\"adCampaignDetail\"]],[24,[\"model\",\"ads\"]],[24,[\"model\",\"acWorkFlows\"]],\"Campaign with Ads Report\"]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]}],[0,\" \\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/reports/campaignaddetails/template.hbs"
    }
  });

  _exports.default = _default;
});