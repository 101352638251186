define("adtikir3v1/pods/home-office/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route", "adtikir3v1/pods/agency/controller"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //route for campaign.index (listing route)
  var _default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    store: Ember.inject.service,
    adkSessionAccount: Ember.inject.service,
    // queryParams: {
    //   page: {
    //     refreshModel: true
    //   },    
    //   pageSize: {
    //     refreshModel: true
    //   },
    //   query: {
    //     refreshModel: true
    //   }    
    // },
    //  loadModelTask: task(function* (params) {
    //      //let adkSessionAccount = this.get('adkSessionAccount');
    //     try {
    //       console.time("campaign model get");
    //        let  adCampaigns = yield this.get('store').query('home-office',
    //            {   page: params.page,
    //                pageSize: params.pageSize,
    //                query: params.query
    //            }
    //        ); //query
    //        console.timeEnd("campaign model get");
    //        return {
    //          adCampaigns: adCampaigns,
    //        }
    //      } catch (e) {
    //        throw (e);
    //      }
    //   }).drop(), 
    // model: function(params) {
    // var newModel = route.model();
    // var newModel = {adCampaigns:[]};
    // controller.set('model', newModel);
    // return {adCampaigns:[]}; 
    // return newModel;
    // try {
    //   let params={}; 
    //   // params.query = "";
    //   params.query = "HOtoDo";
    //   return this.loadModelTask.perform(params);
    // } catch (e) {
    //   throw (e);
    // }
    // },
    setupController: function (controller, model) {
      this._super(controller, model); // controller.set().


      controller.set('homeOffices', []);
      controller.reset();
      controller.actions.changeView.call(controller, false, controller.homeOfficeView[controller.homeOfficeViewIdx]);
    },
    // renderTemplate(controller, model) {
    //   this._super(controller, model);
    //   console.log(...arguments);
    //   alert('did-render');
    // },
    resetController: function (controller, isExiting) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('page', 0);
      }
    },
    actions: {
      didTransition() {
        let controller = this.controller;

        if (controller.currentView.hoTask === 'byTerritories') {
          controller.loadParentTerritoriesTask.perform();
        } else {
          controller.runQueryTask.perform();
        }
      },

      willTransition: function (transition) {
        this.controller.set('editMode', false); //only do full reset when both to and from also not home-office

        if (transition.from.name.split('.')[0] !== "home-office" && transition.to.name.split('.')[0] !== "home-office") {
          this.controller.reset();
        }
      } //willTransition

    } //actions

  });

  _exports.default = _default;
});