define("adtikir3v1/pods/components/adk-companyinfo-timeformat/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super();

      this.timeFormatOptions = ["12-hour", "24-hour"];
      this.currentTime = new Date();
      this.set('localTimeZoneId', this.get('timeZoneId'));
    },

    timeZoneMapping: Ember.computed('localTimeZoneId', {
      get(key) {
        let timezoneOptions = this.get('timezoneOptions');
        let localTimeZoneId = this.get('localTimeZoneId');
        let result = timezoneOptions.findBy('id', localTimeZoneId);
        return result;
      },

      set(key, value) {
        this.set('localTimeZoneId', value.id);
        return value;
      }

    }),
    timeFormatMapping: Ember.computed("timeFormat", {
      get(key) {
        let timeFormat = this.get('timeFormat');

        if (timeFormat == "HH:mm:ss") {
          return "24-hour";
        }

        return "12-hour";
      },

      set(key, value) {
        if (value == "12-hour") {
          this.set('timeFormat', "h:mm:ss A");
        } else {
          this.set('timeFormat', "HH:mm:ss");
        }

        return value;
      }

    }),
    actions: {
      openEditTimeDialog(ev) {
        this.set('showEditTimeDialog', true);
        this.set('dialogOrigin', ev.currentTarget);
      },

      closeEditTimeDialog() {
        this.set('showEditTimeDialog', false);
        this.set('localTimeZoneId', this.get('timeZoneId'));
        this.cancelAction();
      },

      updateTime() {
        this.set('showEditTimeDialog', false);

        if (this.get('localTimeZoneId') != this.get('timeZoneId')) {
          this.set('timeZoneId', this.get('localTimeZoneId'));
        }

        this.saveAction();
      }

    }
  });

  _exports.default = _default;
});