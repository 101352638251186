define("adtikir3v1/models/me", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    // companies: DS.hasMany('company', { async: true, inverse: 'companyOwner' }),
    status: attr('string'),
    //active, deleted, suspend, pending
    aboutMe: attr('string'),
    email: attr('string'),
    profilePic: attr('string'),
    oldPassword: attr('string'),
    newPassword: attr('string'),
    training: attr('string'),
    noRole: attr('boolean', {
      defaultValue: true
    }),
    myRoles: hasMany('my-role', {
      async: true,
      inverse: 'user'
    }),
    lastLoginRole: belongsTo('my-role', {
      inverse: null
    }),
    //set by server if switch company
    //set by server when new company signup
    //if not the server will set it with first line in roles
    //role should be sorted by last use date
    action: attr('string') // userName: computed('firstname', 'lastname', function () {
    //   return `${this.get('firstName')} ${this.get('lastName')}`;
    // }),

  });

  _exports.default = _default;
});