define("adtikir3v1/pods/components/adkcard-post/component", ["exports", "adtikir3v1/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    CDN: Ember.inject.service('adk-cdn-url'),
    field: "media",

    init() {
      this._super(...arguments);

      this.setProperties({
        url: '',
        videoType: '',
        thumbUrl: ''
      });
      this.reset();
    },

    reset() {
      this.setProperties({
        isImage: false,
        isVideo: false,
        isPdf: false,
        isAudio: false,
        isDoc: false
      });
    },

    colorClass: Ember.computed('adPost.{approved,legalApprovalStage}', function () {
      let adPost = this.get('adPost');

      if (adPost.approved) {
        if (adPost.legalApprovalStage === "approved") {
          return "card-ad-green";
        } else if (adPost.legalApprovalStage === "nReviewed") {
          return "card-ad-yellow";
        } else if (adPost.legalApprovalStage === "nRequired") {
          return "card-ad-blue";
        } else if (adPost.legalApprovalStage === "nApproved") {
          return "card-ad-red";
        }
      } else {
        return "card-ad-grey";
      }
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.imgWidth > this.imgHeight) {
        this.isLandscape = true;
      } //this.width = Math.floor(this.imgWidth * 0.6);


      this.width = this.imgWidth;
      this.height = this.imgHeight;

      if (this.title) {
        this.set('myTitle', this.title);
      } else if (this.titleField) {
        this.set('myTitle', this.get('adPost').get(this.get('titleField')));
      } else {
        this.set('myTitle', this.get('adPost').get('title'));
      }

      let getFileExt = this.get('getFileExt');
      getFileExt.perform(this);

      if (!this.get('cardad')) {
        this.set('cardad', "card-ad card-ad-height");
      }
    },

    click(event) {
      // get all 'md-card' elements
      // var a = document.getElementsByTagName('md-card');
      // var i = 1;
      // for (i = 1; i < a.length; i++) {
      //   // Remove the class 'active-class' if it exists
      //   a[i].classList.remove('active-class')
      // }
      let mdcard = event.currentTarget.children.item(0);
      this.adkPostSetActive(mdcard);
      this.onSelect(event);
      return true;
    },

    getFileExt: (0, _emberConcurrency.task)(function* () {
      // let fileid = this.get('adPost').get(this.get('fileid'));
      let latestDiscussion = yield this.get('latestDiscussion');
      let fileid;
      let thumbId = latestDiscussion.get('thumbnail');

      if (thumbId !== null) {
        if (thumbId.substr(0, 7) === "adkf://") {
          let thumb = thumbId.substr(7);
          thumb = thumb.substring(thumb.indexOf('/')); //need to plug in the bunny host...cdnHost

          this.set('thumbUrl', _environment.default.cdnHost + thumb);
        } else if (thumbId.substr(0, 9) === "adkfid://") {
          try {
            let thumb = thumbId.substr(9);
            let responseThumb = yield this.CDN.getCdnUrl(thumb);
            this.set('thumbUrl', responseThumb.data.url);
          } catch (e) {
            throw e;
          }
        } else {
          try {
            //old id      
            let thumbResponse = yield this.CDN.getCdnUrl(thumbId);
            this.set('thumbUrl', thumbResponse.data.url);
          } catch (e) {
            throw e;
          }
        }
      }

      if (latestDiscussion == null) {
        fileid = '';
        this.set('latestDiscussion', {
          media: ''
        });
      } else {
        fileid = yield latestDiscussion.get(this.get('field'));
        this.set('latestDiscussion', latestDiscussion);
      }

      if (!fileid.substr) {
        throw new Error('fileid is not a string');
      } //no substr . throw error;


      if (fileid.substr(0, 7) === "adkf://") {
        let wip = fileid.substr(7);
        wip = wip.substring(wip.indexOf('/')); //need to plug in the bunny host...cdnHost

        this.set('url', _environment.default.cdnHost + wip);
      } else if (fileid.substr(0, 8) === "/assets/") {
        this.set('url', _environment.default.cdnHost + fileid);
      } else if (fileid.substr(0, 9) === "adkfid://") {
        try {
          let wip = fileid.substr(9);
          let response = yield this.CDN.getCdnUrl(wip); //filetype = response.data.filetype.split('/');

          this.set('url', response.data.url);
        } catch (e) {
          throw e;
        } // } else if (fileid.substr(0, 7) === "/assets/" || 

      } else if (fileid.substr(0, 7) === "http://" || //  fileid.substr(0, 7) === "http://" ||
      fileid.substr(0, 8) === "https://") {
        this.set('url', fileid);
      } else {
        try {
          //old id      
          let response = yield this.CDN.getCdnUrl(fileid);
          this.set('url', response.data.url);
        } catch (e) {
          throw e;
        }
      } //if
      //ext...


      this.reset();
      let url = this.get('url');
      let ext = '.' + url.split('.').pop();

      if (_environment.default.imageExt.indexOf(ext) >= 0) {
        this.set('isImage', true);
      } else if (_environment.default.videoExt.indexOf(ext) >= 0) {
        this.set('isVideo', true);
      } else if (_environment.default.pdfExt.indexOf(ext) >= 0) {
        this.set('isPdf', true);
      } else if (_environment.default.docExt.indexOf(ext) >= 0) {
        this.set('isDoc', true);
      } else if (_environment.default.audioExt.indexOf(ext) >= 0) {
        this.set('isAudio', true);
      }
    }),
    actions: {
      // let imgIsLandscape = false;
      // if (this.isImage) {
      //   let img = ev.target;
      //   if (img.width > img.height) {
      //     imgIsLandscape = true;
      //   }
      //   if ((this.isLandscape && imgIsLandscape) ||
      //     (!this.isLandscape && imgIsLandscape)) {
      //     if (img.width > this.imgWidth) {
      //       img.width = this.imgWidth;
      //     }
      //   } else if ((this.isLandscape && !imgIsLandscape) ||
      //     (!this.isLandscape && !imgIsLandscape)) {
      //     if (img.height > this.imgHeight) {
      //       img.height = this.imgHeight;
      //     }
      //   }
      // }
      imageLoad(ev) {
        let img = ev.target;
        let imgLandscape = false; //detect

        if (img.width >= img.height) {
          imgLandscape = true;
        }

        if (this.width == 0 || this.width == '') {
          this.width = img.width;
        }

        if (this.height == 0 || this.height == '') {
          this.height = img.height;
        } // image is landscape and display size is landscape
        // image is not landscape (portrait) and display size is landscape
        // check the width first... 
        // then only check the height


        if (imgLandscape && this.isLandscape || !imgLandscape && this.isLandscape) {
          if (img.width > this.width) {
            img.width = this.width;
          }

          if (img.height > this.height) {
            img.height = this.height;
          }
        } else {
          // this should be the rest combination
          // image is landscape and display size is not landscape (portrait)
          // image is not landscape (portrait) and display size is not landscape (portrait) 
          // check the height first... 
          // then only check the width 
          if (img.height > this.height) {
            img.height = this.height;
          }

          if (img.width > this.width) {
            img.width = this.width;
          }
        } //else if check image landscape and display landscape 

      } //imageLosd


    } //actions

  });

  _exports.default = _default;
});