define("adtikir3v1/services/ajax", ["exports", "ember-ajax/services/ajax", "@jftechnology/ember-keycloak-auth/mixins/keycloak-adapter", "adtikir3v1/config/environment"], function (_exports, _ajax, _keycloakAdapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend(_keycloakAdapter.default, {
    host: _environment.default.apiHost
  });

  _exports.default = _default;
});