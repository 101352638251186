define("adtikir3v1/pods/home-office/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    queryParams: ['pageSize', 'page', 'query', 'queryType', 'homeOfficeViewIdx'],
    searchText: '',
    pageSize: 8,
    page: 0,
    homeOfficeViewIdx: 0,
    query: '',
    queryType: '',
    //homeOfficeView: ,
    currentView: '',
    currentTerritory: '',
    //aways set to full
    needReset: 'full',

    init() {
      this._super(...arguments);

      this.homeOfficeView = [{
        hoTask: 'toDo',
        taskTitle: 'Approvals'
      }, {
        hoTask: 'byProduct',
        taskTitle: 'By Product'
      }, {
        hoTask: 'byTerritories',
        taskTitle: 'Campaigns By Territories'
      }];
    },

    totalPage: Ember.computed('homeOffices.meta.page.total', 'pageSize', function () {
      let total = 0;

      if (this.homeOffices.meta && this.homeOffices.meta.page && this.homeOffices.meta.page.total) {
        total = this.homeOffices.meta.page.total;
      }

      let pageSize = 1;

      if (this.pageSize > 0) {
        pageSize = this.pageSize;
      }

      total = Math.ceil(total / pageSize);
      return total;
    }),
    pageArray: Ember.computed("totalPage", function () {
      let totalPage = this.get('totalPage');
      if (totalPage == 0) return [];
      return Array.from(Array(totalPage).keys(), x => x + 1);
    }),
    steps: Ember.computed(function () {
      return [{
        element: '#campaignManager',
        intro: 'Your authorized campaigns are all listed below.'
      }, {
        element: '#agencyMenu',
        intro: 'Agency Menu is visible for companies registered as Agency.'
      }, {
        element: '.companies',
        intro: 'You can belong to multiple companies. Switch companies here.'
      }, {
        element: '#addCompany',
        intro: 'Add a new company here.'
      }, {
        element: '#profile',
        intro: 'Click here to change your profile and company settings.'
      }, {
        element: '#page',
        intro: 'Only 8 campaigns are displayed at a time. Click here to see the next 8 or jump to any number.'
      }, {
        element: '#addCampaign',
        intro: 'Click here to create or add a new campaign.',
        position: 'left'
      }];
    }),

    reset() {
      if (this.needReset !== 'no') {
        this.set('searchText', '');
        this.set('page', 0);
        this.set('query', '');

        if (this.needReset === "full") {
          this.set('queryType', '');
          this.set('currentTerritory', '');
          this.set('homeOfficeViewIdx', 0);
          this.set('currentView', '');
        }
      } //needReset == no


      this.set('needReset', 'full');
    },

    loadCampaignsToDoTask: (0, _emberConcurrency.task)(function* (query, page = 0, pageSize = 10) {
      try {
        console.time("campaign model get");
        let homeOffices = yield this.get('store').query('home-office', {
          page: page,
          pageSize: pageSize,
          queryType: this.queryType,
          query: query
        }); //query

        console.timeEnd("campaign model get");
        this.set("homeOffices", homeOffices);
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    loadCampaignsSearchProductsTask: (0, _emberConcurrency.task)(function* (query, page = 0, pageSize = 10) {
      try {
        // this.set('query', this.get('searchText'));
        let homeOffices = yield this.get('store').query('home-office', {
          page: page,
          pageSize: pageSize,
          query: this.query,
          queryType: this.queryType
        }); //query

        this.set("homeOffices", homeOffices);
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    loadParentTerritoriesTask: (0, _emberConcurrency.task)(function* () {
      try {
        let parentTerritories = yield this.get('store').findAll('parent-territory');
        this.set("parentTerritories", parentTerritories);
        this.actions.changeTerritory.call(this, parentTerritories.get('firstObject'));
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    loadCampaignTerritoryTask: (0, _emberConcurrency.task)(function* (query, page = 0, pageSize = 8) {
      try {
        let homeOffices = yield this.get('store').query('home-office', {
          page: page,
          pageSize: pageSize,
          queryType: this.queryType,
          query: query
        }); //query

        this.set("homeOffices", homeOffices);
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    runQueryTask: (0, _emberConcurrency.task)(function* () {
      let pageSize = this.pageSize;

      if (this.currentView.hoTask === 'toDo') {
        yield this.loadCampaignsToDoTask.perform('', this.page, pageSize);
      } else if (this.currentView.hoTask === 'byProduct') {
        yield this.loadCampaignsSearchProductsTask.perform(this.query, this.page, pageSize);
      } else {
        // By Territories
        yield this.loadCampaignTerritoryTask.perform(this.query, this.page, pageSize);
      }
    }),
    actions: {
      setPage(number) {
        number = number - 1;

        if (number >= this.totalPage) {
          number = this.totalPage - 1;
        } else if (number < 0) {
          number = 0;
        }

        this.set('page', number);
        this.runQueryTask.perform();
      },

      nextPage() {
        this.actions.setPage.call(this, this.get('page') + 2); //this.actions.setPage.call(this, this.get('page') + 1);
      },

      prevPage() {
        this.actions.setPage.call(this, this.get('page')); //this.actions.setPage.call(this, this.get('page')-1);
      },

      searchMe() {
        let page = 0;
        this.set('query', this.get('searchText'));
        this.loadCampaignsSearchProductsTask.perform(this.query, page, this.pageSize);
      },

      clearSearch() {
        this.set('query', '');
        this.set('searchText', '');
        this.set('page', 0);
        this.runQueryTask.perform();
      },

      introComplete() {
        let me = this.get('adkSessionAccount').me;
        me.set("action", "updateTraining");
        me.set("training", "1");
        me.save();
      },

      // changeView(parentView) {
      changeView(needTransitionTo, newView) {
        // if (this.router.currentRouteName !== 'homeo-office') {
        //    this.router.transitionTo('home-office');
        // }
        this.set('needReset', 'half');
        this.reset();
        this.set('homeOfficeViewIdx', this.homeOfficeView.indexOf(newView));
        this.set('queryType', this.homeOfficeView[this.homeOfficeViewIdx].hoTask);
        this.set('currentView', newView);

        if (this.router.currentRouteName && this.router.currentRouteName !== 'home-office.index') {
          this.router.transitionTo('home-office');
        } else {
          // if (needTransitionTo) {        
          //   this.router.transitionTo('home-office');
          // } else {
          if (this.currentView.hoTask === 'byTerritories') {
            this.loadParentTerritoriesTask.perform();
          } else {
            this.set('query', '');
            this.set('searchText', '');
            this.set('currentTerritory', '');
            this.runQueryTask.perform();
          }
        }
      },

      changeTerritory(selectedTerritory) {
        this.set('currentTerritory', selectedTerritory);
        this.set('query', selectedTerritory.companyId);
        this.set('page', 0);
        this.runQueryTask.perform();
      },

      createTemplate() {
        console.log("****######*****");
        console.log('campaign createTemplate');
        console.log("****######*****");
      },

      deleteCampaign(adcampaign) {
        adcampaign.destroyRecord();
        this.set('showCampaignMgrDialog', false);
      },

      openDialogDeleteCampaign(adcampaign, event) {
        this.set('adcampaign', adcampaign);
        this.set('dialogOrigin', event.currentTarget);
        this.set('showCampaignMgrDialog', true);
      },

      closeDialogDeleteCampaign() {
        this.set('showCampaignMgrDialog', false);
      },

      cancelAction() {}

    },
    cardlayout: false,
    selectedBudgetStatus: 'approved',
    selectedAssetAction: 'review'
  });

  _exports.default = _default;
});