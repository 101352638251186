define("adtikir3v1/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com
  const {
    Model,
    attr,
    belongsTo // hasMany

  } = _emberData.default;

  var _default = Model.extend({
    // Updated datetime for backend to ensure last update wins
    updatedAt: attr('date'),
    updatedBy: attr('string'),
    // Future use for security
    // signature            : attr('string'),
    companyName: attr('string'),
    companyNameLocal: attr('string'),
    address1: attr('string'),
    address2: attr('string'),
    state: attr('string'),
    city: attr('string'),
    postCode: attr('string'),
    countryCode: attr('string'),
    email: attr('string'),
    url: attr('string'),
    briefDescription: attr('string'),
    description: attr('string'),
    //companyDescription      : attr('string'),
    logo: attr('string'),
    languages: attr('string'),
    // Ratings: Good Partner, Communications, Timely Payments, Responsiveness, Timely Approvals
    // Array of 6 values - number of 5 Star, 4 Star, 3 Star, 2 Star, 1 Star, Total
    //  overallRatings          : attr(),
    //ratings                 : hasMany('rating'),
    isMedia: attr('boolean'),
    isAgency: attr('boolean'),
    isAAAA: attr('string'),
    // industry                : attr('string'),
    country: belongsTo('location-country')
  });

  _exports.default = _default;
});