define("adtikir3v1/pods/campaign/details/ads/ad/financials/account/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    loadApprovedBudgetListTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let adId = this.get('ad').get('id');
      let status = pagingObj.status;
      let type = pagingObj.chargeType;
      let result = yield this.store.query('ad-campaign-budget-approved', {
        type: type,
        page: page,
        pageSize: pagingObj.pageSize,
        status: status,
        adId: adId
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    cleanUp: function () {
      this.set('chargeTypeArray', ['agency', 'media', 'misc']);

      for (let i = 0; i < this.chargeTypeArray.length; i++) {
        this.set(this.chargeTypeArray[i] + 'BudgetApprovedList', {
          page: 0,
          pageSize: 20,
          action: this.get("loadApprovedBudgetListTask"),
          chargeType: this.chargeTypeArray[i],
          status: 'approved',
          result: null,
          refresh: false,
          type: "emberdata"
        });
      } //for

    } //cleanUp

  });

  _exports.default = _default;
});