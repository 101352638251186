define("adtikir3v1/models/ad-post-discussion", ["exports", "ember-data", "adtikir3v1/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    title: attr('string'),
    media: attr('string'),
    mediaFormat: attr('string'),
    mediaType: attr('string'),
    comment: attr('string'),
    wflowNum: attr('string', {
      defaultValue: ''
    }),
    thumbnail: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    annotation: attr('boolean', {
      defaultValue: false
    }),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    adPost: belongsTo('adPost'),
    mediaExt: Ember.computed('media', function () {
      let media = this.get('media');
      let ext = media.split('.')[1];
      const imgExts = _environment.default.imageExt; //** Maybe for future checking use, return a value if match with the extension */

      if (imgExts.includes("." + ext)) {
        return 1;
      } else {
        return 0;
      } // //**Return true means match with the video extension */
      // return exts.includes("." + ext);

    })
  });

  _exports.default = _default;
});