define("adtikir3v1/pods/components/adk-models-table-display-fullname/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "axqmUczV",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"record\",\"companyUser\",\"firstName\"]],false],[0,\" \"],[1,[24,[\"record\",\"companyUser\",\"lastName\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-models-table-display-fullname/template.hbs"
    }
  });

  _exports.default = _default;
});