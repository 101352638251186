define("adtikir3v1/models/ad-campaign-budget-approved", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    amountLocal: attr('number', {
      defaultValue: 0
    }),
    amountForeign: attr('number', {
      defaultValue: 0
    }),
    comment: attr('string'),
    title: attr('string'),
    proposalType: attr('string'),
    proposalId: attr('string', {
      defaultValue: ''
    }),
    //this one not use belongsTo.. 
    // combine proposalType and this to get data
    currencyType: attr('string', {
      defaultValue: 'local'
    }),
    currency: attr('string'),
    exchangeRate: attr('number'),
    exchangeRateDate: attr('date'),
    exchangeRateType: attr('string', {
      defaultValue: 'rowRate'
    }),
    status: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    chargeType: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    vendorCompany: belongsTo('company'),
    adCampaign: belongsTo('adCampaign'),
    ad: belongsTo('ad')
  });

  _exports.default = _default;
});