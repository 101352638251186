define("adtikir3v1/pods/components/adk-vendor-media-card-edit/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedTab: 0,

    init() {
      this._super(...arguments);

      this.setProperties({
        frequencyOptions: ["Weekly", "Bi-Weekly", "Monthly", "Daily"]
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.set("isNew", this.get("isNew"));
      this.set("languageOptions", this.get('languages').sortBy('name'));
    },

    canNext: Ember.computed('selectedTab', function () {
      if (this.selectedTab != 3) {
        return true;
      }

      return false;
    }),
    canBack: Ember.computed('selectedTab', function () {
      if (this.selectedTab != 0) {
        return true;
      }

      return false;
    }),
    canSave: Ember.computed('selectedTab', function () {
      if (this.selectedTab == 3) {
        return true;
      }

      return false;
    }),
    createMediaDetail: (0, _emberConcurrency.task)(function* () {
      let mediaDetail = this.get("mediaDetail");
      yield this.createMediaDetailTask.perform(mediaDetail);
    }),
    saveMediaDetail: (0, _emberConcurrency.task)(function* (status) {
      let selectedMediaType = this.get('selectedMediaType');
      let locationList = this.get('locationList');
      let removeList = this.get('removeList');
      let adkToast = this.get('adkToast');
      let mediaDetail = this.get("mediaDetail");
      mediaDetail.set('mediaType', selectedMediaType);

      if (mediaDetail.targetIndustry == false) {
        mediaDetail.targetIndustrySelection.clear();
      }

      let parentId = mediaDetail.id;

      try {
        for (let i = 0; i < locationList.length; i++) {
          if (locationList[i].adkNewFlag) {
            let location = this.store.createRecord('location', {
              parentId: parentId,
              parentField: this.get('parentField'),
              locationableType: locationList[i].constructor.modelName,
              LocationableId: locationList[i].id
            });
            yield location.save();
            locationList[i].set('adkNewFlag', false);
          }
        }

        for (let i = removeList.length - 1; i >= 0; i--) {
          let id = this.get('parentField') + '!!!' + parentId + '!!!' + removeList[i].constructor.modelName + '!!!' + removeList[i].id;
          let record = yield this.store.findRecord('location', id);
          yield record.destroyRecord();
          removeList.removeObject(removeList[i]);
        }

        yield mediaDetail.save();
        mediaDetail.reload();
        adkToast.display("Media updated successfully");

        if (status === "done") {
          this.doneEdit();
        }
      } catch (e) {
        adkToast.display("Failed to update media", "error");
      }
    }).restartable(),
    //saveMediaDetail
    actions: {
      cancelEdit(mediaDetail) {
        this.cancelEdit(mediaDetail);
      },

      incomeLevelUpdate(value) {
        value = !value;
        this.get('mediaDetail').set('incomeLevel', value);

        if (value == false) {
          this.get('mediaDetail').set('lowIncome', false);
          this.get('mediaDetail').set('middleIncome', false);
          this.get('mediaDetail').set('upperMiddleIncome', false);
          this.get('mediaDetail').set('highIncome', false);
        }
      },

      targetIndustrySelectionUpdate(value) {
        value = !value;
        let mediaDetail = this.get('mediaDetail');
        mediaDetail.set('targetIndustry', value);
      },

      backTab() {
        this.set("selectedTab", this.selectedTab - 1);
      },

      nextTab() {
        if (this.selectedTab == 0) {
          let adkToast = this.get('adkToast');
          let mediaDetail = this.get("mediaDetail");

          if (mediaDetail.name == null) {
            adkToast.display("Please enter product name", "error", 3000);
            return false;
          }

          if (this.get('selectedMediaType') == '') {
            adkToast.display("Media type not selected, please select one", "error", 3000);
            return false;
          }
        }

        this.set("selectedTab", this.selectedTab + 1);
      }

    } //actions

  });

  _exports.default = _default;
});