define("adtikir3v1/pods/components/adkpanel-approval/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { get, set }            from '@ember/object';
  var _default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    searchTerm: '',
    page: 0,
    pageSize: 4,
    activeAdPost: null,
    colorStatus: false,
    //  formInvalid: false,
    newTitle: '',
    newComment: '',
    newMedia: '',
    totalPage: Ember.computed('total', 'pageSize', function () {
      let total = 0;

      if (this.total) {
        total = this.total;
      }

      let pageSize = 1;

      if (this.pageSize > 0) {
        pageSize = this.pageSize;
      }

      total = Math.ceil(total / pageSize);
      return total;
    }),
    formInvalid: Ember.computed('newTitle', 'newMedia', 'newComment', function () {
      let newTitle = this.get('newTitle');
      let newMedia = this.get('newMedia');
      let newComment = this.get('newComment');

      if (newTitle == "" || newMedia == "" || newComment == "") {
        return true;
      }
    }),
    loadAdPostsFunc: (0, _emberConcurrency.task)(function* () {
      let adApproval = this.get('adApproval');
      let result = yield this.get('loadAdPosts')(adApproval.id, this.get('searchTerm'), this.get('page'), this.get('pageSize')); //loadAdPostsTask

      this.set('adPosts', result.adPosts);
      this.set('total', result.total);
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this.loadAdPostsFunc.perform(this);
    },

    init: function () {
      this._super(...arguments);

      let adApproval = this.get('adApproval');
      this.set('pannelExpanse', !adApproval.get('approved'));
    },

    /*
      willRender: function() {
         console.log("adApproval",this.get('adApproval.id'), this.get('adApproval.approved'));
      },
    */
    filterByTitle: (0, _emberConcurrency.task)(function* (searchTerm) {
      this.set('searchTerm', searchTerm); // if (searchTerm !== '') {

      yield this.loadAdPostsFunc.perform(); // }
    }).restartable(),
    newPostAsset: (0, _emberConcurrency.task)(function* () {
      yield this.saveNewAdPost({
        title: this.get('newTitle'),
        media: this.get('newMedia'),
        comment: this.get('newComment'),
        thumbnail: this.get('thumbnail')
      }, this.get('adApproval'));
      this.actions.closeAddNewAdPost.apply(this);
      this.loadAdPostsFunc.perform(this);
    }),
    //newPostAsset
    clearNewForm: function () {
      this.set('newComment', '');
      this.set('newTitle', '');
      this.set('newMedia', '');
    },
    actions: {
      openAddNewAdPost(event) {
        this.set('dialogOrigin', event.currentTarget);
        this.clearNewForm();
        this.set('showNewPost', true);
        let adkToast = this.get('adkToast');
        adkToast.display('All fields are required', 'warning', 3000);
      },

      colorStatus() {
        this.set('colorStatus', true);
      },

      closeAddNewAdPost() {
        this.clearNewForm();
        this.set('showNewPost', false);
      },

      /* Dialog with new comment */
      uploadAdPostPic: function () {
        //do nothing
        return;
      },

      //uploadPostPic
      setPage(number) {
        if (number >= this.totalPage) {
          number = this.totalPage - 1;
        } else if (number < 0) {
          number = 0;
        }

        this.set('page', number);
        this.loadAdPostsFunc.perform();
      },

      //setPage
      nextPage() {
        this.actions.setPage.call(this, this.get('page') + 1);
      },

      prevPage() {
        this.actions.setPage.call(this, this.get('page') - 1);
      },

      adkPostSetActive(element) {
        element.classList.add('active-class');

        if (this.activeAdPost) {
          this.activeAdPost.classList.remove('active-class');
        }

        this.activeAdPost = element;
      },

      selectCard(adPost) {
        this.set('adPost', adPost);
        return;
      }

    }
  });

  _exports.default = _default;
});