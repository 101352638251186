define("adtikir3v1/helpers/prepend", ["exports", "@abcum/ember-helpers/helpers/prepend"], function (_exports, _prepend) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _prepend.default;
    }
  });
  Object.defineProperty(_exports, "prepend", {
    enumerable: true,
    get: function () {
      return _prepend.prepend;
    }
  });
});