define("adtikir3v1/pods/components/adk-companyinfo-users/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),

    init() {
      this._super();

      let adkSessionAccount = this.get('adkSessionAccount');
      this.setProperties({
        showGlobalFilter: false,
        useFilteringByColumns: false,
        showComponentFooter: false,
        showColumnsDropdown: false,
        editMode: false
      });
      this.set('existUser_col', [{
        propertyName: 'companyUser.lastName',
        title: 'Last Name'
      }, {
        propertyName: 'companyUser.firstName',
        title: 'First Name'
      }, {
        propertyName: 'companyUser.email',
        title: 'Email'
      }, {
        propertyName: 'role',
        title: 'Role'
      }, {
        propertyName: 'createdAt',
        title: 'Member Since',
        editable: false,
        component: 'date-display'
      }, {
        propertyName: 'lastLoginDate',
        title: 'Last Login',
        editable: false,
        component: 'date-display'
      }, {
        propertyName: 'status',
        title: 'Status'
      }]);

      if (adkSessionAccount.get('lastLoginRole').get('role') == 'OWNER') {
        this.existUser_col.push({
          title: 'Action',
          editable: false,
          component: 'edit-user'
        });
      }
    } //init;


  });

  _exports.default = _default;
});