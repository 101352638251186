define("adtikir3v1/models/ad", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import user from './user';
  // import {
  //   //fragment,
  //   fragmentArray
  //   //  array
  // } from 'ember-data-model-fragments/attributes';
  // import adkDateTime from '../utils/adk-date-time';
  // import { sort } from '@ember/object/computed';
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),

    /* Ballack section */
    // adStages: hasMany('ad-stage', { async: false }),
    // adStages: attr(),
    // adStages: attr('string'),
    // adStages: fragmentArray('adStages'),

    /*  adTimelines: hasMany('ad-timeline', {
        async: true
      }),
    */

    /*
      //commnet by syeo.. take out and put it in adapproval
      adposts: hasMany('adpost', {
        async: true
      }),
    */
    // ad approvals.. will be created when
    // wfStatusNumber = 2 to 8
    // by the route campaign/details/ad/
    // adApprovals: hasMany('ad-approval', {
    //    async: true
    // }),
    // Soon - July 16,18.
    // ad-deadlines has the multiple deadlines per workflow step
    //Budgetting related model
    adBudgetWorkflowStep: attr('number', {
      defaultValue: 0
    }),
    estBudget: attr('number', {
      defaultValue: 0
    }),
    estAgencyBudget: attr('number', {
      defaultValue: 0
    }),
    estMediaBudget: attr('number', {
      defaultValue: 0
    }),
    estMiscBudget: attr('number', {
      defaultValue: 0
    }),
    // plannedBudget:         attr('number', { defaultValue: 0}),
    plannedMediaBudget: attr('number', {
      defaultValue: 0
    }),
    plannedAgencyBudget: attr('number', {
      defaultValue: 0
    }),
    plannedMiscBudget: attr('number', {
      defaultValue: 0
    }),
    committedBudget: attr('number', {
      defaultValue: 0
    }),
    committedAgencyBudget: attr('number', {
      defaultValue: 0
    }),
    committedMediaBudget: attr('number', {
      defaultValue: 0
    }),
    committedMiscBudget: attr('number', {
      defaultValue: 0
    }),
    actualSpend: attr('number', {
      defaultValue: 0
    }),
    actualMediaSpend: attr('number', {
      defaultValue: 0
    }),
    actualAgencySpend: attr('number', {
      defaultValue: 0
    }),
    actualMiscSpend: attr('number', {
      defaultValue: 0
    }),
    approvedMiscBudgetFlag: attr('boolean', {
      defaultValue: false
    }),
    // plannedBudget: computed('plannedMediaBudget', 'plannedAgencyBudget', 'plannedMiscBudget', function() {
    //   let plannedMediaBudget = this.get('plannedMediaBudget');
    //   let plannedAgencyBudget = this.get('plannedAgencyBudget');
    //   let plannedMiscBudget = this.get('plannedMiscBudget');
    //   return (plannedMediaBudget + plannedAgencyBudget + plannedMiscBudget);
    // }),
    budgetLevel: Ember.computed('committedBudget', 'plannedBudget', function () {
      let committedBudget = this.get('committedBudget');
      let plannedBudget = this.get('plannedBudget');

      if (!plannedBudget) {
        //this.set('plannedBudget',0);
        plannedBudget = 0;
      }

      if (!committedBudget) {
        //this.set('committedBudget',0);
        committedBudget = 0;
      }

      if (plannedBudget >= committedBudget) {
        let diffPercent = (plannedBudget - committedBudget) / plannedBudget * 100;

        if (diffPercent > 10) {
          return "success";
        }

        return "warning";
      }

      return "danger";
    }),
    //syeo added
    //  in process
    //  review
    //  approaved
    //  update
    //  temp discard
    //  discard
    adBudgetStatus: attr('number', {
      defaultValue: '0'
    }),
    // adBudgetComments: fragmentArray('budget-comment'),
    adDeadlines: Ember.computed('id', function () {
      return this.store.query('ad-deadline', {
        ad: this.id,
        page: 0,
        pageSize: 0
      });
    }),
    //computed for display lastest comment
    // Check that this is not used. Can remove
    // adBudgetLatestComment: computed('adBudgetComments.@each', function () {
    //   let adBudgetComments = this.get('adBudgetComments');
    //   if (adBudgetComments != null && adBudgetComments.length > 0) {
    //     return adBudgetComments.lastObject;
    //   }
    //   return null;
    // }),
    taxIncluded: attr('boolean'),
    assetOwner: attr('boolean'),

    /* End here */
    //remove by syeo
    //customerId           : attr('string'),
    //adId                 : attr('string'),
    adName: attr('string', {
      defaultValue: ''
    }),
    adNameLocal: attr('string', {
      defaultValue: ''
    }),
    adPic: attr('string'),
    description: attr('string', {
      defaultValue: ''
    }),
    estimatedImpressions: attr('number', {
      defaultValue: 0
    }),
    actualImpression: attr('number', {
      defaultValue: 0
    }),
    adColor: attr('string', {
      defaultValue: ''
    }),
    // mediatype           : belongsTo('mediatype'),
    mediatype: attr('string'),
    mediaformat: attr('string'),
    adMediaFormatDetails: attr('string'),
    mediaOutlet: attr('string'),
    // comment by syeo..no use
    //  workFlowStatus     : attr('string'),
    // workFlowStatusNumber   : attr('number'),
    workFlowStatusNumber: attr('number', {
      defaultValue: 0
    }),
    agencyName: attr('string'),
    agencyFees: attr('string'),
    //Soon added for miscellaneous costs
    miscCosts: attr('number'),
    //syeo add in this, for future use
    // media               : belongsTo('media');
    //mediaName            : attr('string'),
    //mediaFees            : attr('string'),
    //mediaFeesActual      : attr('string'),
    mediaFeesApproved: attr('string'),
    mediaPaymentSchedule: attr('string'),
    mediaAgreement: attr('string'),
    mediaNDA: attr('string'),
    mediaStartDate: attr('string'),
    mediaEndDate: attr('string'),
    language: attr('string'),
    agencyStartDate: attr('string'),
    agencyEndDate: attr('string'),
    agencyPaymentSchedule: attr('string'),
    agencyAgreement: attr('string'),
    agencyNDA: attr('string'),
    adPlayStartDate: attr('date'),
    adPlayEndDate: attr('date'),
    timeStart: attr('string'),
    duration: attr('string'),
    customField: attr('string'),
    assetConcept: attr('string'),
    assetConceptFinal: attr('string'),
    // assetMedia shows the adPic
    assetCredit: attr('string'),
    assetCaption: attr('string'),
    adtypes: attr('string'),
    //Whether an active is active or completed it's life cycle
    activeStatus: attr('boolean'),
    //changed from customAdtypeDesc to customAdTypeDesc
    customAdTypeDesc: attr('string'),
    //comment out by syoe.. not use it in gorv approval doc upload
    //  approvalRequiredGov: attr('boolean'),
    //  approvedGov          : attr('string'),
    // using model/timestamp.js from Firebase
    // dateofGovApproval   : attr('timestamp'),
    dateofGovApproval: attr('date'),
    // approvedGovRequestDate : belongsTo('ad-deadline',{ inverse: null }),
    approvedGovRequestDate: attr('string'),
    approvedGovCertificate: attr('string'),
    approvedClient: attr('string'),
    // dateofApproval      : attr('timestamp'),
    dateofApproval: attr('date'),
    adCampaignDetail: belongsTo('adCampaignDetail', {
      async: true
    }),
    adCampaign: belongsTo('adCampaign', {
      async: true
    }),
    // invoices            : hasMany('invoice'),
    adDuration: Ember.computed('adPlayStartDate', 'adPlayEndDate', function () {
      let startdate = (0, _moment.default)(this.get('adPlayStartDate'));
      let enddate = (0, _moment.default)(this.get('adPlayEndDate')); // console.log("adcampaignmodel - now", now);
      // console.log("adcampaignmodel - launchDate", launchDate);

      let duration = enddate.diff(startdate, 'days') + 1;
      return duration;
    }),
    daysToStart: Ember.computed('adPlayStartDate', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let now = (0, _moment.default)(adkSessionAccount.get('myToday'));
      let adLaunchDate = (0, _moment.default)(this.get('adPlayStartDate'));
      let diffDay = adLaunchDate.diff(now, 'days');

      if (diffDay > 0) {
        diffDay += " Days";
      } else if (diffDay == 0) {
        diffDay = 'This is the day';
      } else if (diffDay < 0) {
        diffDay = 'Ad Already Started';
      }

      return diffDay;
    }),
    // Performance Data
    // kpi added by Soon - To bind ad performance to what client wants to measure
    // For example - measure of ad performance as it relates to rating
    kpi1Ad: attr('number'),
    kpi1AdDesc: attr('string'),
    kpi2Ad: attr('number'),
    kpi2AdDesc: attr('string'),
    //Planned budget is the summed of media, agency and misc budgets
    plannedBudget: Ember.computed('estimatedImpressions', 'plannedBudget', function () {
      return this.get('plannedMediaBudget') + this.get('plannedAgencyBudget') + this.get('plannedMiscBudget');
    }),
    // Cost Per Mile calculations - Standard calculation in advertising
    cpm: Ember.computed('estimatedImpressions', 'plannedBudget', function () {
      if (this.get('plannedBudget') > 0) {
        // console.log('adbudget: ', this.get('adBudget'));
        // console.log('estimatedImpressions: ', this.get('estimatedImpressions'));
        return this.get('estimatedImpressions') / 1000 / (this.get('plannedMediaBudget') + this.get('plannedAgencyBudget') + this.get('plannedMiscBudget'));
      }
    }),
    //  invoiceAds            : hasMany('invoice-ad'),
    //  workFlowStatus: computed('workFlowStatusNumber', function() {
    //      let workFlowStatusNumber = this.get('workFlowStatusNumber');
    //      let adcampaignDetail = this.get('adcampaigndetail');
    //      let wflowstatus = adcampaignDetail.get('wflowstatus');
    //      return wflowstatus.objectAt(workFlowStatusNumber).get('workFlowStatus');
    //  }),
    isDeleted: attr('boolean'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    //action flag.. added by syeo to support function save in server
    action: attr('string')
  });

  _exports.default = _default;
});