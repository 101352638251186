define("adtikir3v1/pods/campaign/details/ads/workflow/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    inControl: false,
    //flag for confirm in the updateWorkFlowDesc
    actions: {
      updateWorkFlowDesc: function (adcampaignDetail) {
        let thisController = this;

        if (adcampaignDetail.get('hasDirtyAttributes')) {
          if (this.inControl == false && confirm("are you sure to change this?")) {
            this.inControl = true;
            adcampaignDetail.save().then(function () {
              thisController.inControl = false; //reset the flag
            });
          } else {
            adcampaignDetail.rollbackAttributes();
            this.inControl = false; //reset the flag
          }
        } //if

      },
      setStatus: function (obj, ops) {
        let targetStatus = ops.target.status;
        let currentStatus = obj.get("workFlowStatusNumber"); // console.log('targetStatus: '+ targetStatus );
        // console.log('currentStatus: '+ currentStatus );

        this.set('dialogOrigin', null);
        this.set('showPromptDialog', true);

        if (targetStatus < currentStatus) {
          this.set('showWarning', true);
        }

        this.set('targetStatus', targetStatus); //passing param into dialog

        this.set('currentStatus', currentStatus); //passing param into dialog

        this.set('adid', obj.get("id"));
      }
    },
    saveData: (0, _emberConcurrency.task)(function* (adid, targetStatus) {
      var result = yield this.get('store').peekRecord('ad', adid);
      result.set("workFlowStatusNumber", targetStatus);
      result.save(); //  console.log("status updated to: " + targetStatus);

      return true;
    }),
    closePromptDialog: (0, _emberConcurrency.task)(function* (result, approvalComment, adid, targetStatus) {
      if (result === 'ok') {
        result = `${result} and new comment2: ${approvalComment} with adid: ${adid}`;
        let saveSuccessful = this.get('saveData').perform(adid, targetStatus);
        let success = yield saveSuccessful;

        if (success) {//       console.log("Status updated.")
        }
      } //Toast service or notification service


      this.set('result', result); //Ending

      this.set("approvalComment", null);
      this.set("showWarning", false);
      this.set('showPromptDialog', false);
    })
  });

  _exports.default = _default;
});