define("adtikir3v1/models/targeting-obj-tbd", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hasMany,
    attr,
    Model
  } = _emberData.default; //only use array from now
  // import {
  //   array
  // } from 'ember-data-model-fragments/attributes';

  var _default = Model.extend({
    maleAge0: attr('boolean'),
    maleAge1: attr('boolean'),
    maleAge2: attr('boolean'),
    maleAge3: attr('boolean'),
    maleAge4: attr('boolean'),
    maleAge5: attr('boolean'),
    maleAge6: attr('boolean'),
    maleAge7: attr('boolean'),
    maleAge8: attr('boolean'),
    maleAge9: attr('boolean'),
    femaleAge0: attr('boolean'),
    femaleAge1: attr('boolean'),
    femaleAge2: attr('boolean'),
    femaleAge3: attr('boolean'),
    femaleAge4: attr('boolean'),
    femaleAge5: attr('boolean'),
    femaleAge6: attr('boolean'),
    femaleAge7: attr('boolean'),
    femaleAge8: attr('boolean'),
    femaleAge9: attr('boolean'),
    languages: hasMany('languages', {
      async: true
    }),
    //teritories             : hasMany('territory-option', { async: true }),
    //countries               : hasMany('country', { async: true }),
    targetIndustry: attr('boolean'),
    targetIndustrySelection: hasMany('industryType', {
      async: true
    }),
    isMarried: attr('boolean'),
    isDisvorce: attr('boolean'),
    hasChildren: attr('boolean'),
    incomeLevel: attr('boolean'),
    lowIncome: attr('boolean'),
    middleIncome: attr('boolean'),
    upperMiddleIncome: attr('boolean'),
    highIncome: attr('boolean'),
    places: hasMany('place', {
      polymorphic: true
    }),
    createdAt: attr('date'),
    createdBy: attr('string'),
    updatedAt: attr('date'),
    updatedBy: attr('string')
  });

  _exports.default = _default;
});