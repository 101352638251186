define("adtikir3v1/services/google-charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    language: 'en',

    init() {
      this._super(...arguments);

      this.googlePackages = this.googlePackages || ['corechart', 'bar', 'line', 'scatter', 'column', 'timeline'];
      this.defaultOptions = this.defaultOptions || {
        animation: {
          duration: 500,
          startup: false
        }
      };
    },

    loadPackages() {
      const {
        google: {
          charts,
          visualization
        }
      } = window;
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (visualization !== undefined) {
          resolve();
        } else {
          charts.load('current', {
            language: this.language,
            packages: this.googlePackages
          });
          charts.setOnLoadCallback(ex => {
            if (ex) {
              reject(ex);
            }

            resolve();
          });
        }
      });
    }

  });

  _exports.default = _default;
});