define("adtikir3v1/pods/components/adk-vendor-proposal-agency-shortlisted/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showAddShortlistForm: false,
    showAwardForm: false,
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);
    },

    actions: {
      SaveShortlistDialog() {
        let store = this.get('store'); //

        let sessionAccount = this.get('sessionAccount');
        let proposalResponse = this.get('record');
        let proposal = this.get('proposal');
        let proposalResponseChoosen = store.createRecord('proposal-response-choosen', {
          id: proposalResponse.get('id').concat('shortlisted'),
          shortlistedBy: sessionAccount.get('user'),
          shortlistedDate: new Date(),
          shortlistedComment: this.get('comment'),
          proposal: proposal,
          proposalResponse: proposalResponse
        });

        if (proposal.get('proposalShortlisteds') == null || proposal.get('proposalShortlisteds') == undefined) {
          proposal.set('proposalShortlisteds', Ember.A());
        }

        proposal.get('proposalShortlisteds').addObject(proposalResponseChoosen);
        proposalResponseChoosen.save();
        proposal.save();
        this.set('comment', '');
        this.set('showAddShortlistForm', false);
      },

      closeShortlistDialog(field) {
        this.set('comment', '');
        this.set(field, false);
      },

      addMe() {
        //need to put in a dialog..
        this.set('showAddShortlistForm', true);
      },

      removeMe() {
        let component = this;
        let proposalResponseChoosen = this.get('record');
        let proposalShortlisteds = this.get('proposalShortlisteds');
        proposalShortlisteds.removeObject(proposalResponseChoosen);
        let proposal = this.get('proposal');
        proposal.save();
        proposalResponseChoosen.destroyRecord().then(function () {
          component.store.unloadRecord(proposalResponseChoosen);
        });
        this.set('proposalResponseChoosen', null);
      },

      awardMe() {
        this.set('showAwardForm', true);
      }

    } //actions

  });

  _exports.default = _default;
});