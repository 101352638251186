define("adtikir3v1/pods/components/adk-companyinfo-numberformat/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super();

      this.thousandSeparatorOptions = [",", "."];
      this.decimalSeparatorOptions = [",", "."];
    },
    actions: {
      openEditNumberDialog(ev) {
        this.set('showEditNumberDialog', true);
        this.set('dialogOrigin', ev.currentTarget);
      },

      closeEditNumberDialog() {
        this.set('showEditNumberDialog', false);
        this.cancelAction();
      },

      updateNumber() {
        this.set('showEditNumberDialog', false);
        this.saveAction();
      }

    }
  });

  _exports.default = _default;
});