define("adtikir3v1/helpers/country", ["exports", "@abcum/ember-helpers/helpers/country"], function (_exports, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _country.default;
    }
  });
  Object.defineProperty(_exports, "country", {
    enumerable: true,
    get: function () {
      return _country.country;
    }
  });
});