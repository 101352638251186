define("adtikir3v1/pods/components/adkcard-addashboard-deadlines-formdialog/component", ["exports", "adtikir3v1/utils/adk-date-time", "moment"], function (_exports, _adkDateTime, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    fixWorkFlowNumberFlag: false,
    ///this is for fix workflow number
    editFlag: false,
    // Set up today's date as center for the calendar
    init: function () {
      this._super();

      let adkSessionAccount = this.get('adkSessionAccount');
      this.set('currentDate', adkSessionAccount.get('myToday'));
      this.set('deadlineDateCenter', (0, _moment.default)(this.get('ad').get('adCampaign').get('campaignStartDate')));
    },

    didReceiveAttrs() {
      if (this.get('fixWorkFlowNumberFlag')) {
        let acWworkFlowOptions = this.get('acWorkFlowOptions');
        let i = this.get('defaultWorkFlowNumber');
        this.set('initialWF', acWworkFlowOptions[i]);
        this.set('deadlineDescription', this.get("deadlineDescription")); //deadlineDescription will be pass in from model
        //deadlineDescription    
        //editFlag is in new mode

        this.set('editFlag', false); //need to pass in the ad filedname that needed to update...
      } else {
        this.set('deadlineDescription', '');
        this.set('deadlineAdRelatedField', '');
      }

      if (this.get('action')) {
        this.set('action', this.get('action'));
      } else {
        this.set('action', '');
      }

      this.set('deadlineDate', '');
      this.set('deadlineOldDate', ''); //edit mode

      if (this.get('editFlag')) {
        let theDeadline = this.get('theDeadline');
        let acWorkFlowOptions = this.get('acWorkFlowOptions');

        for (let i = 0; i < acWorkFlowOptions.length; i++) {
          if (acWorkFlowOptions[i].index == theDeadline.workFlowStatusNumber) {
            this.set('initialWF', acWorkFlowOptions[i]);
            break;
          }
        }

        this.set('fixWorkFlowNumberFlag', theDeadline.fixWorkflowStatus);
        this.set('deadlineDescription', theDeadline.deadlineDescription);
        this.set('deadlineDate', (0, _moment.default)(theDeadline.deadlineDate));
        this.set('deadlineOldDate', (0, _moment.default)(theDeadline.deadlineDate));
        this.set('deadlineDateCenter', (0, _moment.default)(theDeadline.deadlineDate)); //    this.set('deadlineAdRelatedField', theDeadline.deadlineAdRelatedField);
      } // if
      //console.log("did receive attrs: " , this);

    },

    actions: {
      updateDate(thefield, selected) {
        let adkToast = this.get('adkToast');
        let adkSessionAccount = this.get('adkSessionAccount');
        let newDate = selected.moment;
        ``;
        let campaignStartDate = (0, _moment.default)(this.get('ad').get('adCampaign').get('campaignStartDate')); //assume is a string ('YYYY-MM-dd')

        let campaignEndDate = (0, _moment.default)(this.get('ad').get('adCampaign').get('campaignEndDate')); //assume is a string ('YYYY-MM-dd)
        //check date > campaign start date or < campaign enddate

        if (_adkDateTime.default.compareDate(newDate, campaignStartDate) < 0 || _adkDateTime.default.compareDate(newDate, campaignEndDate) > 0) {
          newDate = '';

          let campaignRangeStr = _adkDateTime.default.convertDateObjToDateString(campaignStartDate, adkSessionAccount.get('utcOffset'), adkSessionAccount.get('dateFormat')) + " to " + _adkDateTime.default.convertDateObjToDateString(campaignEndDate, adkSessionAccount.get('utcOffset'), adkSessionAccount.get('dateFormat'));

          if (this.get('editFlag')) {
            this.set(thefield, this.get('deadlineOldDate'));
          }

          adkToast.display("Date not in campaign range , will not set it. Acceptable range " + campaignRangeStr, "error", 2000);
          return false;
        }

        if (newDate != '') {
          this.set(thefield, newDate); // let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          // this.set(thefield + "Local", moment(new Date(newDateStr)));

          adkToast.display("Date successfully set", "warning", 2000);
        }
      },

      //end of update
      checkCenter(thefield, centerField, centerDate) {
        let newCenterDate = centerDate.moment;
        let campaignStartDate = (0, _moment.default)(this.get('ad').get('adCampaign').get('campaignStartDate')); //assume is a string ('YYYY-MM-dd')

        let campaignEndDate = (0, _moment.default)(this.get('ad').get('adCampaign').get('campaignEndDate')); //assume is a string ('YYYY-MM-dd)

        if (_adkDateTime.default.compareYearMonth(newCenterDate, campaignStartDate) < 0) {
          newCenterDate = campaignStartDate;
        }

        if (_adkDateTime.default.compareYearMonth(newCenterDate, campaignEndDate) > 0) {
          newCenterDate = campaignEndDate;
        }

        this.set(centerField, newCenterDate);
      },

      //checkCenter()
      actionUpdateDeadline: function () {
        let editFlag = this.get('editFlag'); //let adkNewDeadlineComponent = this;

        let adkSessionAccount = this.get('adkSessionAccount');
        let adkToast = this.get('adkToast'); //let fixWorkFlowNumberFlag = this.get('fixWorkFlowNumberFlag');

        let theDeadline; //ned error checking code here... b4 save, make sure all field is set and
        // in logical order... 

        let wf = this.get('initialWF');

        if (wf == '' || !wf) {
          adkToast.display("Workflow not selected", "error");
          return false;
        }

        let deadlineDescription = this.get('deadlineDescription');

        if (deadlineDescription == '' || !deadlineDescription) {
          adkToast.display("Description is not been filled", "error");
          return false;
        }

        let deadlineDate = this.get('deadlineDate');

        if (deadlineDate == '' || !deadlineDate) {
          adkToast.display("Date is not selected", "error");
          return false;
        }

        let newDateStr = deadlineDate.format('YYYY-MM-DD') + 'T00:00:00' + adkSessionAccount.get('utcOffsetStr');
        let deadlineDateLocal = new Date(newDateStr);

        if (!editFlag) {
          theDeadline = this.store.createRecord('ad-deadline');
          theDeadline.set('ad', this.get('ad'));
          theDeadline.set('action', this.get('action'));
          theDeadline.set('deadlineDate', deadlineDateLocal);
        } else {
          theDeadline = this.get('theDeadline');

          if (!deadlineDate.isSame(this.get('deadlineOldDate'))) {
            theDeadline.set('deadlineDate', deadlineDateLocal);
          }
        }

        theDeadline.set('deadlineDescription', this.get('deadlineDescription'));
        theDeadline.set('workFlowStatusNumber', this.get('initialWF').index);

        if (editFlag) {
          return this.get('editAction')(theDeadline);
        } else {
          return this.get('newAction')(theDeadline);
        }
      } //actionUpdateDeadline

    } //actions              

  });

  _exports.default = _default;
});