define("adtikir3v1/models/a-proposal-award", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    AdCampaignProposalResponseId: attr('number'),
    AgencyCompanyId: attr('string'),
    AdvertiserCompanyId: attr('string'),
    awardedNote: attr('string'),
    advertiserNote: attr('string'),
    //  agencyNote:                    attr('string'),
    advertiserUpdateUser: attr('string'),
    advertiserUpdateUserId: attr('string'),
    startDate: attr('date'),
    acceptJob: attr('boolean'),
    isDeleted: attr('boolean'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    adCampaign: belongsTo('ad-campaign-rfp')
  });

  _exports.default = _default;
});