define("adtikir3v1/helpers/drag-set", ["exports", "@abcum/ember-helpers/helpers/drag-set"], function (_exports, _dragSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dragSet.default;
    }
  });
  Object.defineProperty(_exports, "dragSet", {
    enumerable: true,
    get: function () {
      return _dragSet.dragSet;
    }
  });
});