define("adtikir3v1/helpers/check", ["exports", "@abcum/ember-helpers/helpers/check"], function (_exports, _check) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _check.default;
    }
  });
  Object.defineProperty(_exports, "check", {
    enumerable: true,
    get: function () {
      return _check.check;
    }
  });
});