define("adtikir3v1/pods/campaign/details/vendor/media/proposal/controller", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    refresh: false,
    aMediaProposalResponse: null,
    userAMediaProposalResponseRating: null,
    rateDisplay: false,
    starWidth: 12,
    starHeight: 12,
    showAwardFlag: false,
    showAwardEditFlag: false,
    awardExpiredDayCount: 3,
    awardedNote: '',
    showCancelAwardAction: false,
    showConfirmProjectDialogFlag: false,
    showAwardButtonFlag: Ember.computed('aMediaProposalResponse', 'aMediaProposalResponse.status', 'showAwardEditFlag', function () {
      let fg = false; //let adCampaign = this.get('adCampaign');

      let aMediaProposalResponse = this.get('aMediaProposalResponse');
      let showAwardEditFlag = this.get('showAwardEditFlag');

      if (aMediaProposalResponse && aMediaProposalResponse.get('status') === "vSubmitted") {
        //need check date as well for expiry
        let responseExpiredDate = (0, _moment.default)(aMediaProposalResponse.get('responseExpiredDate'));

        if (responseExpiredDate.diff(this.adkSessionAccount.get('myNow')) > 0) {
          fg = true;
        } //if

      } //if 


      if (showAwardEditFlag) {
        fg = false;
      }

      return fg;
    }),
    showAwardedInfoFg: Ember.computed('aMediaProposalResponse.status', function () {
      let aMediaProposalResponse = this.get('aMediaProposalResponse');
      let flaseArray = ['new', 'vSubmitted', 'expired'];

      if (flaseArray.indexOf(aMediaProposalResponse.status) >= 0) {
        return false;
      }

      return true;
    }),
    showAcceptInfoFg: Ember.computed('aMediaProposalResponse.status', function () {
      let aMediaProposalResponse = this.get('aMediaProposalResponse');
      let flaseArray = ['vAccept', 'vReject', 'vAcceptExpired', 'project', 'projectReject'];

      if (flaseArray.indexOf(aMediaProposalResponse.status) >= 0) {
        return true;
      }

      return false;
    }),
    showCreateRatingButtonFlag: Ember.computed("rateDisplay", "userAMediaProposalResponseRating", 'aMediaProposalResponse', 'aMediaProposalResponse.status', function () {
      let fg = false;
      let userAMediaProposalResponseRating = this.get('userAMediaProposalResponseRating');
      let aMediaProposalResponse = this.get('aMediaProposalResponse');
      let rateDisplay = this.get('rateDisplay');

      if (!userAMediaProposalResponseRating) {
        if (aMediaProposalResponse.status === "vSubmitted") {
          if (rateDisplay) {
            fg = true;
          }
        }
      }

      return fg;
    }),
    //expirarion date for the award... vendor needed to reponse before this date, 
    //otherwise, statuse will turn to awareExpired
    awardExpiredDateComputed: Ember.computed('awardExpiredDayCount', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let awardExpiredDayCount = this.get('awardExpiredDayCount');
      let dateObj = new Date(adkSessionAccount.get('myNow'));
      dateObj.setDate(dateObj.getDate() + awardExpiredDayCount + 1);
      dateObj = (0, _moment.default)(dateObj);
      let newDateStr = dateObj.format('YYYY-MM-DD') + 'T00:00:00.000' + adkSessionAccount.get('utcOffsetStr');
      let rtnDate = new Date(newDateStr);
      return rtnDate;
    }),
    // loadAdCampaingMediaRfpBodyTask: task(function* (page = 0, pagingObj) {
    //   let adCampaingMediaRfp = this.get('adCampaingMediaRfp');
    //   let result = yield this.store.query('ad-campaign-media-rfp-body', {
    //     adCampaingMediaRfp: adCampaingMediaRfp.id,
    //     page: page,
    //     pageSize: pagingObj.pageSize
    //   });
    //   set(pagingObj, 'result', result);
    //   set(pagingObj, 'page', page);
    //   return true;
    // }),
    loadAdCampaignMediaRfpBodyListTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adCampaignMediaRfpId = this.get('adCampaignMediaRfp').id;
      let adCampaignMediaRfpBodies = yield this.store.query('ad-campaign-media-rfp-body', {
        adCampaignMediaRfp: adCampaignMediaRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", adCampaignMediaRfpBodies); //return attachments;
    }),
    //loadAdCampaignMediaRfpBodyListTask
    loadAMediaProposalResponsesTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let adCampaignMediaRfpBody = this.get('adCampaignMediaRfpBody');
      let result = yield this.store.query('a-media-proposal-response', {
        adCampaignMediaRfpBody: adCampaignMediaRfpBody.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    loadAMediaProposalResponsesRatingsTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let adCampaignMediaResponse = this.get('aMediaProposalResponse');
      let result = yield this.store.query('a-media-proposal-response-rating', {
        aMediaProposalResponse: adCampaignMediaResponse.id,
        // byUser: false,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    loadUserAMediaProposalResponseRatingTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignMediaResponse = this.get('aMediaProposalResponse');
      let result = yield this.store.query('a-media-proposal-response-rating', {
        aMediaProposalResponse: adCampaignMediaResponse.id,
        byUser: true,
        page: 0,
        pageSize: 1
      });
      Ember.set(this, 'userAMediaProposalResponseRating', result.get('firstObject'));

      if (this.userAMediaProposalResponseRating) {
        this.set('rateDisplay', true);
      }
    }),

    cleanAMediaProposalResponse(controller = null) {
      if (!controller) {
        controller = this;
      }

      controller.set('rateDisplay', true);
      controller.set('aMediaProposalResponseRatingList', {
        page: 0,
        pageSize: 20,
        action: this.get("loadAMediaProposalResponsesRatingsTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      controller.set('aMediaProposalResponse', null);
      controller.set('userAMediaProposalResponseRating', null);
      controller.set('showAwardFlag', false);
      controller.set('showCancelAwardAction', false);
      controller.set('awardCancelReason', '');
      controller.set('finalNote', '');
      controller.set('showConfirmProjectDialogFlag', false);
      controller.cleanAwardEdit(controller);
    },

    cleanAwardEdit(controller = null) {
      //clean the award form seciton
      //will be called by cleanAProposalUp..
      if (!controller) {
        controller = this;
      }

      controller.set('showAwardEditFlag', false);
      controller.set('awardExpiredDayCount', 3);
      controller.set('awardedNote', '');
    },

    cleanUp: function () {
      //this.set('createRatingOn', false);
      this.set('adCampaignMediaRfpBodyList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAdCampaignMediaRfpBodyListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },

    init() {
      this._super(...arguments);

      this.cleanUp();
    },

    //init
    saveAwardEditActionTask: (0, _emberConcurrency.task)(function* () {
      let aMediaProposalResponse = this.get('aMediaProposalResponse');
      aMediaProposalResponse.set('action', "awardAction");

      if (aMediaProposalResponse.isForeignCurrency) {
        aMediaProposalResponse.set('finalCurrencyRate', aMediaProposalResponse.exchangeRate);
        aMediaProposalResponse.set('finalCurrencyRateDate', aMediaProposalResponse.get('exchangeRateDate'));
      }

      aMediaProposalResponse.set('awardedNote', this.get('awardedNote'));
      aMediaProposalResponse.set('awardedDate', this.get('adkSessionAccount').get('myNow').toDate());
      aMediaProposalResponse.set('awardExpiredDate', this.get('awardExpiredDateComputed')); //need popup for confirmation....

      yield aMediaProposalResponse.save();
      this.cleanAwardEdit(); //reload adCampaign for 
      //yield this.get('adCampaign').reload();

      this.get('adkToast').display('Proposal awarded', 'warning', 1500);
    }),
    saveCancelAwardActionTask: (0, _emberConcurrency.task)(function* () {
      let aMediaProposalResponse = this.get('aMediaProposalResponse');
      aMediaProposalResponse.set('action', "awardCancelAction");
      aMediaProposalResponse.set('awardResponseMessage', this.get('awardCancelMessage')); //need popup for confirmation....

      yield aMediaProposalResponse.save();
      this.set('showAwardEditFlag', true);
      this.set('awardCancelReason', ''); //reload adCampaign for 

      yield this.get('adCampaign').reload();
      this.get('adkToast').display('Proposal canceled', 'warning', 1500);
    }),
    saveResponseActionTask: (0, _emberConcurrency.task)(function* (action) {
      //action should be projectAcceptAction or projectRejectAction
      let adkToast = this.get('adkToast');
      let aMediaProposalResponse = this.get('aMediaProposalResponse');

      if (this.get('finalNote').length === 0) {
        adkToast.display("Pleae enter some values", "error");
        return;
      }

      aMediaProposalResponse.set('action', action);
      aMediaProposalResponse.set('finalNote', this.get('finalNote'));
      yield aMediaProposalResponse.save(); //reload adCampaign for 

      yield this.get('adCampaign').reload();
      this.set('showConfirmProjectDialogFlag', false);
      this.set('finalNote', '');
      this.get('adkToast').display('Proposal Updated', 'warning', 1500);
    }),
    //saveResponseActionTask
    setAMediaProposalResponseTask: (0, _emberConcurrency.task)(function* (aMediaProposalResponse) {
      let router = this.get('router');
      this.cleanAMediaProposalResponse(this);
      aMediaProposalResponse.reload();

      if (aMediaProposalResponse.status === "project") {
        router.transitionTo('campaign.details.vendor.media.accepted', this.adCampaign.id, {
          queryParams: {
            aMediaProposalResponseId: aMediaProposalResponse.id
          }
        });
      }

      let adCampaignMediaRfp = this.get('adCampaignMediaRfp');

      if (aMediaProposalResponse.quotedCurrency === adCampaignMediaRfp.foreignCurrency) {
        aMediaProposalResponse.set('isForeignCurrency', true);
        aMediaProposalResponse.set('localCurrency', adCampaignMediaRfp.localCurrency); //ok.. is using foreignCurrency...

        if (aMediaProposalResponse.status === 'vSubmitted' || aMediaProposalResponse.status === 'expired') {
          let currencyId = adCampaignMediaRfp.foreignCurrency + 'to' + adCampaignMediaRfp.localCurrency;
          let currencyRate = yield this.store.findRecord('exchange-rate', currencyId);
          aMediaProposalResponse.set('exchangeRate', currencyRate.exchangeRate);
          aMediaProposalResponse.set('exchangeRateDate', currencyRate.exchangeDate);
        } else {
          aMediaProposalResponse.set('exchangeRate', aMediaProposalResponse.finalCurrencyRate);
          aMediaProposalResponse.set('exchangeRateDate', aMediaProposalResponse.finalCurrencyRateDate);
        }
      }

      this.set('aMediaProposalResponse', aMediaProposalResponse);
      let pagingObj = this.get('aMediaProposalResponseRatingList');

      if (!this.rateDisplay) {
        this.actions.cancelRating.call(this);
      } //this.set('aMediaProposalResponseRatingListRefresh', true);


      Ember.set(pagingObj, "refresh", true);
      Ember.set(this, "refresh", aMediaProposalResponse.id); //set(pagingObj, "actionInstance", pagingObj.action.perform(pagingObj.page, pagingObj));

      this.set('loadUserAMediaProposalResponseRatingTaskInstant', this.loadUserAMediaProposalResponseRatingTask.perform());
    }),
    //setAMediaProposalResponseTask
    actions: {
      setAdCampaignMediaRfpBody(adCampaignMediaRfpBody) {
        this.set('adCampaignMediaRfpBody', adCampaignMediaRfpBody);
        this.set('aMediaProposalResponseList', {
          page: 0,
          pageSize: 6,
          action: this.get("loadAMediaProposalResponsesTask"),
          result: null,
          refresh: false,
          type: "emberdata"
        });
        this.cleanAMediaProposalResponse(this);
      },

      cancelCancelAwardAction() {
        this.set('showAwardEditFlag', false);
        this.set('awardCancelReason', '');
      },

      showConfirmProjectDialog(action) {
        this.set('showConfirmProjectDialogFlag', true);
        this.set('projectAction', action);
      },

      cancelConfirmProjectAction() {
        this.set('showConfirmProjectDialogFlag', false);
        this.set('projectAction', '');
        this.set('finalNote', '');
      },

      showEditAwardProposal() {
        this.set('showAwardEditFlag', true);
      },

      showCancelAwardAction() {
        this.set('showCancelAwardAction', true); //need set this to cancel
      },

      cancelAwardEditAction() {
        this.cleanAwardEdit();
      },

      setCreateRatingOn() {
        //this.set('createRatingOn', true);
        this.set('userAMediaProposalResponseRating', this.store.createRecord('a-media-proposal-response-rating'));
        this.set('rateDisplay', false);
      },

      setModifyRatingOn() {
        this.set('rateDisplay', false);
      },

      cancelRating() {
        let userAMediaProposalResponseRating = this.get('userAMediaProposalResponseRating');

        if (userAMediaProposalResponseRating.isNew) {
          this.set('userAMediaProposalResponseRating', null);
        } else {
          if (userAMediaProposalResponseRating.get('hasDirtyAttributes')) {
            userAMediaProposalResponseRating.rollbackAttributes();
          }
        } //this.set('createRatingOn', false);


        this.set('rateDisplay', true);
      },

      saveRating() {
        let adkToast = this.get('adkToast');
        let saveMode;
        let userAMediaProposalResponseRating = this.get('userAMediaProposalResponseRating');
        let changeAttributes = userAMediaProposalResponseRating.changedAttributes();

        if (Object.keys(changeAttributes).length === 0) {
          adkToast.display("Please enter some values", "error");
          return;
        }

        if (userAMediaProposalResponseRating.isNew) {
          saveMode = 'new';
          userAMediaProposalResponseRating.set('aMediaProposalResponse', this.get('aMediaProposalResponse'));
        }

        let controller = this;
        userAMediaProposalResponseRating.save().then(function () {
          if (saveMode === 'new') {
            adkToast.display("Your new rating has been created", "warning");
          } else {
            adkToast.display("Your rating get updated", "warning");
          } //controller.set('createRatingOn', false);


          controller.set('rateDisplay', true);
          Ember.set(controller.get('aMediaProposalResponseRatingList'), 'refresh', true);
          Ember.set(controller, "refresh", userAMediaProposalResponseRating.id);
        });
      },

      updateAMediaProposalResponse(componentValues) {
        this.aMediaProposalResponse.setProperties(componentValues);
        this.aMediaProposalResponse.save();
      }

    } //actions

  });

  _exports.default = _default;
});