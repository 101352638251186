define("adtikir3v1/pods/newcompany/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),

    init() {
      this._super();

      this.setProperties({
        industrySelection: [],
        selectedTab: 'basicInfo',
        basicTab: true,
        emailValidation: [{
          message: 'Please provide email in a valid format',
          validate: inputValue => {
            let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return emailPattern.test(inputValue);
          }
        }] //emailValidation

      }); //setPropertities
    },

    //init
    timeZoneType: Ember.computed('companyCountry', function () {
      let country = this.get('companyCountry');

      if (!country) {
        return [];
      }

      return this.store.query('timezone', {
        countryId: country.get('id')
      });
    }),
    loadLocationStateTask: (0, _emberConcurrency.task)(function* () {
      let myCountry = this.get('companyCountry');
      let states = yield this.store.query('location-state', {
        country: myCountry.id
      });
      this.set('stateOptions', states);
    }),
    // load city list.. 
    // this will check the city's state is in locationList or not.. if so, dont load it
    loadLocationCityTask: (0, _emberConcurrency.task)(function* () {
      let myState = this.get('companyState');
      let cities = yield this.store.query('location-city', {
        state: myState.id
      });
      this.set('cityOptions', cities);
    }),
    saveToDb: (0, _emberConcurrency.task)(function* () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let store = this.get("store");
      let me = adkSessionAccount.get('me');

      try {
        let myNewCompany = store.createRecord('my-company');
        myNewCompany.set('companyName', this.get('companyName'));
        myNewCompany.set('companyNameLocal', this.get('companyNameLocal'));
        myNewCompany.set('url', this.get('companyWebSite'));
        myNewCompany.set('briefDescription', this.get('companyBriefDesc'));
        myNewCompany.set('email', this.get('companyEmail'));
        myNewCompany.set('isAgency', this.get('isAgency'));
        myNewCompany.set('isMedia', this.get('isMedia'));
        myNewCompany.set('country', this.get('companyCountry').get('id'));
        myNewCompany.set('industries', this.get('industrySelection'));
        yield myNewCompany.save(); //yield myNewCompany.reload();

        let myNewCompanyDetail = yield myNewCompany.get('myCompanyDetail');
        myNewCompanyDetail.set('address1', this.get('companyAddress1'));
        myNewCompanyDetail.set('address2', this.get('companyAddress2'));
        myNewCompanyDetail.set('cityId', this.get('companyCity').id);
        myNewCompanyDetail.set('city', this.get('companyCity').name);
        myNewCompanyDetail.set('state', this.get('companyState').name);
        myNewCompanyDetail.set('stateId', this.get('companyState').id);
        myNewCompanyDetail.set('postCode', this.get('companyPostcode'));
        myNewCompanyDetail.set('description', this.get('companyDesc'));
        myNewCompanyDetail.set('timeZoneId', this.get('timezone').get('id'));
        yield myNewCompanyDetail.save(); //commnet by syeo.. move code to server end.. myVendor
        // let emailOwner = store.createRecord('email-group-owner')
        // emailOwner.set("CompanyId", myNewCompany.id);
        // emailOwner.set("email", me.email);
        // yield emailOwner.save();

        yield me.reload();
        yield adkSessionAccount.loadLoginUserTask.perform(true);
        this.get('router').transitionTo('profile.company');
      } catch (e) {
        console.error(e);
      } //catch

    }),

    clearNewCompany() {
      this.set('companyName', '');
      this.set('companyNameLocal', '');
      this.set('companyWebSite', '');
      this.set('companyBriefDesc', '');
      this.set('industrySelection', []);
      this.set('companyEmail', '');
      this.set('isAgency', false);
      this.set('isMedia', false);
      this.set('companyCountry', '');
      this.set('industrySelection', []);
    },

    clearAdditionCompany() {
      this.set('companyAddress1', '');
      this.set('companyAddress2', '');
      this.set('companyCity', '');
      this.set('companyState', '');
      this.set('companyPostcode', '');
      this.set('companyDesc', '');
      this.set('timezone', null);
      this.set('stateOptions', []);
      this.set('cityOptions', []);
    },

    actions: {
      actionGoToAdditional: function () {
        this.setProperties({
          basicTab: false,
          selectedTab: 'additional'
        });
        this.loadLocationStateTask.perform();
        return false;
      },
      //function actionNewCompany
      actionBackToBasic: function () {
        this.setProperties({
          basicTab: true,
          selectedTab: 'basicInfo'
        });
      },
      actionCancel: function () {
        this.clearNewCompany();
        this.clearAdditionCompany();
        this.get('router').transitionTo('index');
      },
      //actionNewCompanyCancel
      actionChangeRouteTest: function () {
        let adkSessionAccount = this.get('adkSessionAccount');
        adkSessionAccount.loadLoginUserTask.perform(true);
        this.get('router').transitionTo('profile.company');
      },
      actionSaveCompany: function () {
        this.saveToDb.perform();
      },

      actionSelectState(state) {
        this.set('cityOptions', []);
        this.set('companyCity', '');
        this.set('companyState', state);
        this.loadLocationCityTask.perform();
      },

      actionSelectCity(city) {
        this.set('companyCity', city);
      }

    } // actinons  

  });

  _exports.default = _default;
});