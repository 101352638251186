define("adtikir3v1/pods/components/adk-ad-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});