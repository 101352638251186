define("adtikir3v1/pods/components/adk-agency-proposal/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    isOpen: false,
    //Variables
    campaignName: '',
    advertiser: '',
    editMode: Ember.computed('vAgencyProposal.status', 'proposalInvitedExipred', function () {
      let vAgencyProposal = this.get('vAgencyProposal');

      if (this.proposalInvitedExipred) {
        return false;
      }

      if (vAgencyProposal.status === "new") {
        return true;
      }

      return false;
    }),
    genCostArray: function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let vAgencyProposal = this.get('vAgencyProposal');
      let costs = []; //let costs = this.get('costs');

      if (!vAgencyProposal.get('cost')) {
        let costArray = this.get('costArray');
        let decimalSeperator = adkSessionAccount.get('myCompanyDetail').get('decimalSeperator');
        vAgencyProposal.set('cost', costArray.map(function (item) {
          return item + '::' + '0' + decimalSeperator + '00';
        }).join('::'));
      }

      let fields = vAgencyProposal.get('cost').split('::');

      for (let i = 0; i < fields.length; i = i + 2) {
        costs.push({
          title: fields[i],
          value: fields[i + 1]
        });
      }

      this.set('costs', costs);
    },
    loadProposalAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let findObj = {
        page: page,
        pageSize: pagingObj.pageSize
      };

      if (this.aProposalResponseType) {
        let aProposalResponseId = this.get('aProposalResponse').id;
        findObj.aProposalResponse = aProposalResponseId;
      } else {
        let vAgencyProposalId = this.get('vAgencyProposal').id;
        findObj.vProposalAgency = vAgencyProposalId;
      }

      let attachments = yield this.store.query('proposal-attachment', findObj); // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //    set(pagingObj, "total", attachments.meta.page.total); 
      // }

      Ember.set(pagingObj, "result", attachments);
    }).restartable(),

    didReceiveAttrs() {
      //this.set('editMode', this.get('editMode'));
      this.set('costArray', ['creative', 'visualize', 'implementation', 'play', 'misc including contingency', 'taxes']); //this is hack for aProposalResponse

      if (this.get('aProposalResponse')) {
        this.set('vAgencyProposal', this.get('aProposalResponse'));
        this.set('aProposalResponseType', true);
      } else {
        this.set('aProposalResponseType', false);
      } //temp fix for vProposalAgency change to vAgencyProposal


      let vAgencyProposal = this.get('vAgencyProposal');

      if (!vAgencyProposal && this.get('vProposalAgency')) {
        this.set('vAgencyProposal', this.get('vProposalAgency'));
        vAgencyProposal = this.get('vAgencyProposal');
      }

      this.set('adCampaignRfp', vAgencyProposal.get('adCampaignRfp'));
      this.genCostArray();
      this.set('openedTarget', null);
      this.set('refresh', vAgencyProposal.id);

      if (!('proposalInvitedExipred' in this)) {
        this.set('proposalInvitedExipred', false);
      }
    },

    //didReceiveAttrs
    total: Ember.computed('costs.@each.value', function () {
      let total = 0;
      let a = this.get('costs');

      for (var i = 0; i < a.length; i++) {
        total += a[i].value * 1;
      }

      return total;
    }),
    currencyOption: Ember.computed('adCampaignRfp.currencyAccept', function () {
      let currencyAccept = this.get('adCampaignRfp').get('currencyAccept');
      let array = [];
      let values = currencyAccept.split(',');

      for (var i = 0; i < values.length; i++) {
        array.push(values[i]);
      }

      return array;
    }),
    proposalExpiredDateComputed: Ember.computed('vAgencyProposal.responseExpiredDayCount', function () {
      //let adkSessionAccount = this.get('adkSessionAccount');
      let adCampaignRfp = this.get('adCampaignRfp');
      let vAgencyProposal = this.get('vAgencyProposal');
      let responseExpiredDayCount = vAgencyProposal.get('responseExpiredDayCount');
      let validDate = (0, _moment.default)(adCampaignRfp.get('proposalDeadline')).add(responseExpiredDayCount, 'days'); //let dateObj = new Date(adkSessionAccount.getTime());

      let rtnDate = new Date(validDate);
      return rtnDate;
    }),
    submitAdkAgencyProposalTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast'); // event.preventDefault();

      if (this.isOpen) {
        alert("Some field is opened, please close it first");
        return;
      } // let component = this;


      let vAgencyProposal = this.get('vAgencyProposal');
      vAgencyProposal.set('status', 'vSubmitted');
      yield this.saveProposal('submitted');
      this.set('showDialogWithParent', false);
      this.set('editMode', false);
      adkToast.display("Proposal submitted successfully.", "warning");
    }),
    actions: {
      updateNumber(cost, value) {
        //let adkSessionAccount = this.get('adkSEssionAccount');
        //let decimalSeperator = adkSessionAccount.get('myCompanyDetail').get('adkSessionAccount');
        if (typeof value != "string") {
          value = cost.value;
          return false;
        } // we only process strings!  


        if (isNaN(value) || // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        isNaN(parseFloat(value))) {
          value = cost.value;
          return false;
        }

        Ember.set(cost, 'value', value);
      },

      toggleEdit(field, even) {
        let fieldAllow = ['projectObjectives', 'ourUnderstanding', 'aboutUs', 'ourMethodology', 'discovery', 'visualize', 'implementation', 'ourTeam', 'ourResults', 'cost', 'timelines', 'terms', 'responseExpiredDate', 'taxIP', 'finalNote']; //check if allow to edit

        if (!this.editMode) {
          return true;
        }

        let idx = fieldAllow.indexOf(field);

        if (idx != -1) {
          if (this.isOpen) {
            alert("Field is opened, please close it first");
            this.openedTarget.scrollIntoView();
            return;
          } //this.set('isOpen', true);


          this.toggleProperty('isEdit' + fieldAllow[idx]);

          if (this.get('isEdit' + fieldAllow[idx])) {
            this.set('isOpen', field);
            let target = even.currentTarget;
            this.set('openedTarget', target.parentNode.parentNode.parentNode);
          } else {
            this.set('isOpen', false);
            this.set('openedTarget', null);
          }

          return;
        }

        this.toggleProperty('isEditing');
      },

      //toggleEdit
      openDialogWithParent(event) {
        if (this.isOpen) {
          alert("Some field is opened, please close it first");
          return;
        }

        this.set('dialogOrigin', event.currentTarget);
        this.set('showDialogWithParent', true);
      },

      closeDialogWithParent() {
        // this.set('result', result);
        this.set('showDialogWithParent', false); //this.actions.cancelAction();
      },

      toggleProjObjectives() {
        this.toggleProperty('isEditProjectObjectives');
      },

      cancelAction(field) {
        let adkToast = this.get('adkToast');
        let vAgencyProposal = this.get('vAgencyProposal');

        if (field === 'cost') {
          this.genCostArray(); //    let costs = vAgencyProposal.get("cost");  
          //   let costs = this.get('costs');
          //   let newCostStr = costs.map(function (item) {
          //     return item.title + '::' + item.value;
          //   }).join("::");
        }

        if (vAgencyProposal.get('hasDirtyAttributes')) {
          vAgencyProposal.rollbackAttributes();
        }

        adkToast.display("Action cancel", "warning");
        this.set('isOpen', false);
        this.actions.toggleEdit.call(this, field);
      },

      saveAdkAgencyProposal(field) {
        // event.preventDefault();
        let component = this;
        let adkToast = this.get('adkToast');
        let vAgencyProposal = this.get('vAgencyProposal');

        if (field === 'cost') {
          let costs = this.get('costs');
          let newCostStr = costs.map(function (cost) {
            return cost.title + "::" + cost.value;
          }).join("::");
          vAgencyProposal.set("cost", newCostStr);
          vAgencyProposal.set('responseTotalCost', this.get('total'));
        } else if (field === "responseExpiredDate") {
          vAgencyProposal.set("responseExpiredDate", this.get('proposalExpiredDateComputed'));
        }

        this.saveProposal().then(function () {
          component.set('isOpen', false);
          component.actions.toggleEdit.call(component, field);
          adkToast.display("Proposal successfully saved.", "warning");
        });
      },

      nothing() {// Do nothing. Used just to highlight sections.
      }

    }
  });

  _exports.default = _default;
});