define("adtikir3v1/models/media-proposal-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    mediaName: attr('string'),
    promises: attr('string', {
      defaultValue: ''
    }),
    note: attr('string', {
      defaultValue: ''
    }),
    cost: attr('number'),
    adStartDate: attr('date'),
    adEndDate: attr('date'),
    vProposalMedia: belongsTo('v-proposal-media'),
    aMediaProposalResponse: belongsTo('a-media-proposal-response'),
    mediaType: belongsTo('media-type', {
      inverse: null
    }),
    vendorMediaDetail: _emberData.default.belongsTo('vendor-media-detail', {
      inverse: null
    }),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });

  _exports.default = _default;
});