define("adtikir3v1/pods/campaign/details/vendor/media/clientbrief/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Z/sdxJy",
    "block": "{\"symbols\":[],\"statements\":[[5,\"adk-vendor-clientbrief\",[],[[\"@adCampaign\",\"@adCampaignDetail\",\"@adCampaignRfpSetting\",\"@targeting\",\"@saveMe\",\"@fileFor\",\"@saveAttachmentTask\",\"@loadAttachmentTask\"],[[22,\"adCampaign\"],[22,\"adCampaignDetail\"],[22,\"adCampaignMediaRfpSetting\"],[22,\"targeting\"],[28,\"action\",[[23,0,[]],\"saveAndCreateRfp\"],null],\"campaignMediaRfp\",[23,0,[\"saveMediaRfpAttachmentTask\"]],[23,0,[\"loadMediaRfpAttachmentTask\"]]]]],[0,\" \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/details/vendor/media/clientbrief/template.hbs"
    }
  });

  _exports.default = _default;
});