define("adtikir3v1/pods/media/request/controller", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {
  //   computed
  // } from '@ember/object';
  var _default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    mode: '',
    mediaProposalInvited: null,
    vMediaProposal: null,
    selectedAdvertiserRfp: null,
    resetProposalInvited: function () {
      this.setProperties('mediaProposalInvited', null);
    },
    loadVMediaProposalInvitedTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query('v-media-proposal-invited', {
        type: pagingObj.taskType,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page); //alert(pagingObj.refresh + ' ' +  pagingObj.firstFg + ' ' + pagingObj.initFg);

      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, 'initFg', false);
        Ember.set(pagingObj, 'firstFg', false);
      }

      return true;
    }),
    loadVAdMediaProposalInvitedTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query('v-ad-media-proposal-invited', {
        type: pagingObj.taskType,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page); //alert(pagingObj.refresh + ' ' +  pagingObj.firstFg + ' ' + pagingObj.initFg);

      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, 'initFg', false);
        Ember.set(pagingObj, 'firstFg', false);
      }

      return true;
    }),
    loadMediaProposalAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let vMediaProposalId = this.get('vMediaProposal').id;
      let attachments = yield this.store.query('media-proposal-attachment', {
        vMediaProposal: vMediaProposalId,
        page: page,
        pageSize: pagingObj.pageSize
      }); // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }

      Ember.set(pagingObj, "result", attachments);
    }).restartable(),
    saveMediaProposalAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentObj) {
      let adkToast = this.get('adkToast');
      let vMediaProposal = this.get('vMediaProposal');
      let attachment = this.store.createRecord('media-proposal-attachment', {
        attachFile: attachmentObj.attachFile,
        description: attachmentObj.description,
        vMediaProposal: vMediaProposal
      });

      try {
        attachment = yield attachment.save();
        return attachment;
      } catch (e) {
        console.log(e);

        if (e.errors) {
          adkToast.display("Error, " + e.errors[0].detail, "error", 1500);
        } else {
          adkToast.display("Error, " + e, "error", 1500);
        }

        throw e;
      }
    }).drop(),
    loadAdMediaProposalAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let vAdMediaProposalId = this.get('vAdMediaProposal').id;
      let attachments = yield this.store.query('ad-media-proposal-attachment', {
        vAdMediaProposal: vAdMediaProposalId,
        page: page,
        pageSize: pagingObj.pageSize
      }); // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }

      Ember.set(pagingObj, "result", attachments);
    }).restartable(),
    saveAdMediaProposalAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentObj) {
      let adkToast = this.get('adkToast');
      let vAdMediaProposal = this.get('vAdMediaProposal');
      let attachment = this.store.createRecord('ad-media-proposal-attachment', {
        attachFile: attachmentObj.attachFile,
        description: attachmentObj.description,
        vAdMediaProposal: vAdMediaProposal
      });

      try {
        attachment = yield attachment.save();
        return attachment;
      } catch (e) {
        console.log(e);

        if (e.errors) {
          adkToast.display("Error, " + e.errors[0].detail, "error", 1500);
        } else {
          adkToast.display("Error, " + e, "error", 1500);
        }

        throw e;
      }
    }).drop(),

    // loadMediaRfpAttachmentTask: task(function* (page, pagingObj) {
    //   let adCampaignMediaRfpId = this.get('adCampaignMediaRfp').id
    //   let attachments = yield this.store.query('media-rfp-attachment', {
    //     adCampaignMediaRfp: adCampaignMediaRfpId,
    //     page: page,
    //     pageSize: pagingObj.pageSize
    //   });
    //   if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
    //     set(pagingObj, "total", attachments.meta.page.total);
    //   }
    //   set(pagingObj, "result", attachments);
    //   if (pagingObj.refresh) {
    //     this.set('refreshRfpAttachment', false);
    //   }
    //   //return attachments;
    // }).restartable(),
    resetForm() {
      let runArray = [['Invited', 'new'], ['Submitted', 'submitted'], ['Expired', 'expired']];

      for (let i = 0; i < runArray.length; i++) {
        this.set('vMediaProposalInvitedObj' + runArray[i][0], {
          page: 0,
          pageSize: 20,
          action: this.get("loadVMediaProposalInvitedTask"),
          result: null,
          refresh: false,
          taskType: runArray[i][1],
          type: "emberdata",
          initFg: true,
          firstFg: true
        });
        this.set('vAdMediaProposalInvitedObj' + runArray[i][0], {
          page: 0,
          pageSize: 20,
          action: this.get("loadVAdMediaProposalInvitedTask"),
          result: null,
          refresh: false,
          taskType: runArray[i][1],
          type: "emberdata",
          initFg: true,
          firstFg: true
        });
      } //for


      this.set('loopArray', [['adkExpansionPanelAgencyAwarded', 'New Request List', 'Newly request invitation', 'vMediaProposalInvitedObjInvited', 'vAdMediaProposalInvitedObjInvited'], ['adkExpansionPanelAgencyPending', 'Submitted List', 'Invitation with submited proposal', 'vMediaProposalInvitedObjSubmitted', 'vAdMediaProposalInvitedObjSubmitted'], ['adkExpansionPanelAgencyAccept', 'Expired List', 'Expired invitation', 'vMediaProposalInvitedObjExpired', 'vAdMediaProposalInvitedObjExpired']]);
      this.set('mediaProposalInvited', null); // if (this.vMediaProposalId) {
      //   this.store.findRecord('v-proposal-media', this.vMediaProposalId)
      //     .then(function (vMediaProposal) {
      //       this.set('vMediaProposal', vMediaProposal);
      //     });
      // }

      this.clearProposal();
    },

    clearProposal() {
      this.set('vMediaProposal', null);
      this.set('adCampaignMediaRfp', null);
      this.set('adCampaignMediaRfpBody', null);
      this.set('vAdMediaProposal', null);
      this.set('adRfp', null);
      this.set('adMediaRfpBody', null);
      this.set('loadMediaRfpTaskInstance', null);
      this.set('loadAdMediaRfpTaskInstance', null);
      this.set('isNotAdRequest', false);
    },

    loadMediaRfpTask: (0, _emberConcurrency.task)(function* () {
      let vMediaProposal;
      let mediaProposalItems;
      let adCampaignMediaRfp = yield this.get('mediaProposalInvited').get('adCampaignMediaRfp');
      let adCampaignMediaRfpBody = yield this.get('mediaProposalInvited').get('adCampaignMediaRfpBody');
      this.set('adCampaignMediaRfp', adCampaignMediaRfp);
      this.set('adCampaignMediaRfpBody', adCampaignMediaRfpBody);
      vMediaProposal = yield this.store.query('v-proposal-media', {
        adCampaignMediaRfpBody: adCampaignMediaRfpBody.id
      }); // load vMediaProposal here

      if (vMediaProposal && vMediaProposal.length > 0) {
        this.set('vMediaProposal', vMediaProposal.firstObject);
        mediaProposalItems = yield this.store.query('media-proposal-item', {
          vMediaProposal: vMediaProposal.id
        });
        this.set('mediaProposalItems', mediaProposalItems);
        this.set('isNotAdRequest', true);
      } else {
        this.set('vMediaProposal', null);
      }
    }),
    loadAdMediaRfpTask: (0, _emberConcurrency.task)(function* () {
      let vAdMediaProposal;
      let adMediaProposalItems;
      let mediaProposalInvited = this.get('mediaProposalInvited');
      let adRfp = yield mediaProposalInvited.get('adRfp');
      let adMediaRfpBody = yield mediaProposalInvited.get('adMediaRfpBody');
      this.set('adRfp', adRfp);
      this.set('adMediaRfpBody', adMediaRfpBody);
      vAdMediaProposal = yield this.store.query('v-ad-media-proposal', {
        adMediaRfpBody: adMediaRfpBody.id
      }); // load vMediaProposal here

      if (vAdMediaProposal && vAdMediaProposal.length > 0) {
        this.set('vAdMediaProposal', vAdMediaProposal.firstObject);
        adMediaProposalItems = yield this.store.query('ad-media-proposal-item', {
          vAdMediaProposal: vAdMediaProposal.id
        });
        this.set('adMediaProposalItems', adMediaProposalItems);
      } else {
        this.set('vAdMediaProposal', null);
      }
    }),
    // setProposalInvited(proposalInvited,adCampaignRfpId) {            
    setMediaProposalInvitedTask: (0, _emberConcurrency.task)(function* (mediaProposalInvited) {
      this.clearProposal();
      this.set('mediaProposalInvited', mediaProposalInvited); // this.set('adCampaignRfpId', adCampaignRfpId);

      this.set('loadMediaRfpTaskInstance', this.loadMediaRfpTask.perform());
      yield this.get('loadMediaRfpTaskInstance');

      if (mediaProposalInvited.status === "submitted") {
        if (this.get('vMediaProposal')) {
          let router = this.get('router');
          router.transitionTo('media.awards', {
            queryParams: {
              vMediaProposalId: this.get('vMediaProposal').id
            }
          });
        }
      }
    }),
    // setProposalInvited(proposalInvited,adCampaignRfpId) {            
    setAdMediaProposalInvitedTask: (0, _emberConcurrency.task)(function* (mediaProposalInvited) {
      this.clearProposal();
      this.set('mediaProposalInvited', mediaProposalInvited); // this.set('adCampaignRfpId', adCampaignRfpId);

      this.set('loadAdMediaRfpTaskInstance', this.loadAdMediaRfpTask.perform());
      yield this.get('loadAdMediaRfpTaskInstance');

      if (mediaProposalInvited.status === "submitted") {
        if (this.get('vAdMediaProposal')) {
          let router = this.get('router');
          router.transitionTo('media.awards', {
            queryParams: {
              vAdMediaProposalId: this.get('vAdMediaProposal').id
            }
          });
        }
      }
    }),
    createMediaProposalTask: (0, _emberConcurrency.task)(function* (mediaProposalInvited, adCampaignMediaRfpBody) {
      let adkToast = this.get('adkToast');
      let validDate = (0, _moment.default)(adCampaignMediaRfpBody.proposalDeadline).add(30, 'days');
      let adCampaignMediaRfp = yield adCampaignMediaRfpBody.get('adCampaignMediaRfp');
      let vMediaProposal = this.store.createRecord('v-proposal-media', {
        campaignName: mediaProposalInvited.campaignName,
        responseExpiredDate: (0, _moment.default)(validDate).toDate()
      });
      vMediaProposal.set('adCampaignMediaRfpBody', adCampaignMediaRfpBody);
      yield vMediaProposal.save();

      if (vMediaProposal.quotedCurrency === adCampaignMediaRfp.foreignCurrency) {
        vMediaProposal.set('isForeignCurrency', true);
        vMediaProposal.set('localCurrency', adCampaignMediaRfp.localCurrency); //ok.. is using foreignCurrency...

        if (vMediaProposal.status === 'vAccept' || vMediaProposal.status === 'vReject' || vMediaProposal.status === 'project' || vMediaProposal.status === 'projectReject') {
          vMediaProposal.set('exchangeRate', vMediaProposal.vFinalCurrencyRate);
          vMediaProposal.set('exchangeRateDate', vMediaProposal.vFinalCurrencyRateDate);
        } else {
          let currencyId = adCampaignMediaRfp.foreignCurrency + 'to' + adCampaignMediaRfp.localCurrency;
          let currencyRate = yield this.store.findRecord('exchange-rate', currencyId);
          vMediaProposal.set('exchangeRate', currencyRate.exchangeRate);
          vMediaProposal.set('exchangeRateDate', currencyRate.exchangeDate);
        }
      }

      this.set('vMediaProposal', vMediaProposal);
      adkToast.display("Proposal successfully created.", "warning");
    }),
    //createProposalAgency
    createAdMediaProposalTask: (0, _emberConcurrency.task)(function* (mediaProposalInvited, adMediaRfpBody) {
      let adkToast = this.get('adkToast');
      let validDate = (0, _moment.default)(adMediaRfpBody.proposalDeadline).add(30, 'days');
      let adRfp = yield adMediaRfpBody.get('adRfp');
      let vAdMediaProposal = this.store.createRecord('v-ad-media-proposal', {
        campaignName: mediaProposalInvited.campaignName,
        responseExpiredDate: (0, _moment.default)(validDate).toDate()
      });
      vAdMediaProposal.set('adMediaRfpBody', adMediaRfpBody);
      yield vAdMediaProposal.save();

      if (vAdMediaProposal.quotedCurrency === adRfp.foreignCurrency) {
        vAdMediaProposal.set('isForeignCurrency', true);
        vAdMediaProposal.set('localCurrency', adRfp.localCurrency); //ok.. is using foreignCurrency...

        if (vAdMediaProposal.status === 'vAccept' || vAdMediaProposal.status === 'vReject' || vAdMediaProposal.status === 'project' || vAdMediaProposal.status === 'projectReject') {
          vAdMediaProposal.set('exchangeRate', vAdMediaProposal.vFinalCurrencyRate);
          vAdMediaProposal.set('exchangeRateDate', vAdMediaProposal.vFinalCurrencyRateDate);
        } else {
          let currencyId = adRfp.foreignCurrency + 'to' + adRfp.localCurrency;
          let currencyRate = yield this.store.findRecord('exchange-rate', currencyId);
          vAdMediaProposal.set('exchangeRate', currencyRate.exchangeRate);
          vAdMediaProposal.set('exchangeRateDate', currencyRate.exchangeDate);
        }
      }

      this.set('vAdMediaProposal', vAdMediaProposal);
      adkToast.display("Ad media proposal successfully created.", "warning");
    }),
    //createProposalAgency
    actions: {
      error() {
        if (this.get('vMediaProposal')) {
          this.set('vMediaProposal', null);
        }
      },

      // Patch or Update Agency Proposal from component AdkAgencyProposal
      updateMediaProposal(type) {
        let vMediaProposal = this.get('vMediaProposal');

        if (type == "submitted") {
          let controller = this; //need to do invitee update here;

          return vMediaProposal.save().then(function () {
            //controller need to refresh the list;
            Ember.set(controller.get('vMediaProposalInvitedObjSubmitted'), "refresh", true);
            Ember.set(controller.get('vMediaProposalInvitedObjSubmitted'), "initFg", true);
            Ember.set(controller.get('vMediaProposalInvitedObjSubmitted'), "firstFg", true);
            Ember.set(controller.get('vMediaProposalInvitedObjInvited'), "refresh", true);
            Ember.set(controller.get('vMediaProposalInvitedObjInvited'), "initFg", true);
            Ember.set(controller.get('vMediaProposalInvitedObjInvited'), "firstFg", true);
          });
        } //this.vProposalAgency.setProperties(componentValues);


        return vMediaProposal.save();
      },

      // Patch or Update Agency Proposal from component AdkAgencyProposal
      updateAdMediaProposal(type) {
        let vAdMediaProposal = this.get('vAdMediaProposal');

        if (type == "submitted") {
          let controller = this; //need to do invitee update here;

          return vAdMediaProposal.save().then(function () {
            //controller need to refresh the list;
            Ember.set(controller.get('vAdMediaProposalInvitedObjSubmitted'), "refresh", true);
            Ember.set(controller.get('vAdMediaProposalInvitedObjSubmitted'), "initFg", true);
            Ember.set(controller.get('vAdMediaProposalInvitedObjSubmitted'), "firstFg", true);
            Ember.set(controller.get('vAdMediaProposalInvitedObjInvited'), "refresh", true);
            Ember.set(controller.get('vAdMediaProposalInvitedObjInvited'), "initFg", true);
            Ember.set(controller.get('vAdMediaProposalInvitedObjInvited'), "firstFg", true);
          });
        } //this.vProposalAgency.setProperties(componentValues);


        return vAdMediaProposal.save();
      }

    } //End of actions

  });

  _exports.default = _default;
});