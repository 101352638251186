define("adtikir3v1/pods/profile/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    actions: {
      saveMe() {
        //let controller = this;
        // if(this.filesid){
        //     this.model.set('profilePic', this.filesid[0]);
        // }
        return this.model.save();
      } // toggleResetPassDialog() {
      //     this.set("showResetPassDialog", !this.showResetPassDialog);
      // },
      // closeResetPassDialog(ev) {
      //     if(ev == 'save'){
      //         this.set("showResetPassDialog", false);
      //         ///password not saved yet
      //     }
      //     else{
      //         this.set('oldPassword', '');
      //         this.set('newPassword', '');
      //         this.set('confirmNewPassword', '');
      //         this.set("showResetPassDialog", false);
      //     }
      // },
      // cancelUserUpdate() {
      //     this.set('email', '');
      //     this.set('role', '');
      // },
      // uploadImage(attrs) {
      //     if(attrs.filesid.length != 0) {
      //         this.set("filesid", attrs.filesid);
      //     }
      //     if(this.filesid){
      //         this.model.set('profilePic', this.filesid[0]);
      //         this.model.set("action","saveProfilePic");
      //     }
      //     this.model.save();
      // }
      //    togglePrompt() {
      //         this.set("showResetPassDialog", !this.showResetPassDialog);
      //    }


    }
  });

  _exports.default = _default;
});