define("adtikir3v1/pods/agency/admanager/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    adkSessionAccount: Ember.inject.service(),
    // queryParams: ['gridlayout'],
    // gridlayout: true,
    loadCompanyListTasks: (0, _emberConcurrency.task)(function* () {
      let result = yield this.store.query('list-company-ad-agency', {
        date: new Date().getTime()
      });
      this.set('companyList', result);
    }) // actions: {
    //   setGridLayout(option) {
    //     this.set('gridlayout', option);
    //   }
    // }

  });

  _exports.default = _default;
});