define("adtikir3v1/pods/campaign/details/ads/adnew/controller", ["exports", "sanitize-html"], function (_exports, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    actions: {
      backToAdNewRoute: function () {
        let adcampaign = this.get('adcampaign');
        this.get('router').transitionTo('campaign.details.ads', adcampaign.id);
      },

      saveAd(componentValues) {
        let adCampaignDetail = this.get('adCampaignDetail');
        let router = this.get('router');
        let adkToast = this.get('adkToast');
        let sanitizeName = (0, _sanitizeHtml.default)(componentValues.adName);
        let sanitizeDescription = (0, _sanitizeHtml.default)(componentValues.description);
        let sanitizeAdMediaFormatDetails = (0, _sanitizeHtml.default)(componentValues.adMediaFormatDetails);
        let newAdvert = this.store.createRecord('ad', {
          adName: sanitizeName,
          description: sanitizeDescription,
          estBudget: componentValues.estBudget,
          estAgencyBudget: componentValues.estAgencyBudget,
          estMediaBudget: componentValues.estMediaBudget,
          estMiscBudget: componentValues.estMiscBudget,
          adPic: componentValues.adPic,
          mediatype: componentValues.mediatype,
          mediaformat: componentValues.mediaformat,
          adPlayStartDate: componentValues.adPlayStartDate,
          adPlayEndDate: componentValues.adPlayEndDate,
          adMediaFormatDetails: sanitizeAdMediaFormatDetails,
          workFlowStatus: componentValues.workFlowStatus,
          adColor: componentValues.adColor,
          activeStatus: true
        });
        newAdvert.set('adCampaignDetail', adCampaignDetail);
        return newAdvert.save().then(onAdSuccess, onAdFailure);

        function onAdSuccess() {
          adkToast.display("Ad Saved Successfully", "warning", 6000);
          router.transitionTo('campaign.details.ads');
        }

        function onAdFailure() {
          adkToast.display("Ad Not Saved", "error", 6000); //router.transitionTo('campaign.details.ads.adnew');
        }
      }

    }
  });

  _exports.default = _default;
});