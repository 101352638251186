define("adtikir3v1/helpers/active-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Author: Soon Lam
  // Date: May 17, 2018
  // Purpose: Set a List item as an active class to highlight a list item
  // cont: in this case, an Image. Used in selecting images for new campaign
  var _default = Ember.Helper.helper(function activeClass(params
  /*, hash*/
  ) {
    const index = params[0];
    const active = params[1];
    let styleClass;

    if (params[2]) {
      styleClass = params[2];
    } else {
      styleClass = 'active-class';
    }

    return index === active ? styleClass : '';
  });

  _exports.default = _default;
});