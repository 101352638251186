define("adtikir3v1/helpers/prevent-default", ["exports", "@abcum/ember-helpers/helpers/prevent-default"], function (_exports, _preventDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _preventDefault.default;
    }
  });
  Object.defineProperty(_exports, "preventDefault", {
    enumerable: true,
    get: function () {
      return _preventDefault.preventDefault;
    }
  });
});