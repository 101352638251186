define("adtikir3v1/pods/application/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    adkSessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    loadModelTask: (0, _emberConcurrency.task)(function* () {
      let adkSessionAccount = this.get('adkSessionAccount');

      try {
        yield adkSessionAccount.get('loadLoginUserTask').perform();
        let timeZone = yield adkSessionAccount.get('timeZone');
        return {
          me: adkSessionAccount.me,
          lastLoginRole: adkSessionAccount.lastLoginRole,
          myCompany: adkSessionAccount.myCompany,
          timeZone: timeZone
        };
      } catch (e) {
        this.intermediateTransitionTo('error', e);
        throw e; //let the model hook do the error report
      }
    }),
    beforeModel: function () {
      this._super(...arguments);

      let adkSessionAccount = this.get('adkSessionAccount'); // console.log(adkSessionAccount);

      let session = adkSessionAccount.get('session');
      return session.initKeycloak();
    },
    model: function () {
      return this.get('loadModelTask').perform();
    },
    redirect: function () {
      let adkSessionAccount = this.get('adkSessionAccount');

      if (adkSessionAccount.session.authenticated) {
        if (adkSessionAccount.get('needSetupCompany')) {
          this.get('router').transitionTo('newcompany');
        } else if (adkSessionAccount.changeRole) {
          adkSessionAccount.set('changeRole', false);
          this.get('router').transitionTo('/');
        }
      } //if authenticated

    }
  }); // export default class ApplicationRoute extends Route.extend(KeycloakAuthenticatedRouteMixin){
  //   @service adkSessionAccount;
  //   @service router;
  //   @task(function* () {
  //     let adkSessionAccount = this.get('adkSessionAccount');
  //     try {
  //       yield adkSessionAccount.get('loadLoginUserTask').perform();
  //       let timeZone = yield adkSessionAccount.get('timeZone');
  //       return {
  //         me: adkSessionAccount.me,
  //         lastLoginRole: adkSessionAccount.lastLoginRole,
  //         myCompany: adkSessionAccount.myCompany,
  //         timeZone: timeZone
  //       }
  //     } catch (e) {
  //       this.intermediateTransitionTo('error', e);
  //       throw (e); //let the model hook do the error report
  //     }
  //   }) loadModelTask;
  //   beforeModel() {
  //     super.beforeModel(...arguments);
  //     let adkSessionAccount = this.get('adkSessionAccount');
  //     console.log(adkSessionAccount);
  //     let session = adkSessionAccount.get('session');
  //     return session.initKeycloak();
  //   }
  //   model() {  
  //     return this.get('loadModelTask').perform();
  //   }
  //   redirect() {
  //     let adkSessionAccount = this.get('adkSessionAccount');
  //     if (adkSessionAccount.session.authenticated) {
  //       if (adkSessionAccount.get('needSetupCompany')) {
  //         this.get('router').transitionTo('newcompany');
  //       } else if (adkSessionAccount.changeRole) {
  //         adkSessionAccount.set('changeRole', false);
  //         this.get('router').transitionTo('/');
  //       }
  //     }  //if authenticated
  //   }  //redirect
  // }


  _exports.default = _default;
});