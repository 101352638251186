define("adtikir3v1/models/timezone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { computed } from '@ember/object';
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    zoneName: attr('string'),
    gmtOffset: attr('number'),
    abbreviation: attr('string'),
    code: Ember.computed.alias("this.id")
  });

  _exports.default = _default;
});