define("adtikir3v1/helpers/get-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function getCurrency([exchangeRate, ammout]) {
    return exchangeRate * ammout * 1;
  });

  _exports.default = _default;
});