define("adtikir3v1/pods/media/awards/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import {
  //  computed
  //} from '@ember/object';
  var _default = Ember.Controller.extend({
    queryParams: ['vMediaProposalId', 'vAdMediaProposalId'],
    vMediaProposalId: null,
    vAdMediaProposalId: null,
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    mode: '',
    //proposalMedia: false,
    //awardedProj: '',
    adCampaignMediaRfp: '',
    adCampaignMediaRfpBody: '',
    adRfp: '',
    adMediaRfpBody: '',
    //showAward: false,
    //selectedAdvertiserRfp: null,
    //type will be pending, award, accept and '' for this route
    loadVMediaProposalByTypeTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query('v-proposal-media', {
        type: pagingObj.taskType,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);

      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, 'initFg', false);
        Ember.set(pagingObj, 'firstFg', false);
      }

      return true;
    }),
    loadVAdMediaProposalByTypeTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query('v-ad-media-proposal', {
        type: pagingObj.taskType,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);

      if (pagingObj.firstFg && (!result || result.length === 0)) {
        Ember.set(pagingObj, 'initFg', false);
        Ember.set(pagingObj, 'firstFg', false);
      }

      return true;
    }),
    runLoadMediaProposalTask: (0, _emberConcurrency.task)(function* (vMediaProposalId) {
      // GET /blog-posts/1
      let vMediaProposal = yield this.store.findRecord('v-proposal-media', vMediaProposalId);
      this.set('setAwardTaskInstance', this.get('setAwardTask').perform(vMediaProposal));
    }),
    runLoadAdMediaProposalTask: (0, _emberConcurrency.task)(function* (vAdMediaProposalId) {
      // GET /blog-posts/1
      let vAdMediaProposal = yield this.store.findRecord('v-ad-media-proposal', vAdMediaProposalId);
      this.set('setAdMediaAwardTaskInstance', this.get('setAdMediaAwardTask').perform(vAdMediaProposal));
    }),

    init() {
      this._super(...arguments);
    },

    resetForm() {
      let runArray = [['PendingType', 'pending'], ['AcceptType', 'toAccept'], ['AwardType', 'awarded'], ['OthersType', '']];

      for (let i = 0; i < runArray.length; i++) {
        this.set('vMediaProposal' + runArray[i][0], {
          page: 0,
          pageSize: 20,
          action: this.get("loadVMediaProposalByTypeTask"),
          result: null,
          refresh: false,
          taskType: runArray[i][1],
          type: "emberdata",
          initFg: true,
          firstFg: true
        });
        this.set('vAdMediaProposal' + runArray[i][0], {
          page: 0,
          pageSize: 20,
          action: this.get("loadVAdMediaProposalByTypeTask"),
          result: null,
          refresh: false,
          taskType: runArray[i][1],
          type: "emberdata",
          initFg: true,
          firstFg: true
        });
      } //for


      this.set('loopArray', [['adkExpansionPanelAgencyAccept', 'To Accept', 'Accept', 'vMediaProposalAcceptType', 'vAdMediaProposalAcceptType'], ['adkExpansionPanelAgencyPending', //class
      'Pending', //title
      'Waiting for award', //titleSummary
      'vMediaProposalPendingType', 'vAdMediaProposalPendingType'], ['adkExpansionPanelAgencyAwarded', 'Awarded Projects', 'Awarded', 'vMediaProposalAwardType', 'vAdMediaProposalAwardType'], ['adkExpansionPanelAgencyRequest', 'Did Not Win', 'DNW', 'vMediaProposalOthersType', 'vAdMediaProposalOthersType']]);
      this.clearVar();
    },

    clearVar() {
      this.set('vMediaProposal', null);
      this.set('adCampaignMediaRfp', null);
      this.set('adCampaignMediaRfpBody', null);
      this.set('vAdMediaProposal', null);
      this.set('adRfp', null);
      this.set('adMediaRfpBody', null);
      this.set('isAdCampaign', true);
    },

    setAwardTask: (0, _emberConcurrency.task)(function* (vMediaProposal) {
      this.clearVar();
      this.set('vMediaProposal', vMediaProposal);
      let adCampaignMediaRfpBody = yield vMediaProposal.get('adCampaignMediaRfpBody');
      this.set('adCampaignMediaRfpBody', adCampaignMediaRfpBody);
      let adCampaignMediaRfp = yield vMediaProposal.get('adCampaignMediaRfp');
      this.set('adCampaignMediaRfp', adCampaignMediaRfp);

      if (vMediaProposal.quotedCurrency === adCampaignMediaRfp.foreignCurrency) {
        vMediaProposal.set('isForeignCurrency', true);
        vMediaProposal.set('localCurrency', adCampaignMediaRfp.localCurrency); //ok.. is using foreignCurrency...

        if (vMediaProposal.status === 'vAccept' || vMediaProposal.status === 'vReject' || vMediaProposal.status === 'project' || vMediaProposal.status === 'projectReject') {
          vMediaProposal.set('exchangeRate', vMediaProposal.vFinalCurrencyRate);
          vMediaProposal.set('exchangeRateDate', vMediaProposal.vFinalCurrencyRateDate);
        } else {
          let currencyId = adCampaignMediaRfp.foreignCurrency + 'to' + adCampaignMediaRfp.localCurrency;
          let currencyRate = yield this.store.findRecord('exchange-rate', currencyId);
          vMediaProposal.set('exchangeRate', currencyRate.exchangeRate);
          vMediaProposal.set('exchangeRateDate', currencyRate.exchangeDate);
        }
      }
    }),
    setAdMediaAwardTask: (0, _emberConcurrency.task)(function* (vAdMediaProposal) {
      this.clearVar();
      this.set('vAdMediaProposal', vAdMediaProposal);
      let adMediaRfpBody = yield vAdMediaProposal.get('adMediaRfpBody');
      this.set('adMediaRfpBody', adMediaRfpBody);
      let adRfp = yield vAdMediaProposal.get('adRfp');
      this.set('adRfp', adRfp);

      if (vAdMediaProposal.quotedCurrency === adRfp.foreignCurrency) {
        vAdMediaProposal.set('isForeignCurrency', true);
        vAdMediaProposal.set('localCurrency', adRfp.localCurrency); //ok.. is using foreignCurrency...

        if (vAdMediaProposal.status === 'vAccept' || vAdMediaProposal.status === 'vReject' || vAdMediaProposal.status === 'project' || vAdMediaProposal.status === 'projectReject') {
          vAdMediaProposal.set('exchangeRate', vAdMediaProposal.vFinalCurrencyRate);
          vAdMediaProposal.set('exchangeRateDate', vAdMediaProposal.vFinalCurrencyRateDate);
        } else {
          let currencyId = adRfp.foreignCurrency + 'to' + adRfp.localCurrency;
          let currencyRate = yield this.store.findRecord('exchange-rate', currencyId);
          vAdMediaProposal.set('exchangeRate', currencyRate.exchangeRate);
          vAdMediaProposal.set('exchangeRateDate', currencyRate.exchangeDate);
        }
      }

      this.set('isAdCampaign', false);
    }),
    actions: {
      // error(error) {
      //   if (this.get('vproposalMedia')) {
      //     this.set('vproposalMedia', null);
      //   };
      // },
      // Patch or Update Agency Proposal from component AdkAgencyProposal
      updateMediaProposal(componentValues) {
        this.vMediaProposal.setProperties(componentValues);
        this.vMediaProposal.save(); // .then(() => {
        // return vproposalMedia;
        // });
      }

    } //End of actions

  });

  _exports.default = _default;
});