define("adtikir3v1/helpers/object-key", ["exports", "@abcum/ember-helpers/helpers/object-key"], function (_exports, _objectKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _objectKey.default;
    }
  });
  Object.defineProperty(_exports, "objectKey", {
    enumerable: true,
    get: function () {
      return _objectKey.objectKey;
    }
  });
});