define("adtikir3v1/pods/campaign/details/vendor/agency/proposal/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {
  //   A
  // } from '@ember/array';
  var _default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    adkSessionAccount: Ember.inject.service(),
    loadModelTask: (0, _emberConcurrency.task)(function* () {
      let adCampaign = yield this.modelFor('campaign.details').adCampaign;
      let adCampaignDetail = yield adCampaign.get('adCampaignDetail'); // let adCampaignRfpSetting =
      //   yield this.store.findRecord('ad-campaign-rfp-setting',
      //     adCampaign.id + "_rfpSetting"
      //   );

      let adCampaignRfp = null; // if (adCampaignRfpSetting.adCampaignRfpId) {
      //   adCampaignRfp = yield this.store.findRecord('ad-campaign-rfp',
      //     adCampaignRfpSetting.adCampaignRfpId
      //   );
      // }

      adCampaignRfp = yield this.store.query('ad-campaign-rfp', {
        adCampaign: adCampaign.id
      });
      adCampaignRfp = yield adCampaignRfp.get('firstObject');
      return {
        adCampaign: adCampaign,
        adCampaignDetail: adCampaignDetail,
        //adCampaignRfpSetting: adCampaignRfpSetting,
        adCampaignRfp: adCampaignRfp
      }; // let proposal = yield adcampaign.get('proposal');
      // controller.set('proposal', proposal);
      // let proposalResponses =  yield proposal.get('proposalResponses');
      // if (proposalResponses === null || proposalResponses === "") {
      //   proposalResponses = A();      
      // } 
      // controller.set('proposalResponses', proposalResponses);
      // if (proposalResponses.length > 0) {
      //   controller.set('proposalResponse', yield proposalResponses.firstObject);
      // }
      // let proposalShortlisteds =  yield proposal.get('proposalShortlisteds');
      // if (proposalShortlisteds === null || proposalShortlisteds === "") {
      //   proposal.set('proposalShortlisteds', A());
      //   proposal.save();
      //   proposalShortlisteds = proposal.get('proposalShortlisteds');
      // } 
      // controller.set('proposalShortlisteds', proposalShortlisteds);    
    }),

    model() {
      return this.get('loadModelTask').perform();
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('adCampaignRfpSetting', model.adCampaignRfpSetting);
      controller.set('adCampaignRfp', model.adCampaignRfp);
    },
    actions: {
      willTransition() {
        this.controller.cleanUp(); //this.controller.set('refresh', false);
      }

    }
  });

  _exports.default = _default;
});