define("adtikir3v1/models/invitation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo // hasMany

  } = _emberData.default;

  var _default = Model.extend({
    myCompany: belongsTo('my-company', {
      async: true
    }),
    email: attr('string'),
    role: attr('string'),
    note: attr('string'),
    expiredDate: attr('date'),
    firstName: attr('string'),
    lastName: attr('string'),
    expiredDay: attr('number'),
    inviterEmail: attr('string'),
    inviterFirstName: attr('string'),
    inviterLastName: attr('string'),
    createAt: attr('date'),
    updatedAt: attr('date'),
    status: attr('string')
  });

  _exports.default = _default;
});