define("adtikir3v1/models/company-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com
  const {
    Model,
    attr,
    belongsTo // hasMany

  } = _emberData.default;

  var _default = Model.extend({
    // companies: DS.hasMany('company', { async: true, inverse: 'companyOwner' }),
    email: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    status: attr('string'),
    profilePic: attr('string'),
    companyRole: belongsTo('company-role', {
      async: true
    }) // userName: computed('firstname', 'lastname', function () {
    //   return `${this.get('firstName')} ${this.get('lastName')}`;
    // }),

  });

  _exports.default = _default;
});