define("adtikir3v1/pods/components/adk-campaign-invoice/component", ["exports", "adtikir3v1/themes/bootstrap4", "adtikir3v1/utils/adk-date-time", "moment", "ember-concurrency"], function (_exports, _bootstrap, _adkDateTime, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    bootstrap4Theme: _bootstrap.default.create(),
    invoiceNumber: '',
    description: '',
    invoiceAmount: 0,
    notes: '',
    selectedBasicTab: "submittedListing",
    resetForm: function () {
      let todayDate = this.get('adkSessionAccount').get('myToday');
      this.set('invoiceNumber', '');
      this.set('description', '');
      this.set('invoiceAmount', 0);
      this.set('notes', '');
      this.set('invoiceDoc', '');
      this.set('accountNumber', '');
      this.set('dueDate', todayDate);
      this.set('dueDateCenter', todayDate);
    },

    init() {
      this._super(...arguments);

      this.set('newCampaignInvoiceFlag', false);
      let todayDate = this.get('adkSessionAccount').get('myToday');
      this.set('dueDate', todayDate);
      this.set('dueDateCenter', todayDate);
      this.numberValidation = [{
        message: 'Please provide a valid value (99999999.99)',
        validate: inputValue => {
          let valuePattern = /^\d+(\.\d{1,2})?$/;
          return valuePattern.test(inputValue);
        }
      }];
      let component = this;
      let adkSessionAccount = this.get('adkSessionAccount');
      let company = adkSessionAccount.get('myCompany');
      company.get('myCompanyDetail').then(function (myCompanyDetail) {
        myCompanyDetail.get('financialAccounts').then(function (financialAccounts) {
          component.set('financialAccounts', financialAccounts);
        });
      });
      this.set('component', component);
      this.invoiceTableColumn = [{
        "title": "Ad Name",
        "propertyName": "ad.adName"
      }, {
        "title": "Invoice Number",
        "propertyName": "invoiceNumber"
      }, {
        "title": "Work Flow",
        "propertyName": "workFlowStep"
      }, {
        "className": "adkTable24Width",
        "propertyName": "description"
      }, {
        "title": "Invoice Amount",
        "propertyName": "invoiceAmount"
      }, // { 
      //   "title": "Default Currency",
      //   "propertyName": "defaultCurrency"
      // },
      // {
      //   "title": "Foreign Currency",
      //   "propertyName": "foreignCurrency"
      // },
      {
        "title": "Submission Date",
        "className": "adkTable11Width",
        "propertyName": "submissionDate",
        "component": "invoiceSubmissionDateFormat"
      }, {
        "title": "Notes",
        "propertyName": "notes"
      }, {
        "title": "Invoice Doc",
        "component": "invoiceInfoComponent"
      }, {
        "title": "Due Date",
        "className": "adkTable11Width",
        "propertyName": "dueDate",
        "component": "invoiceDueDateFormat"
      }, {
        "title": "Company",
        "propertyName": "companyName"
      }, {
        "title": "Status",
        "component": "invoiceApproveComponent"
      }];
      this.invoiceTable2Column = [{
        "title": "Ad Name",
        "propertyName": "ad.adName"
      }, {
        "title": "Invoice Number",
        "propertyName": "invoiceNumber"
      }, {
        "title": "Work Flow",
        "propertyName": "workFlowStep"
      }, {
        "className": "adkTable24Width",
        "propertyName": "description"
      }, {
        "title": "Invoice Amount",
        "propertyName": "invoiceAmount"
      }, {
        "title": "Account To Bill",
        "propertyName": "accountNumber"
      }, // { 
      //   "title": "Default Currency",
      //   "propertyName": "defaultCurrency"
      // },
      // {
      //   "title": "Foreign Currency",
      //   "propertyName": "foreignCurrency"
      // },
      {
        "title": "Submission Date",
        "className": "adkTable11Width",
        'propertyName': "submissionDate",
        "component": "invoiceSubmissionDateFormat"
      }, {
        "title": "Notes",
        "propertyName": "notes"
      }, {
        "title": "Approval Comments",
        "propertyName": "statusLog"
      }, {
        "title": "Invoice Doc",
        "component": "invoiceInfoComponent"
      }, {
        "title": "Due Date",
        "className": "adkTable11Width",
        "propertyName": "dueDate",
        "component": "invoiceDueDateFormat"
      }, {
        "title": "Company",
        "propertyName": "companyName"
      }]; //merge the previous column table and replace the specific column at column [7];

      this.invoiceTable3Column = this.invoiceTable2Column.concat();
      this.invoiceTable3Column[7] = {
        "title": "Rejected Comments",
        "propertyName": "statusLog"
      };
      this.resetForm();
    },

    // For components to create own variables
    didReceiveAttrs() {// this._super(...arguments);
      // this.loadCampaignInvoiceTask.perform(this.get('adCampaign').id);
    },

    saveInvoice: (0, _emberConcurrency.task)(function* () {
      let todayDate = this.get('adkSessionAccount').get('myToday');
      let adkToast = this.get('adkToast');
      yield this.saveNewInvoice({
        invoiceNumber: this.get('invoiceNumber'),
        description: this.get('description'),
        invoiceAmount: this.get('invoiceAmount'),
        invoiceDoc: this.get('invoices'),
        notes: this.get('notes'),
        defaultCurrency: this.get('adCampaign.currencyType'),
        foreignCurrency: this.get('adCampaign.foreignCurrency'),
        submissionDate: new Date(this.set('submissionDate', todayDate)),
        // submissionDate: new Date(), //should be follow company current date
        dueDate: this.get('dueDate').toDate(),
        createdAt: new Date()
      }, this.get('adCampaign'));
      this.actions.closeDialog.apply(this);
      adkToast.display("New Invoice added successfully", "warning", 3000);
      this.loadCampaignInvoiceTask.perform(this.get('adCampaign').id);
      this.resetForm();
    }),
    // defaultCurrency: computed('adCampaign.currency', function() {})
    // newCampaignInvoices: computed('adCampaignInvoice.@each.status', function() {
    //   let adCampaignInvoice = this.get('adCampaignInvoice');
    //   if(!adCampaignInvoice) {
    //     return A();
    //   }
    //   return adCampaignInvoice.filterBy('status', 'new');
    // }),
    submittedCampaignInvoices: Ember.computed('adCampaignInvoice.@each.status', function () {
      let adCampaignInvoice = this.get('adCampaignInvoice');

      if (!adCampaignInvoice) {
        return Ember.A();
      }

      return adCampaignInvoice.filterBy('status', 'submitted');
    }),
    approvedCampaignInvoices: Ember.computed('adCampaignInvoice.@each.status', function () {
      let adCampaignInvoice = this.get('adCampaignInvoice');

      if (!adCampaignInvoice) {
        return Ember.A();
      }

      return adCampaignInvoice.filterBy('status', 'approved');
    }),
    rejectedCampaignInvoices: Ember.computed('adCampaignInvoice.@each.status', function () {
      let adCampaignInvoice = this.get('adCampaignInvoice');

      if (!adCampaignInvoice) {
        return Ember.A();
      }

      return adCampaignInvoice.filterBy('status', 'rejected');
    }),
    actions: {
      uploadInvoiceDoc: function () {
        //do nothing
        return;
      },

      updateDate(theField, selected) {
        let adkToast = this.get('adkToast');
        let newDate = selected.moment;
        let dueDate = this.get('dueDate');

        if (theField != 'dueDate') {
          if (dueDate != '') {
            newDate = '';
          }
        }

        if (newDate != '') {
          this.set(theField, newDate); // let theActualField = theField.substring(0, theField.length - 5);

          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          this.set(theField, (0, _moment.default)(new Date(newDateStr))); // this.set(theActualField, moment(new Date(newDateStr)));

          adkToast.display("Date successfully set", "warning", 2000);
        }
      },

      checkCenter(theField, centerField, centerDate) {
        let newCenterDate = centerDate.moment;
        let dueDate = this.get('dueDate');

        if (theField != 'dueDate') {
          if (dueDate != '') {
            if (_adkDateTime.default.compareYearMonth(newCenterDate, dueDate) <= 0) {
              newCenterDate = dueDate;
            }
          }
        }

        this.set(centerField, newCenterDate);
      },

      toggleEdit() {
        this.toggleProperty('isEditing');
      },

      newCampaignInvoice() {
        this.set('newCampaignInvoiceFlag', true);
      },

      closeDialog: function () {
        this.set('newCampaignInvoiceFlag', false);
        this.resetForm();
      }
    }
  });

  _exports.default = _default;
});