define("adtikir3v1/helpers/pattern-decimal", ["exports", "@abcum/ember-helpers/helpers/pattern-decimal"], function (_exports, _patternDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _patternDecimal.default;
    }
  });
  Object.defineProperty(_exports, "patternDecimal", {
    enumerable: true,
    get: function () {
      return _patternDecimal.patternDecimal;
    }
  });
});