define("adtikir3v1/pods/components/adk-vendor-media-selection/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //this component should allow mediaTy[pes[],
  //searchType  base on searchType .. server will get the
  //  mediaTypes, targeting info  from backend
  //searchId, the id for searchType
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    showVendorDetail: false,
    loadMediaVendorTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let result = yield this.store.query('list-media-vendor', {
        searchType: this.searchType,
        searchId: this.searchId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page); // if (!result  ||  result.length  === 0) {
      //   set(pagingObj, 'initFg', false);
      //   //set(pagingObj, 'firstFg', false);
      // }

      return true;
    }),

    // willDestroyElement () {
    //   this._super(...arguments);
    //   this.element.removeEventListener('resize', this.get('myListenerWithContext'));
    // },
    // didRender() {
    //   this._super(...arguments);
    //   this.set('myListenerWithContext', this.myListener.bind(this));
    //   this.element.addEventListener('resize', this.get('myListenerWithContext'));
    // },
    // myListener: function(event) {
    //   console.log("reize"); 
    //   console.log(event);
    // },
    didReceiveAttrs() {
      this._super(...arguments);

      this.set('listMediaVendorType', {
        page: 0,
        pageSize: 20,
        action: this.get("loadMediaVendorTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
    },

    didInsertElement: function () {
      this._super(...arguments); // this._resizeHandler = function() {
      //   let vendorDetail = document.getElementById("vendorDetail");
      //   let el = this.element;
      //   if (vendorDetail.style.display === "block") {
      //       el.appendChild(vendorDetail);
      //       vendorDetail.style.display = 'none';
      //       this.setActiveVendorDetail(this.get('activeMDCard'));
      //   }
      // }.bind(this);
      // window.addEventListener('resize', this._resizeHandler);
      // this._resizeHandler(); 


      this.element.scrollIntoView();
    },
    //didReceiveAttrs
    didUpdateAttrs: function () {
      this._super(...arguments);

      let vendorDetail = document.getElementById("vendorDetail");
      let el = this.element;

      if (vendorDetail.style.display === "block") {
        el.appendChild(vendorDetail);
        vendorDetail.style.display = 'none';
      }

      this.clearShowVendorDetail();
    },

    // willDestroyElement: function() {
    //   window.removeEventListener('resize', this._resizeHandler);
    // },
    setActiveVendorDetail(mdCard) {
      mdCard.classList.add('adkListCardActive');
      this.set('activeMdClass', mdCard);
      let nextMdCard = mdCard;
      let vendorDetail = document.getElementById("vendorDetail");
      nextMdCard.parentElement.insertBefore(vendorDetail, nextMdCard.nextSibling);
      this.set('showVendorDetail', true);
      vendorDetail.style.display = "block";
      vendorDetail.classList.add('flex-100');
      Ember.run.once(this, function () {
        mdCard.scrollIntoView();
      });
    },

    //to clean clean out display
    clearShowVendorDetail() {
      this.set('vendor', null);
      this.set('preferredFlag', false);
      this.set('activeMDCard', null);
      this.set('showVendorDetail', false);

      if (this.activeMdClass) {
        this.activeMdClass.classList.remove("adkListCardActive");
      }

      this.set('activeMdClass', null);
    },

    addToPreferred: (0, _emberConcurrency.task)(function* (listMediaVendor) {
      let adkToast = this.get('adkToast');
      let preferVendor = this.store.createRecord("my-prefer-vendor", {
        vendor: listMediaVendor.vendor
      });

      try {
        yield preferVendor.save();
        listMediaVendor.set('preferredFlag', true);
        adkToast.display(listMediaVendor.vendor.get('company').get('companyName') + " added as preferred vendor!", "warning", 3000);
      } catch (e) {
        adkToast.display("Fail to add " + listMediaVendor.vendor.get('company').get('companyName') + " as preferred vendor", "error", 3000);
        console.error(e);
        return false;
      }
    }),
    actions: {
      showDetailAction(vendor, preferredFlag, event) {
        let currentMdCard = event.currentTarget.parentElement.parentElement;
        let closeMode = this.activeMdClass === currentMdCard ? true : false;
        this.clearShowVendorDetail();

        if (closeMode) {
          return;
        } // v       this.didUpdateAttrs();


        Ember.run.later(this, function () {
          this.setActiveVendorDetail(currentMdCard); //let newDiv = document.createElement('div');
          //newDiv.id="vendorDetail";

          this.set('vendor', vendor);
          this.set('preferredFlag', preferredFlag);
          this.set('activeMDCard', currentMdCard);
          this.set('showVendorDetail', true);
        }, 300);
      },

      hideDetailAction() {
        this.set('showVendorDetail', false);
        this.didUpdateAttrs();
      },

      addAction(listMediaVendor) {
        //put the promise resolve here because this is specific to this component...
        //should not put in the add button compoent    
        let thisCompoenent = this;
        listMediaVendor.get('vendor').then(function (vendor) {
          vendor.get('company').then(function (company) {
            thisCompoenent.get('addList')(listMediaVendor.preferredFlag, company);
          });
        });
      }

    } //actions

  });

  _exports.default = _default;
});