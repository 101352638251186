define("adtikir3v1/pods/components/adkpanel-adpost-tobedeleted/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import config from '../../../config/environment';
  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    searchTerm: '',
    page: 0,
    pageSize: 2,
    activeAdPostComment: null,
    totalPage: Ember.computed('total', 'pageSize', function () {
      let total = 0;

      if (this.total) {
        total = this.total;
      }

      let pageSize = 1;

      if (this.pageSize > 0) {
        pageSize = this.pageSize;
      }

      total = Math.ceil(total / pageSize);
      return total;
    }),
    loadAdPostCmtFunc: (0, _emberConcurrency.task)(function* () {
      let adPost = this.get('adPost');
      let result = yield this.get('loadAdPostComments')(adPost.id, this.get('page'), this.get('pageSize')); // console.log('result: ', result)

      this.set('adPostsComment', result.adPostsComment); // console.log("adPostsComment: ",result.adPostsComment)

      this.set('total', result.total); // console.log("totallll: ", this.set('total', result.total))
    }),
    newDiscussionPost: (0, _emberConcurrency.task)(function* () {// yield this.saveNewDiscussion({
      //   media: this.get('newMedia'),
      //   comment: this.get('newComment'),
      // }, this.get('adPost'));
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this.loadAdPostCmtFunc.perform();
    },

    adpostCommentCleanUp: function () {
      this.set('commenter', '');
      this.set('comment', '');
    },
    actions: {
      openCommentDialog(event) {
        //this.set('dialogOrigin', $(event.currentTarget));
        this.set('dialogOrigin', event.currentTarget);
        this.set('showNewComment', true); // this.set('postid', this.get("selectedCardData.id"));
      },

      closeCommentDialog() {
        this.adpostCommentCleanUp();
        this.set('showNewComment', false);
      },

      openNewDiscussion(event) {
        this.set('dialogOrigin', Ember.$(event.currentTarget));
        this.set('showNewDiscussion', true);
      },

      closeDiscussionDialog() {
        this.set('showNewDiscussion', false);
      },

      uploadAdPostDiscussionPic() {
        return;
      },

      setPage(number) {
        if (number >= this.totalPage) {
          number = this.totalPage - 1;
        } else if (number < 0) {
          number = 0;
        }

        this.set('page', number);
        this.loadAdPostCmtFunc.perform();
      },

      nextPage() {
        this.actions.setPage.call(this, this.get('page') + 1);
      },

      prevPage() {
        this.actions.setPage.call(this, this.get('page') - 1);
      },

      addComment(adPost) {
        let adkToast = this.get('adkToast');
        let comment = this.get('store').createRecord('ad-post-comment', {
          comment: this.get('comment'),
          adPost: adPost,
          createdAt: new Date()
        }); // console.log('comment: ', comment);

        comment.save().then(() => {
          this.actions.closeCommentDialog.apply(this);
          this.loadAdPostCmtFunc.perform();
        }), adkToast.display("New Comment added successfully", "warning", 3000);
      }

    }
  });

  _exports.default = _default;
});