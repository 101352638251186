define("adtikir3v1/pods/profile/company/additional/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loadLocationStateTask: (0, _emberConcurrency.task)(function* () {
      let myCompany = this.get('model.myCompany');
      let states = yield this.store.query('location-state', {
        country: myCompany.country
      });
      this.set('stateOptions', states);
    }),
    // load city list.. 
    // this will check the city's state is in locationList or not.. if so, dont load it
    loadLocationCityTask: (0, _emberConcurrency.task)(function* () {
      let myCompanyDetail = this.get('model.myCompanyDetail');
      let cities = yield this.store.query('location-city', {
        state: myCompanyDetail.stateId
      });
      this.set('cityOptions', cities);
    }),
    actions: {
      actionToEditMode() {
        this.set('editMode', true);
      },

      actionCancelEditMode() {
        this.set('editMode', false);
      },

      selectState(state) {
        let myCompanyDetail = this.get('model.myCompanyDetail');
        this.set('cityOptions', []);
        myCompanyDetail.set('city', '');
        myCompanyDetail.set('cityId', '');
        myCompanyDetail.set('stateId', state.id);
        myCompanyDetail.set('state', state.name);
        this.set('myState', state);
        this.set('myCity', null);
        this.loadLocationCityTask.perform();
      },

      selectCity(city) {
        let myCompanyDetail = this.get('model.myCompanyDetail');
        this.set('myCity', city);
        myCompanyDetail.set('cityId', city.id);
        myCompanyDetail.set('city', city.name);
      },

      actionUpdateAdditional() {
        let controller = this;
        this.model.myCompanyDetail.save().then(function () {
          controller.set('editMode', false);
        }).catch(function (e) {
          console.error(e);
        });
      },

      actionAdditionalCancel() {
        if (this.model.myCompanyDetail.get('hasDirtyAttributes')) {
          this.model.myCompanyDetail.rollbackAttributes();
        }

        this.set('editMode', false);
      }

    } // changedAttributesHash: getComputedStyle('model.{name,url}', function() {
    //     let changedAttributes = this.get("model").changeAttributes();
    //     let usableHash = object.keys(cjangeAttributes).map(function(attributeName) {
    //       return {
    //         key: attributeName,
    //         earlierValue: changedAttributes[attributeName][0],
    //         currentValue: changedAttributes[attributeName][1],
    //       }
    //     });
    //     return usableHash; 
    // })

  });

  _exports.default = _default;
});