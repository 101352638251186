define("adtikir3v1/pods/components/adk-adinvoice-approve/component", ["exports", "sanitize-html", "adtikir3v1/utils/adk-date-time", "moment"], function (_exports, _sanitizeHtml, _adkDateTime, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    embedded: Ember.computed("record.invoiceDoc", function () {
      if (this.get('record.invoiceDoc')) {
        return false;
      }

      return true;
    }),
    workFlowOptions: Ember.computed('acWorkFlows.[]', function () {
      let array = [];
      let acWorkFlows = this.get('acWorkFlows');
      acWorkFlows.forEach(function (item, index) {
        if (item.isSelected && item.id != acWorkFlows.length - 1) {
          array.pushObject({
            index: index,
            workflowStatus: item.workFlowStatus
          });
        }
      });
      return array;
    }),
    init: function () {
      this._super(...arguments);

      this.numberValidation = [{
        message: 'Please provide a valid value (99999999.99)',
        validate: inputValue => {
          let valuePattern = /^\d+(\.\d{1,2})?$/;
          return valuePattern.test(inputValue);
        }
      }];
      let component = this;
      let adkSessionAccount = this.get('adkSessionAccount');
      let company = adkSessionAccount.get('myCompany');
      company.get('myCompanyDetail').then(function (myCompanyDetail) {
        myCompanyDetail.get('financialAccounts').then(function (financialAccounts) {
          component.set('financialAccounts', financialAccounts);
        });
      });
      this.set('component', component);
      let currentCompany = company.get('companyName', this.set('companyName'));
      this.set('currentCompany', currentCompany);
    },

    didReceiveAttrs() {
      if (this.get('editFlag')) {
        let invoiceAd = this.get('record');
        let workFlowOptions = this.get('workFlowOptions'); // console.log("invoiceAd", invoiceAd);
        // console.log("ionvoiceAd.ad", invoiceAd.get("ad"));

        for (let i = 0; i < workFlowOptions.length; i++) {
          if (workFlowOptions[i].workflowStatus == invoiceAd.workFlowStep) {
            this.set('initialWF', workFlowOptions[i]);
            break;
          }
        }

        this.set("dueDateCenter", (0, _moment.default)(this.get('record.dueDate')));
        this.set("dueDate", (0, _moment.default)(this.get('record.dueDate')));
        this.set("dueDateOldDate", (0, _moment.default)(this.get('record.dueDate')));
      }
    },

    actions: {
      closeDialog: function () {
        this.set('submitFlag', false);
        this.set('editFlag', false);
        this.set('approvedFlag', false);
        this.set('rejectFlag', false); // this.set('dialogTitle', '');

        this.set('notes', '');
        this.set('faccount', '');
        this.set('buttonPress', '');
      },
      cancelEdit: function () {
        let invoice = this.get('record');

        if (invoice.get('hasDirtyAttributes')) {
          invoice.rollbackAttributes();
        }

        this.actions.closeDialog.apply(this);
      },

      updateDate(theField) {
        let adkToast = this.get('adkToast');
        let newDate = arguments[1].moment;
        let dueDate = this.get('dueDate');

        if (theField != 'dueDate') {
          if (dueDate != '') {
            newDate = '';
          }
        }

        if (newDate != '') {
          this.set(theField, newDate);
          let theActualField = theField.substring(0, theField.length - 5);
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          this.set(theActualField, (0, _moment.default)(new Date(newDateStr)));
          adkToast.display("Date successfully set", "warning", 2000);
        }
      },

      checkCenter(theField, centerField) {
        // let newCenterDate = centerDate.moment;
        // let dueDate       = this.get('dueDate');
        let newCenterDate = arguments[1].moment;
        let dueDate = this.get('dueDate');

        if (theField != 'dueDate') {
          if (dueDate != '') {
            if (_adkDateTime.default.compareYearMonth(newCenterDate, dueDate) <= 0) {
              newCenterDate = dueDate;
            }
          }
        }

        this.set(centerField, newCenterDate);
      },

      uploadInvoiceDoc: function () {
        //do nothing
        return;
      },
      updateFlag: function (buttonPress, event) {
        this.set('buttonPress', buttonPress);

        if (buttonPress == "submit") {
          this.set('dialogTitle', 'Summary of Invoice');
        }

        this.set('submitFlag', true);
        this.set('origin', event.target);
      },
      updateApproveFlag: function (buttonPress, event) {
        this.set('buttonPress', buttonPress);

        if (buttonPress == "approved") {
          this.set('dialogTitle', 'Summary of Invoice');
        }

        this.set('approvedFlag', true);
        this.set('origin', event.target);
      },
      rejectFlag: function (buttonPress, event) {
        this.set('buttonPress', buttonPress);

        if (buttonPress == "reject") {
          this.set('dialogTitle', 'Reject Invoice');
        }

        this.set('rejectFlag', true);
        this.set('origin', event.target);
      },
      approvedInvoice: function () {
        let adkToast = this.get('adkToast');
        let invoice = this.get('record');
        let sanitizeComment = (0, _sanitizeHtml.default)(this.get('comment'));
        invoice.set('accountNumber', this.get('faccount.costCode'));
        invoice.set('comment', sanitizeComment);
        invoice.set('status', 'approved');
        invoice.set('reactedByDate', new Date());
        invoice.save().then(function (response) {
          response.ad.reload();
          adkToast.display("Invoice Approved", "warning", 3000);
          return true;
        }, function () {
          if (invoice.get('hasDirtyAttributes')) {
            invoice.rollbackAttributes();
          }

          adkToast.display("Invoice Failed to Approve", "error", 3000);
          return true;
        });
        this.actions.closeDialog.apply(this);
      },
      rejectInvoice: function () {
        let adkToast = this.get('adkToast');
        let invoice = this.get('record');
        let sanitizeComment = (0, _sanitizeHtml.default)(this.get('comment'));
        invoice.set('status', 'rejected');
        invoice.set('comment', sanitizeComment);
        invoice.set('reactedByDate', new Date());
        invoice.save().then(function () {
          adkToast.display("Invoice Rejected", "warning", 3000);
          return true;
        }, function () {
          if (invoice.get('hasDirtyAttributes')) {
            invoice.rollbackAttributes();
          }

          adkToast.display("Invoice Failed to Reject", "error", 3000);
          return true;
        });
        this.actions.closeDialog.apply(this);
      },
      updateEditFlag: function (event) {
        this.set('editFlag', true);
        this.set('origin', event.target);
      },
      submitInvoice: function () {
        let adkToast = this.get('adkToast');
        let invoice = this.get('record');
        invoice.set('status', 'submitted');
        invoice.save().then(function () {
          adkToast.display("Send Successfully", "warning", 3000);
          return true;
        }, function () {
          if (invoice.get('hasDirtyAttributes')) {
            invoice.rollbackAttributes();
          }

          adkToast.display("Send Failed", "error", 3000);
          return true;
        });
        this.actions.closeDialog.apply(this);
      },
      editInvoice: function () {
        let adkToast = this.get('adkToast');
        let invoice = this.get('record');
        invoice.set('dueDate', new Date(this.get('dueDate'))); // invoice.set('dueDate', this.get('dueDate'));

        invoice.set('workFlowStep', this.get('initialWF').workflowStatus); // workFlowStep: this.get('initialWF.workflowStatus')

        invoice.save().then(function () {
          adkToast.display("Invoice Successfully Edited", "warning", 3000);
          return true;
        }, function () {
          if (invoice.get('hasDirtyAttributes')) {
            invoice.rollbackAttributes();
          }

          adkToast.display("Edit Failed", "error", 3000);
          return true;
        });
        this.actions.closeDialog.apply(this);
      },

      calCostTotal(field, value) {
        let controller = this;
        this.set(field, value);

        function calTotal() {
          let a = parseFloat(controller.get("record.mediaCost"));
          let b = parseFloat(controller.get("record.agencyCost"));
          let c = parseFloat(controller.get("record.miscCost"));

          if (isNaN(a)) {
            a = 0;
          }

          if (isNaN(b)) {
            b = 0;
          }

          if (isNaN(c)) {
            c = 0;
          }

          controller.set("record.invoiceAmount", a + b + c);
        }

        Ember.run.scheduleOnce('actions', controller, calTotal);
      }

    }
  });

  _exports.default = _default;
});