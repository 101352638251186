define("adtikir3v1/models/ad-vendor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    adVendorUser: belongsTo('ad-vendor-user', {
      async: true
    }),
    companyName: attr('string'),
    status: attr('string'),
    updatedAt: attr('date'),
    createdAt: attr('date')
  });

  _exports.default = _default;
});