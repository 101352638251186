define("adtikir3v1/helpers/drag-get", ["exports", "@abcum/ember-helpers/helpers/drag-get"], function (_exports, _dragGet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dragGet.default;
    }
  });
  Object.defineProperty(_exports, "dragGet", {
    enumerable: true,
    get: function () {
      return _dragGet.dragGet;
    }
  });
});