define("adtikir3v1/pods/help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sEHo8dEc",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\nCopyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.\\nThis software is protected by Copyright laws and use is subject to license agreement available at adtiki.com\\n\"],[0,\"\\n\\n\\n\\n\"],[0,\"\\n\"],[4,\"swiper-container\",null,[[\"parallax\",\"slidesPerView\",\"centeredSlides\",\"pagination\"],[true,2,true,true]],{\"statements\":[[0,\"  \"],[4,\"swiper-slide\",null,null,{\"statements\":[[7,\"img\",true],[10,\"src\",\"/assets/help/helpcard1.png\"],[10,\"alt\",\"helpcard1.png\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"swiper-slide\",null,null,{\"statements\":[[7,\"img\",true],[10,\"src\",\"/assets/help/helpcard2.png\"],[10,\"alt\",\"helpcard2.png\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"swiper-slide\",null,null,{\"statements\":[[7,\"img\",true],[10,\"src\",\"/assets/help/helpcard3.png\"],[10,\"alt\",\"helpcard3.png\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"swiper-slide\",null,null,{\"statements\":[[7,\"img\",true],[10,\"src\",\"/assets/help/helpcard4.png\"],[10,\"alt\",\"helpcard4.png\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"swiper-slide\",null,null,{\"statements\":[[7,\"img\",true],[10,\"src\",\"/assets/help/helpcard5.png\"],[10,\"alt\",\"helpcard5.png\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"swiper-slide\",null,null,{\"statements\":[[7,\"h2\",true],[10,\"style\",\"text-align: center; padding: 150px 0;\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"https://adtiki.ladesk.com/index.php?upid=kb_defa||f16970ad\"],[8],[0,\"\\n        Visit our link to get some technical support\"],[9],[9],[0,\"\\n  or send an email to - support@adtiki.com \\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/help/template.hbs"
    }
  });

  _exports.default = _default;
});