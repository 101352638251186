define("adtikir3v1/helpers/rsvp-all", ["exports", "@abcum/ember-helpers/helpers/rsvp-all"], function (_exports, _rsvpAll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rsvpAll.default;
    }
  });
  Object.defineProperty(_exports, "rsvpAll", {
    enumerable: true,
    get: function () {
      return _rsvpAll.rsvpAll;
    }
  });
});