define("adtikir3v1/pods/agency/admanager/index/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { inject as service} from '@ember/service';
  // import { task } from 'ember-concurrency';
  class AdManagerIndexRoute extends Ember.Route.extend(_keycloakAuthenticatedRoute.default) {
    // @service store;
    // @service adkSessionAccount;
    // @task(function* (){
    //   try {
    //     let companyList = yield this.store.findAll('list-company-ad-agency');
    //     return {
    //         companyList: companyList,
    //     }
    //   } catch (e) {
    //     this.intermediateTransitionTo('error', e);
    //     throw (e);
    //   }
    //   }).restartable() loadModelTask;
    // model() {
    //   try {
    //     return this.loadModelTask.perform();
    //   } catch (e) {
    //     throw (e);
    //   }
    // }
    setupController(controller) {
      // this._super(controller, model);
      super.setupController(...arguments);
      controller.get('loadCompanyListTasks').perform();
    } //setupController    


  }

  _exports.default = AdManagerIndexRoute;
});