define("adtikir3v1/models/my-vendor", ["exports", "ember-data", "adtikir3v1/models/industry"], function (_exports, _emberData, _industry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { alias } from '@ember/object/computed';
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    rating: attr('string'),
    aboutUs: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    mediaTypes: hasMany('media-type'),
    myCompany: belongsTo('my-company'),
    myVendorAgencyIndustryTypes: hasMany('industry-type'),
    myVendorAgencyLanguages: hasMany('language')
  });

  _exports.default = _default;
});