define("adtikir3v1/pods/components/adk-campaign-topay/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { task } from 'ember-concurrency';
  // import { inject as service } from '@ember/service';
  //import adkDateTime  from '../../../utils/adk-date-time';
  //import moment from 'moment'
  //import { computed } from '@ember/object'; 
  var _default = Ember.Component.extend({
    // @service store,
    // For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);

      this.setProperties({// invoicePay:      this.modelName.get('invoicePay'),
        // localVariable2:      this.modelName.get('localVariable2'),
      });
    },

    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      },

      toPay() {
        this.toggleProperty('isEditing');
        this.cntlToPay({
          invoicePay: this.get('invoicePay'),
          localVariable2: this.get('localVariable2')
        }); //End of localComponentSaveName
      }

    }
  });

  _exports.default = _default;
});