define("adtikir3v1/helpers/uniq", ["exports", "@abcum/ember-helpers/helpers/uniq"], function (_exports, _uniq) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uniq.default;
    }
  });
  Object.defineProperty(_exports, "uniq", {
    enumerable: true,
    get: function () {
      return _uniq.uniq;
    }
  });
});