define("adtikir3v1/pods/campaign/details/ads/ad/vendors/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),

    beforeModel() {
      this.get('router').transitionTo("campaign.details.ads.ad.vendors.adclientbrief");
    }

  });

  _exports.default = _default;
});