define("adtikir3v1/pods/components/adkcard-addashboard-campaigntargeting/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: false,
    adkToast: Ember.inject.service(),
    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      }

    }
  });

  _exports.default = _default;
});