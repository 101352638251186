define("adtikir3v1/components/timeline-view", ["exports", "@abcum/ember-charts/components/timeline-view"], function (_exports, _timelineView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _timelineView.default;
    }
  });
});