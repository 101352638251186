define("adtikir3v1/pods/components/adk-vendor-clientbrief/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    refresh: false,
    didReceiveAttrs: function () {
      this._super(...arguments);

      this.set('ageGroup', ['1-16', '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+']);
    },
    saveRfpSettingsTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');
      let adCampaignRfpSetting = this.get('adCampaignRfpSetting');
      yield adCampaignRfpSetting.save();
      this.set('refresh', true);
      adkToast.display("Customer Brief Published", "warning", 2000);
    }),
    //saveRfpSettingTask; 
    actions: {
      doNotShow: function () {
        this.toggleProperty('isShown');
      }
    } //action

  });

  _exports.default = _default;
});