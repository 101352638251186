define("adtikir3v1/pods/media/admanager/index/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdManagerIndexRoute extends Ember.Route.extend(_keycloakAuthenticatedRoute.default) {
    setupController(controller) {
      super.setupController(...arguments);
      controller.get('loadMediaCompanyListTasks').perform();
    }

  }

  _exports.default = AdManagerIndexRoute;
  ;
});