define("adtikir3v1/pods/components/adk-budget-workflow/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //store is only required if component needs to access the store
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),

    // For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);

      this.setProperties({
        //  estBudget:      this.ad.get('estBudget'),
        //  estAgencyBudget:      this.ad.get('estAgencyBudget'),
        ads: this.get('ads'),
        budgetWorkFlow: this.get('budgetWorkFlow')
      });
    },

    adGroupByBudgetStep: Ember.computed('ads.@each.adBudgetWorkflowStep', function () {
      let ads = this.get('ads');
      let groups = Ember.Object.create();

      if (ads) {
        ads.forEach(function (ad) {
          let adBudgetWorkflowStep = ad.get('adBudgetWorkflowStep');

          if (adBudgetWorkflowStep >= 0) {
            if (!Ember.isPresent(groups.get(adBudgetWorkflowStep))) {
              groups.set(adBudgetWorkflowStep, Ember.A());
            }

            groups.get(adBudgetWorkflowStep).push(ad);
          }
        }); //forEach
      } //if ads


      return groups;
    }),
    // adGroupByBudgetStep2: computed('ads.@each.adBudgetWorkflowStep', function(){
    //   let ads = this.get('ads');
    //   const adsBudgetStep = ads.filter((ads) => {
    //     return ads.adBudgetWorkflowStep = adBudgetWorkflowStep
    //   })
    //   return adsBudgetStep;
    // }),
    actions: {
      componentSaveBudget() {
        this.adSaveBudget({// estBudget : this.get('estBudget'),
          // estAgencyBudget:  this.get('estAgencyBudget'),
        }); //End of localComponentSaveName
      },

      //end of saveAd
      adSaveBudget() {}

    } //end of actions

  });

  _exports.default = _default;
});