define("adtikir3v1/pods/campaign/details/ads/ad/vendors/agency/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    selectedBasicTab: 0
  });

  _exports.default = _default;
});