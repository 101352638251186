define("adtikir3v1/components/line-chart", ["exports", "ember-google-charts/components/line-chart"], function (_exports, _lineChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _lineChart.default;
    }
  });
});