define("adtikir3v1/helpers/last", ["exports", "@abcum/ember-helpers/helpers/last"], function (_exports, _last) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _last.default;
    }
  });
  Object.defineProperty(_exports, "last", {
    enumerable: true,
    get: function () {
      return _last.last;
    }
  });
});