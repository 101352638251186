define("adtikir3v1/pods/components/adk-campaign-activities/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super();

      this.setProperties({
        showComponentFooter: true,
        editMode: false // showPageSize:false,

      });
    }

  });

  _exports.default = _default;
});