define("adtikir3v1/services/adk-cdn-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    getCdnUrl(id) {
      return this.get('ajax').request('/cdnurl/' + id, {
        method: 'GET'
      });
    }

  });

  _exports.default = _default;
});