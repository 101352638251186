define("adtikir3v1/pods/components/adk-ad-timeline/component", ["exports", "adtikir3v1/utils/adk-date-time", "ember-concurrency"], function (_exports, _adkDateTime, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      try {
        this.loadModelTask.perform();
      } catch (e) {
        throw e;
      }
    },

    loadModelTask: (0, _emberConcurrency.task)(function* () {
      try {
        let obj = {};
        let ad = yield this.get('ad');
        let adCampaign = yield ad.get('adCampaign');
        let adkSessionAccount = yield this.get('adkSessionAccount');
        let acWorkFlows = yield this.get('acWorkFlows');
        obj = [{
          color: "adkTimelineCampaign",
          date: adkSessionAccount.getDate(adCampaign.get('campaignStartDate')),
          dateIntl: adkSessionAccount.getDate(adCampaign.get('campaignStartDate'), true),
          description: "Campaign Start Date"
        }, {
          color: "adkTimelineCampaign",
          date: adkSessionAccount.getDate(adCampaign.get('campaignEndDate')),
          dateIntl: adkSessionAccount.getDate(adCampaign.get('campaignEndDate'), true),
          description: "Campaign End Date"
        }];

        if (_adkDateTime.default.validDate(adCampaign.get('campaignProductLaunchDate'))) {
          let productLaunchDate = adkSessionAccount.getDate(adCampaign.get('campaignProductLaunchDate'));
          let productLaunchDateIntl = adkSessionAccount.getDate(adCampaign.get('campaignProductLaunchDate'), true);
          obj.pushObject({
            color: "adkTimelineCampaign",
            date: productLaunchDate,
            dateIntl: productLaunchDateIntl,
            description: "Campaign Product Launch Date"
          });
        }

        if (_adkDateTime.default.validDate(ad.get('adPlayStartDate'))) {
          let adInPlayStartDate = adkSessionAccount.getDate(ad.get('adPlayStartDate'));
          let adInPlayStartDateIntl = adkSessionAccount.getDate(ad.get('adPlayStartDate'), true);
          obj.pushObject({
            color: "adkTimelineAd",
            date: adInPlayStartDate,
            dateIntl: adInPlayStartDateIntl,
            description: "Ad Inplay Start Date"
          });
        }

        if (_adkDateTime.default.validDate(ad.get('adPlayEndDate'))) {
          let adInPlayEndDate = adkSessionAccount.getDate(ad.get('adPlayEndDate'));
          let adInPlayEndDateIntl = adkSessionAccount.getDate(ad.get('adPlayEndDate'), true);
          obj.pushObject({
            color: "adkTimelineAd",
            date: adInPlayEndDate,
            dateIntl: adInPlayEndDateIntl,
            description: "Ad Inplay End Date"
          });
        }

        let adDeadLines = yield ad.get('adDeadlines');

        if (adDeadLines && adDeadLines.length > 0) {
          adDeadLines.forEach(function (deadline) {
            let date = adkSessionAccount.getDate(deadline.deadlineDate);
            let dateIntl = adkSessionAccount.getDate(deadline.deadlineDate, true);
            let desc = deadline.deadlineDescription;
            let workFlowStatus = acWorkFlows.objectAt(deadline.workFlowStatusNumber).workFlowStatus;
            desc += " (" + workFlowStatus + ")";
            obj.pushObject({
              color: "adkTimelineDeadline",
              date: date,
              dateIntl: dateIntl,
              description: desc
            });
          }); //forEach
        } //if


        let dateSorted = obj.sortBy('dateIntl');
        this.set('dateSorted', dateSorted);
      } catch (e) {
        throw e;
      }
    }).restartable()
  });

  _exports.default = _default;
});