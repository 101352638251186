define("adtikir3v1/models/ad-approval", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    workFlowStatusNumber: attr('number'),
    approved: attr('boolean', {
      defaultValue: false
    }),
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    approvedComment: attr('string', {
      defaultValue: ''
    }),
    approvedAt: attr('date'),
    approvedFirstName: attr('string', {
      defaultValue: ''
    }),
    approvedLastName: attr('string', {
      defaultValue: ''
    }),
    approvedRole: attr('string', {
      defaultValue: ''
    }),
    approvedUserRoleId: attr('string', {
      defaultValue: ''
    }),
    ad: belongsTo('ad'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });

  _exports.default = _default;
});