define("adtikir3v1/pods/components/adk-ad-report/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('ad', this.get('ad'));
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let getDeadlines = this.get('getDeadlines');
      getDeadlines.perform();
    },

    getDeadlines: (0, _emberConcurrency.task)(function* () {
      let adDeadlines = this.store.query('ad-deadline', {
        ad: this.get('ad').id
      });
      this.set('adDeadlines', adDeadlines);
    })
  });

  _exports.default = _default;
});