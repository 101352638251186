define("adtikir3v1/pods/campaign/details/ads/ad/dashboard/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AdDashboardRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    console.time("ad dashboard models");
    let adModel = yield this.modelFor('campaign.details.ads.ad');
    let adCampaignDetailModel = yield this.modelFor('campaign.details');
    let targeting = yield adCampaignDetailModel.adCampaignDetail.get('targeting');
    let locations = yield this.store.query('location', {
      adCampaignTargeting: adModel.adCampaign.id
    });
    console.timeEnd("ad dashboard models");
    return {
      ad: adModel.ad,
      adCampaign: adModel.adCampaign,
      adCampaignDetail: adModel.adCampaignDetail,
      acWorkFlows: adModel.acWorkFlows,
      targeting: targeting,
      locationList: locations.toArray()
    };
  }).restartable(), _dec4 = (0, _emberConcurrency.task)(function* () {
    try {
      let adModel = yield this.modelFor('campaign.details.ads.ad');
      let adVendor = yield this.store.query('adVendor', {
        AdId: adModel.ad.id
      });
      return adVendor;
    } catch (e) {
      this.intermediateTransitionTo('error', e);
      throw e;
    }
  }).restartable(), _dec5 = (0, _emberConcurrency.task)(function* () {
    try {
      let adModel = yield this.modelFor('campaign.details.ads.ad');
      let adMediaVendor = yield this.store.query('adMediaVendor', {
        AdId: adModel.ad.id
      });
      return adMediaVendor;
    } catch (e) {
      this.intermediateTransitionTo('error', e);
      throw e;
    }
  }).restartable(), (_class = class AdDashboardRoute extends Ember.Route.extend(_keycloakAuthenticatedRoute.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "adkSessionAccount", _descriptor2, this);

      _initializerDefineProperty(this, "loadModelTask", _descriptor3, this);

      _initializerDefineProperty(this, "loadAdVendorTask", _descriptor4, this);

      _initializerDefineProperty(this, "loadAdMediaVendorTask", _descriptor5, this);
    }

    model() {
      try {
        return this.loadModelTask.perform();
      } catch (e) {
        throw e;
      }
    }

    setupController(controller, model) {
      this._super(controller, model);

      console.time("ad dashboard route setupController");
      controller.set('ad', model.ad); // console.log(model.ad);

      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('acWorkFlows', model.acWorkFlows);
      controller.set('targeting', model.targeting);
      controller.set('locationList', model.locationList);
      controller.set('loadAdVendorTaskInstance', this.loadAdVendorTask.perform());
      controller.set('loadAdMediaVendorTaskInstance', this.loadAdMediaVendorTask.perform());
      console.timeEnd("ad dashboard route setupController"); // controller.set('adWflowStatus', model.adWflowStatus);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadModelTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loadAdVendorTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loadAdMediaVendorTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AdDashboardRoute;
});