define("adtikir3v1/pods/components/adk-companyinfo-workflow/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super();

      this.setProperties({
        showGlobalFilter: false,
        useFilteringByColumns: false,
        showComponentFooter: false,
        showColumnsDropdown: false,
        editMode: false
      });
      this.set('workflowColumns', [{
        propertyName: 'id',
        title: 'Step',
        editable: false
      }, {
        propertyName: 'isSelected',
        title: 'Selected',
        editable: false,
        component: 'edit-checkbox'
      }, {
        propertyName: 'workFlowStatus',
        title: 'Workflow Name',
        editable: false
      }, {
        propertyName: 'workFlowStatusLocalLanguage',
        title: 'Workflow Name in Local Language',
        editable: false
      }, {
        propertyName: 'workFlowColor',
        title: 'Color',
        editable: false,
        component: 'edit-color'
      }]);
      this.set('workflowColumnsEditMode', [{
        propertyName: 'isSelected',
        title: '',
        editable: false,
        component: 'edit-checkbox'
      }, {
        propertyName: 'id',
        title: 'Step',
        editable: false
      }, {
        propertyName: 'workFlowStatus',
        title: 'Workflow Name',
        editable: false,
        component: 'edit-workFlowStatus'
      }, {
        propertyName: 'workFlowStatusLocalLanguage',
        title: 'Workflow Name in Local Language',
        editable: false,
        component: 'edit-workFlowStatusLocalLanguage'
      }, {
        propertyName: 'workFlowColor',
        title: 'Color',
        editable: false,
        component: 'edit-color'
      } // {
      //   title: "Edit",
      //   component: "edit-row",
      //   editable: false
      // }
      ]);
    },

    //init
    workflowsSelected: Ember.computed('workflows.@each.isSelected', function () {
      let workflows = this.get('workflows');
      return workflows; //return workflows.filterBy('isSelected', true);
    }),
    rowChangedArray: Ember.computed('workflows.@each.hasDirtyAttributes', function () {
      let workflows = this.get('workflows');
      return workflows.filterBy('hasDirtyAttributes', true);
    }),
    updateButtonDisable: Ember.computed('rowChangedArray', function () {
      if (this.get('rowChangedArray').length > 0) {
        return false;
      }

      return true;
    }),
    resetFields: function () {
      let rowChangedArray = this.get('rowChangedArray');

      if (rowChangedArray.length > 0) {
        rowChangedArray.forEach(function (record) {
          record.rollbackAttributes();
        });
      }
    },
    saveAll: (0, _emberConcurrency.task)(function* () {
      let rowChangedArray = this.get('rowChangedArray');
      let trackers = [];
      let component = this;

      if (rowChangedArray.length > 0) {
        rowChangedArray.forEach(function (rowChange) {
          console.log(rowChange);
          trackers.push(component.saveChild.perform(rowChange));
        });
      }

      let result = yield Ember.RSVP.all(trackers);
      return result;
    }),
    saveChild: (0, _emberConcurrency.task)(function* (workflowRow) {
      try {
        yield workflowRow.save();
      } catch (e) {
        throw e;
      }

      return workflowRow;
    }),
    actions: {
      workFlowEditMode() {
        this.set('editMode', true);
      },

      updateWorkFlow() {
        this.saveAll.perform();
        this.set('editMode', false);
      },

      closeEditWorkflowDialog() {
        this.resetFields();
        this.set('editMode', false);
      }

    } //actions

  });

  _exports.default = _default;
});