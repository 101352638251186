define("adtikir3v1/pods/components/adk-media-type-multi-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X8/5ac6D",
    "block": "{\"symbols\":[\"type\",\"isSelected\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"checkbox-grid\"],[8],[0,\"\\n\"],[4,\"multiselect-checkboxes\",null,[[\"options\",\"selection\"],[[24,[\"mediaTypes\"]],[24,[\"selectedMediaTypes\"]]]],{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"paper-item\",null,[[\"class\"],[\"md-2-line\"]],{\"statements\":[[0,\"                \"],[4,\"paper-checkbox\",null,[[\"value\",\"onChange\"],[[23,2,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,2,[]]],null]],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"md-list-item-text\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"iconMediaTypeLocation\"]]],null,{\"statements\":[[0,\"                        \"],[7,\"figure\",true],[8],[0,\"\\n                            \"],[5,\"adk-display-image\",[],[[\"@fileid\",\"@width\",\"@height\"],[[23,1,[\"iconMediaTypeLocation\"]],104,104]]],[0,\"\\n                            \"],[7,\"figcaption\",true],[10,\"class\",\"adk-figCaption\"],[8],[1,[23,1,[\"mediaTypeName\"]],false],[9],[0,\"\\n                        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-media-type-multi-selection/template.hbs"
    }
  });

  _exports.default = _default;
});