define("adtikir3v1/pods/components/adk-image-portland/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'img',
    attributeBindings: ['src', 'alt'],

    didInsertElement() {
      this._super(...arguments);

      this.get('element').onload = () => {
        const img = this.get('element');
        const iw = img.naturalWidth;
        const ih = img.naturalHeight;

        if (iw > ih) {
          img.width = this.get('w');
        } else {
          img.height = this.get('h');
        }
      };
    }

  });

  _exports.default = _default;
});