define("adtikir3v1/models/invoice-campaign", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    invoiceNumber: attr('string'),
    description: attr('string'),
    invoiceAmount: attr('number'),
    notes: attr('string'),
    invoiceDoc: attr('string'),
    // activityDate: attr('date'),
    submissionDate: attr('date'),
    dueDate: attr('date'),
    approvedDate: attr('date'),
    rejectedDate: attr('date'),
    approvedBy: attr('string'),
    rejectedBy: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    defaultCurrency: attr('string'),
    foreignCurrency: attr('string'),
    adCampaign: belongsTo('adCampaign'),
    // ad: belongsTo('ad'),
    //companyName: attr('string'),
    // company: belongsTo('company', { inverse: null} ),
    // uploadBy: belongsTo('user', { inverse: null} ),
    status: attr('string', {
      defaultValue: 'new'
    }),
    //new approved rejected
    accountNumber: attr('string'),
    //financial account to bill
    invoiceAmountFormat: Ember.computed('invoiceAmount', function () {
      let invoiceAmount = this.get('invoiceAmount');
      return invoiceAmount.toFixed(2);
    }),
    uploadedBy: Ember.computed('firstName', 'lastName', function () {
      return `${this.firstName} ${this.lastName}`;
    }),
    approvalLog: Ember.computed('notes', 'approvedBy', function () {
      return `${this.notes} - Approved by: ${this.approvedBy}`;
    }),
    rejectedLog: Ember.computed('notes', 'rejectedBy', function () {
      return `${this.notes} - Rejected by: ${this.rejectedBy}`;
    })
  });

  _exports.default = _default;
});