define("adtikir3v1/helpers/is-updating", ["exports", "@abcum/ember-helpers/helpers/is-updating"], function (_exports, _isUpdating) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isUpdating.default;
    }
  });
  Object.defineProperty(_exports, "isUpdating", {
    enumerable: true,
    get: function () {
      return _isUpdating.isUpdating;
    }
  });
});