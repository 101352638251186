define("adtikir3v1/pods/components/adk-adcampaign-project/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    campaignName: '',
    campaignNameLocal: '',

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.vProposalMedia) {
        this.set('vProposalAgency', this.vProposalMedia);
        this.set('adCampaignMediaRfpBody', this.vProposalMedia.get('adCampaignMediaRfpBody'));
        this.set('adCampaignMediaRfp', this.adCampaignMediaRfpBody.get('adCampaignMediaRfp'));
        this.set("adCampaignRfp", this.get('adCampaignMediaRfp'));
      } else {
        this.set("adCampaignRfp", this.get('vProposalAgency').get('adCampaignRfp'));
      }
    },

    actions: {
      async save(event) {
        event.preventDefault(); // const person = this.store.createRecord('person', {
        //   campaignName: this.campaignName,
        //   campaignNameLocal: this.campaignNameLocal,
        // });
        // this.set('campaignName', '');
        // this.set('campaignNameLocal', '');
        // await person.save();
        // this.onSave();
      },

      nothing() {}

    }
  });

  _exports.default = _default;
});