define("adtikir3v1/pods/components/adk-ad-agency-proposal/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    isOpen: false,
    //Variables
    adName: '',
    advertiser: '',
    editMode: Ember.computed('vAdAgencyProposal.status', 'adAgencyProposalInvitedExipred', function () {
      let vAdAgencyProposal = this.get('vAdAgencyProposal');

      if (this.adAgencyProposalInvitedExipred) {
        return false;
      }

      if (vAdAgencyProposal.status === "new") {
        return true;
      }

      return false;
    }),

    didReceiveAttrs() {
      //this is hack for aProposalResponse
      if (this.get('aAdAgencyProposal')) {
        this.set('vAdAgencyProposal', this.get('aAdAgencyProposal'));
        this.set('aAdAgencyProposalType', true);
      } else {
        this.set('aAdAgencyProposalType', false);
      }

      let vAdAgencyProposal = this.get('vAdAgencyProposal');
      this.set('adRfp', vAdAgencyProposal.get('adRfp'));
      this.set('adAgencyRfpBody', vAdAgencyProposal.get('adAgencyRfpBody'));
      this.set('refresh', vAdAgencyProposal.id);
      this.set('openedTarget', null);

      if (vAdAgencyProposal.status === "new") {
        let currencyAccept = this.get('adRfp').get('currencyAccept');
        let currencyOption = currencyAccept.split(',');
        this.set('currencyOption', currencyOption);
      }

      this.set('adAgencyProposalItemList', {
        page: 0,
        pageSize: 20,
        //set it very big
        action: this.get("loadAdAgencyProposalItemTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.set('showMediaVendorDetailId', false);

      if (!('adAgencyProposalInvitedExipred' in this)) {
        this.set('adAgencyProposalInvitedExipred', false);
      }
    },

    //didReceiveAttrs
    loadAdAgencyProposalAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let findObj = {
        page: page,
        pagesize: pagingObj.pageSize
      };

      if (this.aAdAgencyProposalType) {
        let aAdAgencyProposalId = this.get('aAdAgencyProposal').id;
        findObj.aAdAgencyProposal = aAdAgencyProposalId;
      } else {
        let vAdAgencyProposalId = this.get('vAdAgencyProposal').id;
        findObj.vAdAgencyProposal = vAdAgencyProposalId;
      }

      let attachments = yield this.store.query('ad-agency-proposal-attachment', findObj); // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }

      Ember.set(pagingObj, "result", attachments); // if (pagingObj.refresh) { 
      //     this.set('refresh', false); 
      // }
    }).restartable(),
    loadAdAgencyProposalItemTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let findObj = {
        page: page,
        pagesize: pagingObj.pageSize
      };

      if (this.aAdAgencyProposalType) {
        let aAdAgencyProposalId = this.get('aAdAgencyProposal').id;
        findObj.aAdAgencyProposal = aAdAgencyProposalId;
      } else {
        let vAdAgencyProposalId = this.get('vAdAgencyProposal').id;
        findObj.vAdAgencyProposal = vAdAgencyProposalId;
      }

      let adAgencyProposalItems = yield this.store.query('ad-agency-proposal-item', findObj); // if (adAgencyProposalItems.meta && adAgencyProposalItems.meta.page && adAgencyProposalItems.meta.page.total) {
      //   set(pagingObj, "total", adAgencyProposalItems.meta.page.total);
      // }

      Ember.set(pagingObj, "result", adAgencyProposalItems);
    }),
    submitAdkAdAgencyProposalTask: (0, _emberConcurrency.task)(function* (event) {
      // event.preventDefault();
      let target = event.target;

      if (this.isOpen) {
        alert("A field is opened, please close it first");
        this.set('openedTarget', target.parentNode.parentNode.parentNode);
        return;
      } // let component = this;


      let adkToast = this.get('adkToast');
      let vAdAgencyProposal = this.get('vAdAgencyProposal');
      vAdAgencyProposal.set('status', 'vSubmitted');
      yield this.saveProposal('submitted');
      this.set('showDialogWithParent', false);
      this.set('editMode', false);
      adkToast.display("Proposal submitted successfully.", "warning", 3000); //need to save invitee to submitted....
    }),
    proposalExpiredDateComputed: Ember.computed('vAdAgencyProposal.responseExpiredDayCount', function () {
      //let adkSessionAccount = this.get('adkSessionAccount');
      let adAgencyRfpBody = this.get('adAgencyRfpBody');
      let vAdAgencyProposal = this.get('vAdAgencyProposal');
      let responseExpiredDayCount = vAdAgencyProposal.get('responseExpiredDayCount');
      let validDate = (0, _moment.default)(adAgencyRfpBody.get('proposalDeadline')).add(responseExpiredDayCount, 'days'); //let dateObj = new Date(adkSessionAccount.getTime());

      let rtnDate = new Date(validDate);
      return rtnDate;
    }),
    actions: {
      toggleEdit(field, even) {
        let fieldAllow = ['projectObjectives', 'ourUnderstanding', 'aboutUs', 'description', 'ourMethodology', 'discovery', 'visualize', 'implementation', 'currency', 'ourTeam', 'ourResults', 'cost', 'timelines', 'terms', 'responseExpiredDate', 'taxIP', 'finalNote']; //check if allow to edit

        if (!this.editMode) {
          return true;
        }

        let idx = fieldAllow.indexOf(field);

        if (idx != -1) {
          if (this.isOpen) {
            alert("Field is opened, please close it first");
            this.openedTarget.scrollIntoView();
            return;
          } //this.set('isOpen', true);


          this.toggleProperty('isEdit' + fieldAllow[idx]);

          if (this.get('isEdit' + fieldAllow[idx])) {
            this.set('isOpen', field);
            let target = even.currentTarget;
            this.set('openedTarget', target.parentNode.parentNode.parentNode);

            if (field === 'cost') {
              this.set('editorIsOpen', false);
            }
          } else {
            this.set('isOpen', false);
            this.set('openedTarget', null);
          }

          return;
        }

        this.toggleProperty('isEditing');
      },

      //toggleEdit
      openDialogWithParent(event) {
        if (this.isOpen) {
          alert("A field is opened, please close it first");
          return;
        }

        this.set('dialogOrigin', event.currentTarget);
        this.set('showDialogWithParent', true);
      },

      closeDialogWithParent() {
        // this.set('result', result);
        this.set('showDialogWithParent', false); //this.actions.cancelAction();
      },

      toggleProjObjectives() {
        this.toggleProperty('isEditProjectObjectives');
      },

      cancelAction(field) {
        let adkToast = this.get('adkToast');
        let vAdAgencyProposal = this.get('vAdAgencyProposal');

        if (field === 'cost') {
          if (this.get('editorIsOpen')) {
            alert('The editor form is opened, please close it first');
            return false;
          }

          vAdAgencyProposal.reload();
          adkToast.display("Editor closed", "warning");
        } else {
          if (vAdAgencyProposal.get('hasDirtyAttributes')) {
            vAdAgencyProposal.rollbackAttributes();
          }

          adkToast.display("Action cancel", "warning");
        } //else


        this.set('isOpen', false);
        this.actions.toggleEdit.call(this, field);
      },

      saveAdkAdAgencyProposal(field) {
        // event.preventDefault();
        let component = this;
        let adkToast = this.get('adkToast');
        let vAdAgencyProposal = this.get('vAdAgencyProposal');

        if (field === "responseExpiredDate") {
          vAdAgencyProposal.set("responseExpiredDate", this.get('proposalExpiredDateComputed'));
        }

        this.saveProposal().then(function () {
          component.set('isOpen', false);
          component.actions.toggleEdit.call(component, field);
          adkToast.display("Proposal successfully saved.", "warning");
        });
      },

      showVendorAdAgencyDetailAction: function (vendorAdAgencyDetailId, event) {
        event.preventDefault();

        if (this.showAdAgencyVendorDetailId) {
          alert('Other detail already opened... please close it first');
          return false;
        }

        Ember.set(this, 'showAdAgencyVendorDetailId', vendorAdAgencyDetailId); //set(this,'showVendorMediaDetailFlag',true);
      },
      closeVendorAdAgencyDetailAction: function (event) {
        event.preventDefault();
        Ember.set(this, 'showAdAgencyVendorDetailId', false); //set(this,'showVendorMediaDetailFlag',false);
      },

      nothing() {// Do nothing. Used just to highlight sections.
      }

    }
  });

  _exports.default = _default;
});