define("adtikir3v1/pods/components/adk-ad-agency-proposal-item-editor/component", ["exports", "ember-concurrency", "moment", "adtikir3v1/utils/adk-date-time"], function (_exports, _emberConcurrency, _moment, _adkDateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    isNewFlag: true,
    selectedTab: "mediainfo",
    init: function () {
      this._super(...arguments);

      this.set('tabObject', ["mediainfo", "costing", "note"]);
    },

    didReceiveAttrs() {
      this.set('adAgencyProposalItemLists', {
        page: 0,
        pageSize: 10,
        action: this.get("loadAdAgencyProposalItemTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      let vAdAgencyProposal = this.get('vAdAgencyProposal');
      this.set('adRfp', vAdAgencyProposal.get('adRfp'));
      this.set('adAgencyRfpBody', vAdAgencyProposal.get('adAgencyRfpBody'));
      let adRfp = vAdAgencyProposal.get('adRfp');
      this.set('campaignStartDate', adRfp.get('campaignStartDate'));
      this.set('campaignEndDate', adRfp.get('campaignEndDate'));
      this.set('openConfirmationDialogBox', false);
    },

    didInsertElement() {
      this.resetForm(); // let adAgencyProposalCostItemEditor = document.getElementById("adAgencyProposalCostItemEditor");
      // adAgencyProposalCostItemEditor.style.display = "none";
    },

    saveFormActionTask: (0, _emberConcurrency.task)(function* (toClose = false) {
      let adAgencyProposalItem = this.get('adAgencyProposalItem');

      if (!this.checkFields()) {
        return false;
      }

      yield adAgencyProposalItem.save();
      this.adkToast.display("Record save", "warning", 2000);
      let vAdAgencyProposal = this.get('vAdAgencyProposal');
      yield vAdAgencyProposal.reload();
      Ember.set(this.get('adAgencyProposalItemLists'), 'refresh', true);

      if (toClose) {
        if (this.activeDiv) {
          this.activeDiv.classList.remove('adkListCardActive');
          this.activeDiv = null;
        }

        this.resetForm();
      }
    }),
    checkFields: function () {
      let adkToast = this.get('adkToast');
      let adAgencyProposalItem = this.get('adAgencyProposalItem');

      if (!adAgencyProposalItem.name || adAgencyProposalItem.name.trim && adAgencyProposalItem.name.trim().length === 0) {
        adkToast.display("Ad item name must be filled", "error", 2000);
        return false;
      }

      if (!adAgencyProposalItem.cost || adAgencyProposalItem.cost.trim && adAgencyProposalItem.cost.trim().length === 0) {
        adkToast.display("Ad item cost must be filled", "error", 2000);
        return false;
      }

      let re = new RegExp('(:?^|\\s)(?=.)((?:0|(?:[1-9](?:\\d*|\\d{0,2}(?:,\\d{3})*)))?(?:\\.\\d*[0-9])?)(?!\\S)');

      if (!re.test(adAgencyProposalItem.cost)) {
        adkToast.display("Ad item cost should be a number", "error", 2000);
        return false;
      }

      return true;
    },

    delayAWhileActive(adAgencyProposalItem) {
      let activeDiv = document.getElementById('div-' + adAgencyProposalItem.id);
      let adAgencyProposalCostItemEditor = document.getElementById("adAgencyProposalCostItemEditor"); //let parentDiv = divParent.previousElementSibling;

      if (activeDiv) {
        this.set('adAgencyProposalItem', adAgencyProposalItem);
        activeDiv.classList.add('adkListCardActive');
        this.set('activeDiv', activeDiv);
        activeDiv.parentElement.insertBefore(adAgencyProposalCostItemEditor, activeDiv.nextElementSibling);
        adAgencyProposalCostItemEditor.style.display = "block";
        adAgencyProposalCostItemEditor.scrollIntoView();
        this.set('isNewFlag', false); //this.set('editorIsOpen', false);
      }
    },

    resetForm() {
      //clear form variable
      //reset the tab
      //clear flag
      this.set('isNewFlag', false); //this.set('editorIsOpen', false);

      this.set('activeDiv', null); //move div and hide

      let adAgencyProposalCostItemEditor = document.getElementById("adAgencyProposalCostItemEditor");
      adAgencyProposalCostItemEditor.style.display = "none";
      this.get('element').appendChild(adAgencyProposalCostItemEditor);
    },

    //reset form, move div to end
    deleteAdAgencyProposalItemTask: (0, _emberConcurrency.task)(function* () {
      let adAgencyProposalItem = this.get('adAgencyProposalItem');
      let adkToast = this.get('adkToast');

      try {
        //need do it here b4 the dialogOrigin get deleted....
        let el = this.get('dialogOrigin').parentElement.parentElement.parentElement;
        yield adAgencyProposalItem.destroyRecord();
        this.set('openConfirmationDialogBox', false);
        adkToast.display("Ad agency proposal item deleted successfully");
        let vAdAgencyProposal = this.get('vAdAgencyProposal');
        yield vAdAgencyProposal.reload();
        el.scrollIntoView();
        Ember.set(this.get('adAgencyProposalItemLists'), 'refresh', true);
        this.set('adAgencyProposalItem', null);
      } catch (e) {
        adkToast.display("Failed to delete media", "error");
      }
    }),

    //deleteMediaProposalItemTask
    checkActive() {
      if (this.activeDiv) {
        alert('Editor form already open, please close it first');
        let adAgencyProposalCostItemEditor = document.getElementById("adAgencyProposalCostItemEditor");
        adAgencyProposalCostItemEditor.scrollIntoView();
        return false;
      }

      return true;
    },

    actions: {
      deleteAdAgencyProposalItem: function (adAgencyProposalItem, event) {
        event.preventDefault();

        if (!this.checkActive()) {
          return false;
        }

        this.set('dialogOrigin', event.currentTarget);
        this.set('openConfirmationDialogBox', true);
        this.set('adAgencyProposalItem', adAgencyProposalItem);
      },
      cancelDeleteVendorMedia: function () {
        this.set('openConfirmationDialogBox', false);
      },
      updateAdAgencyProposalItem: function (adAgencyProposalItem, event) {
        event.preventDefault();
        let thisComponent = this;

        if (!this.checkActive()) {
          return false;
        } //something wrong with the ember component..
        //i need to use a later to activate the editor later...
        //maybe ember latest version dont have this bug 


        Ember.run.later(this, function () {
          thisComponent.delayAWhileActive(adAgencyProposalItem);
        }, 400); // let i=0;
        // let el=parentDiv;
        // while (el) {
        //   console.log(i);
        //   console.dir(el);
        //   el = el.nextSibling;
        //   i++;
        // }
      },
      addNewAdAgencyProposalItem: function (event) {
        if (!this.checkActive()) {
          return false;
        }

        let adAgencyProposalItem = this.store.createRecord('ad-agency-proposal-item', {
          vAdAgencyProposal: this.get('vAdAgencyProposal'),
          aAdAgencyProposal: null
        });
        this.set('adAgencyProposalItem', adAgencyProposalItem); //let el=this.get('element');

        let adAgencyProposalCostItemEditor = document.getElementById("adAgencyProposalCostItemEditor");
        let buttonDiv = event.currentTarget.parentElement;
        buttonDiv.parentElement.insertBefore(adAgencyProposalCostItemEditor, buttonDiv.nextElementSibling);
        adAgencyProposalCostItemEditor.style.display = "block";
        adAgencyProposalCostItemEditor.scrollIntoView();
        this.set('isNewFlag', true); //this.set('editorIsOpen', true);
      },

      cancelEdit() {
        let adAgencyProposalItem = this.get('adAgencyProposalItem');

        if (!(this.get('isNew') && adAgencyProposalItem.hasDirtyAttributes)) {
          adAgencyProposalItem.rollbackAttributes();
        }

        if (this.activeDiv) {
          this.activeDiv.classList.remove('adkListCardActive');
          this.activeDiv = null;
        }

        this.resetForm();
        this.element.scrollIntoView(); //cancel edit here
      }

    }
  });

  _exports.default = _default;
});