define("adtikir3v1/models/ad-campaign-rfp", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo // hasMany

  } = _emberData.default;

  var _default = Model.extend({
    adkSessionAccount: Ember.inject.service(),
    //proposalID: DS.attr('string'),
    campaignPic: attr('string'),
    campaignName: attr('string'),
    campaignNameLocal: attr('string'),
    campaignDesc: attr('string'),
    marketChallenges: attr('string'),
    productServices: attr('string'),
    customerDesc: attr('string'),
    campaignSuccess: attr('string'),
    campaignStartDate: attr('date'),
    campaignEndDate: attr('date'),
    campaignProductLaunchDate: attr('date'),
    clientRefId: attr('string'),
    proposalDeadline: attr('date'),
    //attachments: array(),
    advertiserCompanyId: attr('string'),
    advertiserCompanyName: attr('string'),
    updatedAt: attr('date'),
    createdAt: attr('date'),
    rfpSent: attr('number'),
    proposalReceived: attr('number'),
    advertiser: belongsTo('company'),
    //disable by syeo...
    // adcampaignId should not be send back to ember
    // adCampaignId: DS.attr('string'),
    updatedByFirstName: attr('string'),
    updatedByLastName: attr('string'),
    updatedByRole: attr('string'),
    targetingMale: attr('string'),
    targetingFemale: attr('string'),
    targetingMarriedStatus: attr('string'),
    targetingHasChildren: attr('string'),
    targetingAnnualIncomeLevel: attr('string'),
    targetingIndustry: attr('string', {
      defaultValue: ''
    }),
    targetingLanguage: attr('string', {
      defaultValue: ''
    }),
    targetingLocation: attr('string', {
      defaultValue: ''
    }),
    currencyAccept: attr('string', {
      defaultValue: ''
    }),
    company: belongsTo('company'),
    daysToDeadline: Ember.computed('proposalDeadline', function () {
      let now = this.get('adkSessionAccount').get('myNow');
      let deadline = (0, _moment.default)(this.get('proposalDeadline'));
      let diffDay = deadline.diff(now, "seconds");

      if (diffDay <= 86400 && diffDay > 0) {
        diffDay = 'Today is the deadline';
      } else if (diffDay > 86400) {
        diffDay = deadline.diff(now, "days");
        diffDay += " Days"; //do nothing;
        // } else if (diffDay == 0) {
        //   diffDay = 'Today is the deadline';
        // } else if (diffDay < 0) {
      } else {
        diffDay = 'Past Deadline';
      }

      return diffDay;
    }),
    passDeadline: Ember.computed("daysToDeadline", 'proposalDeadline', function () {
      let daysToDeadline = this.get('daysToDeadline');

      if (daysToDeadline === 'Past Deadline') {
        return true;
      }

      return false;
    }) //need to add in later
    //list of attachments for the rfp
    //copy over from adCampaignAttachmentSettings
    //************** 
    //adCampaignRfpAttachments: DS.hasMany('adCampaignRfpAttachments'),
    //Relationship with proposals
    //proposalAgency:            DS.belongsTo('proposal-agency'),
    //rfp now is agency use only... not link to proposal
    // proposal:                  DS.belongsTo('proposal'),
    //advertiserCompang: DS.belongsTo('company'), //display...

  });

  _exports.default = _default;
});