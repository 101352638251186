define("adtikir3v1/models/vendor-location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    location: belongsTo('location'),
    vendorMediaDetail: belongsTo('vendorMediaDetail'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });

  _exports.default = _default;
});