define("adtikir3v1/pods/components/adk-ad-rfp/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isAAdRfp: false,
    loadRfpMediaTypeTask: (0, _emberConcurrency.task)(function* () {
      let adRfp = this.get('adRfp');
      let mediaType = '';
      let mediaFormat = '';
      mediaType = this.store.findRecord('media-type', adRfp.get('mediatype'));

      if (adRfp.mediaformat) {
        mediaFormat = yield this.store.findRecord('media-format', adRfp.mediaformat);
      }

      this.set('mediaType', mediaType);
      this.set('mediaFormat', mediaFormat);
    }),
    loadIndustryTask: (0, _emberConcurrency.task)(function* () {
      let adRfp = this.get('adRfp');

      if (!adRfp.get('targetingIndustry')) {
        return [];
      }

      let targetingIndustryArray = adRfp.get('targetingIndustry').split(',');
      let returnArray = [];

      for (let i = 0; i < targetingIndustryArray.length; i++) {
        returnArray[i] = yield this.store.findRecord('industry-type', targetingIndustryArray[i]);
      } //for


      this.set('industryArray', returnArray);
    }),
    loadLanguageTask: (0, _emberConcurrency.task)(function* () {
      let adRfp = this.get('adRfp');

      if (!adRfp.get('targetingLanguage')) {
        return [];
      }

      let targetingLanguageArray = adRfp.get('targetingLanguage').split(',');
      let returnArray = [];

      for (let i = 0; i < targetingLanguageArray.length; i++) {
        returnArray[i] = yield this.store.findRecord('language', targetingLanguageArray[i]);
      } //for


      this.set('languageArray', returnArray);
    }),
    loadLocationTask: (0, _emberConcurrency.task)(function* () {
      let adRfp = this.get('adRfp');

      if (!adRfp.get('targetingLocation')) {
        return [];
      }

      let targetingLocationArray = adRfp.get('targetingLocation').split(',');
      let returnArray = [];

      for (let i = 0; i < targetingLocationArray.length; i++) {
        let model = '';

        if (targetingLocationArray[i] === "City") {
          model = "location-city";
        } else if (targetingLocationArray[i] === "State") {
          model = "location-state";
        } else {
          model = "location-country";
        }

        let location = yield this.store.findRecord(model, targetingLocationArray[i + 1]);
        i++;
        returnArray.push(location);
      } //for


      this.set('locationArray', returnArray);
    }),
    loadAdRfpAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adRfpId = this.get('adRfp').id;
      let attachments = yield this.store.query('ad-rfp-attachment', {
        adRfp: adRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      }); // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }

      Ember.set(pagingObj, "result", attachments); //return attachments;
    }).restartable(),
    loadAdAgencyRfpBodyAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adAgencyRfpBodyId = this.get('adAgencyRfpBody').id;
      let attachments = yield this.store.query('ad-agency-rfp-body-attachment', {
        adAgencyRfpBody: adAgencyRfpBodyId,
        page: page,
        pageSize: pagingObj.pageSize
      }); // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }

      Ember.set(pagingObj, "result", attachments); //return attachments;
    }).restartable(),
    loadAdMediaRfpBodyAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adMediaRfpBodyId = this.get('adMediaRfpBody').id;
      let attachments = yield this.store.query('ad-media-rfp-body-attachment', {
        adMediaRfpBody: adMediaRfpBodyId,
        page: page,
        pageSize: pagingObj.pageSize
      }); // if (attachments.meta && attachments.meta.page && attachments.meta.page.total) {
      //   set(pagingObj, "total", attachments.meta.page.total);
      // }

      Ember.set(pagingObj, "result", attachments); //return attachments;
    }).restartable(),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('aAdRfp')) {
        this.set('isAAdRfp', true);
        this.set('adRfp', this.get('aAdRfp'));
      }

      this.set('isAgencyRfp', false);
      this.set('isMediaRfp', false);

      if (this.get('adAgencyRfpBody')) {
        this.set('isAgencyRfp', true);
      }

      if (this.get('adMediaRfpBody')) {
        this.set('isMediaRfp', true);
      }

      this.set('loadRfpMediaTypeTaskInstant', this.loadRfpMediaTypeTask.perform());
      this.set('loadIndustryTaskInstance', this.loadIndustryTask.perform());
      this.set('loadLanguageTaskInstance', this.loadLanguageTask.perform());
      this.set('loadLocationTaskInstance', this.loadLocationTask.perform());
      this.set('title', 'Customer Brief');
    },

    actions: {
      nothing() {}

    }
  });

  _exports.default = _default;
});