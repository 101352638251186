define("adtikir3v1/pods/components/adkpanel-adpost-discussion/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    page: 0,
    // pageSize: 3,
    //formInvalid: false,
    newTitle: '',
    newComment: '',
    newMedia: '',
    totalPage: Ember.computed('total', 'pageSize', function () {
      let total = 0;

      if (this.total) {
        total = this.total;
      }

      let pageSize = 1;

      if (this.pageSize > 0) {
        pageSize = this.pageSize;
      }

      total = Math.ceil(total / pageSize);
      return total;
    }),
    formInvalid: Ember.computed('newTitle', 'newMedia', 'newComment', function () {
      let newTitle = this.get('newTitle');
      let newMedia = this.get('newMedia');
      let newComment = this.get('newComment');

      if (newTitle == '' || newMedia == '' || newComment == '') {
        return true;
      }
    }),
    loadAdPostDiscussionFunc: (0, _emberConcurrency.task)(function* () {
      let adPost = this.get('adPost');
      let result = yield this.get('loadAdPostDiscussions')(adPost.id, this.get('page'), this.get('pageSize'));
      this.set('adPostDiscussions', result.adPostDiscussions);
      this.set('total', result.total);
    }),
    newDiscussionPost: (0, _emberConcurrency.task)(function* () {
      let adP = this.get('adPost');
      yield this.saveNewDiscussion({
        title: this.get('newTitle'),
        media: this.get('newMedia'),
        comment: this.get('newComment'),
        thumbnail: this.get('thumbnail')
      }, adP);
      this.actions.closeDiscussionDialog.apply(this);
      yield adP.reload();
      let latestDiscussion = yield adP.get('latestDiscussion');
      this.set('latestDiscussion', latestDiscussion);
      this.loadAdPostDiscussionFunc.perform(this);
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this.loadAdPostDiscussionFunc.perform(this); //let adPostId = this.get('adPost').get('id');

      let wflowNum = this.get('ad').get('workFlowStatusNumber'); //let wfNumAdPostId = wflowNum + "-" + adPostId;

      this.set('wfNum', wflowNum);
      let component = this;
      let adPost = this.get('adPost');
      adPost.get('latestDiscussion').then(function (latestDiscussion) {
        component.set('latestDiscussion', latestDiscussion);
      });
    },

    actions: {
      openNewDiscussion(event) {
        this.set('dialogOrigin', Ember.$(event.currentTarget));
        this.set('showNewDiscussion', true);

        if (this.latestDiscussion.annoEditor) {
          this.latestDiscussion.set('annoEditor', false);
        } // console.log("latestDiscussion id", this.latestDiscussion);


        let adkToast = this.get('adkToast');
        adkToast.display('All fields are required', 'warning', 3000);
      },

      closeDiscussionDialog() {
        this.set('showNewDiscussion', false);
        this.set('newTitle', '');
        this.set('newComment', '');
        this.set('newMedia', '');
      },

      uploadAdPostDiscussionPic() {
        return;
      },

      setPage(number) {
        if (number >= this.totalPage) {
          number = this.totalPage - 1;
        } else if (number < 0) {
          number = 0;
        }

        this.set('page', number); // this.loadAdPostCmtFunc.perform();
      },

      nextPage() {
        this.actions.setPage.call(this, this.get('page') + 1);
      },

      prevPage() {
        this.actions.setPage.call(this, this.get('page') - 1);
      },

      annoEditor(record) {
        // if(record.annoEditor === true) {
        //     record.set('annoEditor', false);
        // } else {
        //     record.set('annoEditor', true);
        //     record.set('annoViewer', false);
        // }
        record.toggleProperty('annoEditor');
      },

      annoViewer(record) {
        // if(record.annoViewer === true) {
        //     record.set('annoViewer', false);
        // } else {
        //     record.set('annoViewer', true);
        //     record.set('annoEditor', false);
        // }
        record.toggleProperty('annoViewer');
      }

    }
  });

  _exports.default = _default;
});