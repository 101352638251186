define("adtikir3v1/pods/components/adk-companyinfo-currencyformat/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),

    init() {
      this._super(...arguments);

      this.setProperties({
        exchangeRateOptions: [{
          value: "daily",
          name: "Daily Exchange Rate"
        }, {
          value: "monthly",
          name: "Last Month Monthly Exchange Rate"
        }, // {
        //   value: "rolling",
        //   name: "Rolling Monthly Exchange Rate"
        // },
        {
          value: "annual",
          name: "Annual Exchange Rate (Previous Year)"
        }, {
          value: "custom",
          name: "Custom Exchange Rate"
        }]
      });
      this.set('currecyArray', ["AUD", "BGN", "BRL", "CAD", "CHF", "CNY", "CZK", "DKK", "EUR", "GBP", "HKD", "HRK", "HUF", "IDR", "ILS", "INR", "ISK", "JPY", "KRW", "MXN", "MYR", "NOK", "NZD", "PHP", "PLN", "RON", "RUB", "SEK", "SGD", "THB", "TRY", "USD", "ZAR"]);
    },

    didReceiveAttrs() {
      this._super(...arguments); //   this.setProperties({
      //     currency : this.get('myCompany.currency'),
      //     defaultCurrency : this.get('myCompany.foreignCurrency')
      //   });
      // },
      // makeCopy() {
      //   this.set('currency', this.myCompany.currency);
      //   this.set('foreignCurrency', this.myCompany.foreignCurrency);
      // },
      // resetCopy() {
      //   this.set('currency', '');
      //   this.set('foreignCurrency', '');


      this.set("exchangeRateType", this.get('exchangeRateType'));
      this.set("exchangeRate", this.get("exchangeRate"));
    },

    selectedExchangeRateType: Ember.computed("exchangeRateType", function () {
      return this.get('exchangeRateOptions').findBy('value', this.get('exchangeRateType'));
    }),
    actions: {
      openEditCurrencyDialog(ev) {
        this.set('showEditCurrencyDialog', true);
        this.set('dialogOrigin', ev.currentTarget);
      },

      closeEditCurrencyDialog() {
        this.set('showEditCurrencyDialog', false);
        this.cancelAction(); //this.resetCopy();
      },

      updateCurrency() {
        this.set('showEditCurrencyDialog', false); // this.myCompany.set('currency',this.currency);
        // this.myCompany.set('foreignCurrency',this.foreignCurrency);
        // this.myCompany.save();

        this.saveAction();
      },

      updateExchangeRateType(selectedType) {
        this.set('exchangeRateType', selectedType.value);
      }

    }
  });

  _exports.default = _default;
});