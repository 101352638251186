define("adtikir3v1/helpers/money", ["exports", "@abcum/ember-helpers/helpers/money"], function (_exports, _money) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _money.default;
    }
  });
  Object.defineProperty(_exports, "money", {
    enumerable: true,
    get: function () {
      return _money.money;
    }
  });
});