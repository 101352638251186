define("adtikir3v1/pods/components/adk-dt/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkSessionAccount: Ember.inject.service(),
    //moment: service(),
    spanFlag: false,
    dateFormat: Ember.computed('adkSessionAccount.myCompanyDetail', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let dateFormat = adkSessionAccount.get('myCompanyDetail').get('dateFormat');
      return dateFormat;
    }),
    timeFormat: Ember.computed('adkSessionAccount.myCompanyDetail', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let timeFormat = adkSessionAccount.get('myCompanyDetail').get('timeFormat');
      return timeFormat;
    }),
    fullFormat: Ember.computed('dateFormat', 'timeFormat', function () {
      return this.dateFormat + ' ' + this.timeFormat + 'Z';
    }),
    utcOffset: Ember.computed('adkSessionAccount.timeZone.gmtOffset', function () {
      let gmtOffset = 0;

      if (this.get('adkSessionAccount').get('timeZone')) {
        gmtOffset = this.get('adkSessionAccount').get('timeZone').get('gmtOffset');
      }

      return Math.floor(gmtOffset / 60);
    }),

    //utcOffset
    init() {
      this._super(...arguments); // let thisComponent = this;
      // let adkSessionAccount = this.get('adkSessionAccount');
      // adkSessionAccount.get('timeZone').then(function(timeZone) {
      //   thisComponent.set('timeZone', timeZone);
      // });

    },

    //init
    didReceiveAttrs() {
      this._super(...arguments);

      let theDate = this.get('theDate');
      let type = this.get('t');
      let format = 'fullFormat'; //let moment=this.get('moment');

      if (type == 't') {
        format = 'timeFormat';
      } else if (type == 'd') {
        format = 'dateFormat';
      }

      let formatStr = this.get(format);

      if (this.get('spanFlag')) {
        this.set('tagName', "span");
      }

      theDate = (0, _moment.default)(theDate);

      if (theDate.isValid()) {
        let displayDate = theDate.utcOffset(this.get('utcOffset')).format(formatStr);
        this.set('displayDate', displayDate);
      } else {
        this.set('displayDate', ' ');
      }
    }

  });

  _exports.default = _default;
});