define("adtikir3v1/pods/components/adk-media/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    embedded: false,

    searchAllowArray(type) {
      let allowArray = this.get("allowArray");

      if (allowArray) {
        if (allowArray.indexOf(type) == -1) {
          return false;
        }
      }

      return true;
    },

    allowPdf: Ember.computed("allowArray.[]", function () {
      return this.searchAllowArray("pdf");
    }),
    allowImage: Ember.computed("allowArray.[]", function () {
      return this.searchAllowArray("image");
    }),
    allowVideo: Ember.computed("allowArray.[]", function () {
      return this.searchAllowArray("video");
    }),
    allowAudio: Ember.computed("allowArray.[]", function () {
      return this.searchAllowArray("audio");
    }),
    allowDoc: Ember.computed("allowArray.[]", function () {
      return this.searchAllowArray("doc");
    }),

    // @service store,
    // For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);

      if (!this.get('allowArray')) {
        this.set('allowArray', ["pdf", "image", "video", "audio"]);
      }

      this.set('fileIdArray', []);
    },

    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      },

      uploadImage(attrs) {
        let hasFileUploaded = false; //  *************Todo *******
        //  need to modify to allow multiple files upload... 
        //  now only process the first one      

        if (attrs.filesId.length > 0) {
          this.set("fileIdArray", attrs.filesId); //let files;
          //check the this.fileIdArray]0] is in adfkid://**fid**/**tnid**/video or not... 
          //if so, set the thumbnail and  this.fileIdArray[0] to the adkfid:// format...

          let mySplit = this.fileIdArray[0].split('/');
          let thumbnail = false;

          if (mySplit.pop() === "video") {
            thumbnail = "adkfid://" + mySplit.pop();
            this.fileIdArray[0] = "adkfid://" + mySplit.pop();
          }

          if (this.recordPropertyField) {
            //this is the set the object.propertyField to this.fileIdArray[0] 
            this.record.set(this.recordPropertyField, this.fileIdArray[0]);

            if (thumbnail) {
              this.record.set("thumbnail", thumbnail); //this.set('thumbnail', thumbnail);
            }
          } else {
            if (this.recordPropertyField == '') {
              this.set("record", this.fileIdArray[0]);

              if (thumbnail) {
                this.set('thumbnail', thumbnail);
              }
            }
          } // this.set('saveProfilePic', attrs.action );


          hasFileUploaded = true;
        } //  pass 2nd options to saveAction pointed to
        //  first option already set when pass in as saveAction
        //  hasFileUploaded to tell saveAction, that is a 
        //  this allow the caller to set the action on what should it do...
        //  eg.. cancel or continue to save... base on the app flow


        this.saveAction(hasFileUploaded); //this.record.save();
      }

    } //actions

  });

  _exports.default = _default;
});