define("adtikir3v1/helpers/nl2br", ["exports", "@abcum/ember-helpers/helpers/nl2br"], function (_exports, _nl2br) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _nl2br.default;
    }
  });
  Object.defineProperty(_exports, "nl2br", {
    enumerable: true,
    get: function () {
      return _nl2br.nl2br;
    }
  });
});