define("adtikir3v1/pods/components/adkcard-dashboard-date/component", ["exports", "adtikir3v1/utils/adk-date-time", "moment"], function (_exports, _adkDateTime, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this.set('showDialogWithParent', false); // this.set('items', A([{
      //     icon: 'help',
      //     title: 'View Tutorial',
      //     href: 'https://www.w3schools.com'
      //   },
      //   {
      //     icon: 'mode_edit',
      //     title: 'Edit info',
      //     action: 'edit_info',
      //     isFirst: true
      //   },
      //   {
      //     icon: 'delete',
      //     title: 'Hide card'
      //   }
      // ]));

      this.resetCopy();
      this.set('showErrorFg', false);
    },

    // errorReport: function (parent, errStr, type, timer = 2000) {
    //   //let mycomponent = this;
    //   let typeClass = "md-primary";
    //   if (type == "warning") {
    //     typeClass = "md-accent";
    //   } else if (type == "error") {
    //     typeClass = "md-warn";
    //   } 
    //   //this.set('errType', type);
    //   this.set('errStr', errStr);
    //   this.set('errDuration', timer);
    //   this.set('errTypeClass', typeClass);    
    //   this.set('errParent', parent);
    //   this.set('showErrorFg', true);
    // },
    makeCopy() {
      let adCampaign = this.get('adCampaign');
      this.set('campaignStartDateLocal', (0, _moment.default)(adCampaign.campaignStartDate));
      this.set('productLaunchDateLocal', (0, _moment.default)(adCampaign.campaignProductLaunchDate));
      this.set('campaignEndDateLocal', (0, _moment.default)(adCampaign.campaignEndDate));
      this.set('campaignStartDate', (0, _moment.default)(adCampaign.campaignStartDate));
      this.set('productLaunchDate', (0, _moment.default)(adCampaign.campaignProductLaunchDate));
      this.set('campaignEndDate', (0, _moment.default)(adCampaign.campaignEndDate));
      this.set('centerStart', this.get('campaignStartDateLocal'));
      this.set('centerProducLaunch', this.get('productLaunchDateLocal'));
      this.set('centerEnd', this.get('campaignEndDateLocal'));
    },

    resetCopy: function () {
      this.set('campaignStartDateLocal', '');
      this.set('productLaunchDateLocal', '');
      this.set('campaignEndDateLocal', '');
      this.set('campaignStartDate', '');
      this.set('productLaunchDate', '');
      this.set('campaignEndDate', '');
      this.set('centerStart', '');
      this.set('centerProductLaunch', '');
      this.set('centerEnd', '');
    },
    actions: {
      closeErr() {
        this.set('showErrorFg', false);
      },

      openDialogWithParent(event) {
        this.set('dialogOrigin', event.currentTarget);
        this.makeCopy();
        this.set('showDialogWithParent', true);
      },

      closeDialogWithParent() {
        this.resetCopy();
        this.set('showDialogWithParent', false);
      },

      updateDate(theField, selected) {
        let adkToast = this.get('adkToast');
        let newDate = selected.moment;
        let startDate = this.get('campaignStartDateLocal'); //assume is a moment obj

        let endDate = this.get('campaignEndDateLocal'); //assume is a moment obj

        let productLaunchDate = this.get('productLaunchDateLocal');

        if (theField != 'campaignEndDateLocal') {
          //check newCenterDate > endDate
          if (endDate != '') {
            //compare with endDate...
            if (_adkDateTime.default.compareDate(newDate, endDate) >= 0) {
              newDate = '';
              adkToast.display("Date should not be later than the end date, it will not be set", "warning", 2000);
            }
          } //endDate != ''

        } //cannot put else.. cause productLaunchDate need check both


        if (theField != 'campaignStartDateLocal') {
          if (startDate != '') {
            //compare with startDate
            if (_adkDateTime.default.compareDate(newDate, startDate) <= 0) {
              newDate = '';
              adkToast.display("Date should not be earlier than the start date, it will not be set", "warning", 2000);
            }
          } //startdate != ''

        }

        if (newDate != '') {
          // this check if user modified the start date or end date.. 
          // but product launch date not in range
          //need check productLunchDate <= startDate
          //if so set to '';
          if (theField == 'campaignStartDateLocal' && productLaunchDate != '') {
            if (_adkDateTime.default.compareDate(productLaunchDate, newDate) <= 0) {
              this.set('productLaunchDateLocal', '');
              this.set('productLaunchDate', '');
              this.set('centerProductLaunchLocal', newDate);
              adkToast.display("Product launch date is not in range, will reset it", "warning", 2000);
            }
          } else //need check productLunchDate >= endDate
            if (theField == 'campaignEndDateLocal' && productLaunchDate != '') {
              if (_adkDateTime.default.compareDate(productLaunchDate, newDate) >= 0) {
                this.set('productLaunchDateLocal', '');
                this.set('productLaunchDate', '');
                this.set('centerProductLaunchLocal', newDate);
                adkToast.display("Product launch date is not in range, will reset it", "warning", 2000);
              }
            }

          this.set(theField, newDate); //need to update the actual date..
          // convert the date to company timezoe from local computer timezone

          let theActualField = theField.substring(0, theField.length - 5);
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          this.set(theActualField, (0, _moment.default)(new Date(newDateStr)));
        } // newDate != ''
        // console.log(arguments);

      },

      // updateDate()
      checkCenter(theField, centerField, centerDate) {
        let newCenterDate = centerDate.moment;
        let startDate = this.get('campaignStartDateLocal'); //assume is moment

        let endDate = this.get('campaignEndDateLocal'); //assume is moment
        // let productLaunchDate = this.get('productLaunchDate');

        if (theField != 'campaignEndDateLocal') {
          //check newCenterDate > endDate
          if (endDate != '') {
            //compare with endDate...
            if (_adkDateTime.default.compareYearMonth(newCenterDate, endDate) >= 0) {
              newCenterDate = endDate;
            }
          }
        }

        if (theField != 'campaignStartDateLocal') {
          if (startDate != '') {
            //compare with startDate
            if (_adkDateTime.default.compareYearMonth(newCenterDate, startDate) <= 0) {
              newCenterDate = startDate;
            }
          }
        }

        this.set(centerField, newCenterDate);
      },

      //checkCenter()
      editCampaignDate() {
        let adkToast = this.get('adkToast');
        let campaignStartDate = this.get('campaignStartDate');
        let productLaunchDate = this.get('productLaunchDate');
        let campaignEndDate = this.get('campaignEndDate'); //let parent = event.currentTarget;
        //let component = this;

        if (!campaignStartDate || campaignStartDate == '') {
          adkToast.display("Campaign start date is  not set, please set it", "warning", 1500);
          return;
        }

        if (!productLaunchDate || productLaunchDate == '') {
          adkToast.display("Product launch date is not set, please set it", "warning", 1500);
          return;
        }

        if (!campaignEndDate || campaignEndDate == '') {
          adkToast.display("Campaign end date is not set, please set it", "warning", 1500);
          return;
        }

        let adCampaign = this.get('adCampaign');
        adCampaign.set('campaignStartDate', campaignStartDate.toDate());
        adCampaign.set('campaignProductLaunchDate', productLaunchDate.toDate());
        adCampaign.set('campaignEndDate', campaignEndDate.toDate());
        this.saveAction(true, adCampaign);
        this.resetCopy();
        this.set('showDialogWithParent', false);
      }

    } //actions

  });

  _exports.default = _default;
});