define("adtikir3v1/helpers/length", ["exports", "@abcum/ember-helpers/helpers/length"], function (_exports, _length) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _length.default;
    }
  });
  Object.defineProperty(_exports, "length", {
    enumerable: true,
    get: function () {
      return _length.length;
    }
  });
});