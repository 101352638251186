define("adtikir3v1/pods/components/adk-ad-media-award/component", ["exports", "ember-concurrency", "adtikir3v1/utils/adk-date-time", "moment"], function (_exports, _emberConcurrency, _adkDateTime, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    // campaignName: '',
    // campaignNameLocal: '',
    //  editMode    : '',
    adkSessionAccount: Ember.inject.service(),
    projectStartDate: null,
    projectStartDateCenter: null,
    showAcceptAwardFormFg: false,
    showCancelAwardFormFg: false,
    showAwardedInfoFg: Ember.computed('vAdMediaProposal.status', function () {
      let vAdMediaProposal = this.get('vAdMediaProposal');
      let flaseArray = ['new', 'vSubmitted', 'expired'];

      if (flaseArray.indexOf(vAdMediaProposal.status) >= 0) {
        return false;
      }

      return true;
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('vAdMediaProposal')) {
        this.set("adRfp", this.get('vAdMediaProposal').get('adRfp'));
        this.set("adMediaRfpBody", this.get('vAdMediaProposal').get('adMediaRfpBody'));
      }

      this.resetForm();
    },

    vAcceptExpiredDateComputed: Ember.computed('vAcceptExpiredDateCount', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let vAcceptExpiredDateCount = this.get('vAcceptExpiredDateCount');
      let dateObj = new Date(adkSessionAccount.get('myNow'));
      dateObj.setDate(dateObj.getDate() + vAcceptExpiredDateCount + 1);
      dateObj = (0, _moment.default)(dateObj);
      let newDateStr = dateObj.format('YYYY-MM-DD') + 'T00:00:00.000' + adkSessionAccount.get('utcOffsetStr');
      let rtnDate = new Date(newDateStr);
      return rtnDate;
    }),

    resetForm() {
      let adkSessionAccount = this.get('adkSessionAccount');
      let myNow = adkSessionAccount.getComopanyLocalDateObj(adkSessionAccount.get('myNow'));
      let newDateStr = myNow.format('YYYY-MM-DD') + 'T00:00:00.000' + adkSessionAccount.get('utcOffsetStr');
      let rtnDate = new Date(newDateStr);
      this.set('vResponseNote', '');
      this.set('projectStartDate', (0, _moment.default)(rtnDate));
      newDateStr = myNow.format('YYYY-MM-DD');
      rtnDate = new Date(newDateStr);
      this.set('projectStartDateLocal', (0, _moment.default)(rtnDate));
      this.set('projectStartDateCenter', (0, _moment.default)(rtnDate));
      this.set('vAcceptExpiredDateCount', 3);
    },

    saveAcceptAwardFormTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast'); //let adkSessionAccount = this.get('adkSessionAccount');

      let vResponseNote = this.get('vResponseNote');
      vResponseNote = vResponseNote.trim();

      if (vResponseNote.length == 0) {
        adkToast.display('Please fill in the note', 'error', 2000);
        return false;
      }

      let vAdMediaProposal = this.get('vAdMediaProposal');
      vAdMediaProposal.set('vResponseNote', vResponseNote);
      vAdMediaProposal.set('status', 'vAccept');
      vAdMediaProposal.set('projectStartDate', this.get('projectStartDate'));

      if (vAdMediaProposal.isForeignCurrency) {
        vAdMediaProposal.set('vFinalCurrencyRate', vAdMediaProposal.get('exchangeRate'));
        vAdMediaProposal.set('vFinalCurrencyRateDate', vAdMediaProposal.get('exchangeRateDate'));
      }

      vAdMediaProposal.set('vAcceptExpiredDate', this.get('vAcceptExpiredDateComputed'));

      try {
        yield vAdMediaProposal.save();
      } catch (e) {
        throw e;
      }

      this.set('showAcceptAwardFormFg', false);
      this.resetForm();
    }),
    saveCancelAwardFormTask: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');
      let vResponseNote = this.get('vResponseNote');
      vResponseNote = vResponseNote.trim();

      if (vResponseNote.length == 0) {
        adkToast.display('Please fill in the note', 'error', 2000);
        return false;
      }

      let vAdMediaProposal = this.get('vAdMediaProposal');
      vAdMediaProposal.set('vResponseNote', vResponseNote);
      vAdMediaProposal.set('status', 'vReject');

      try {
        yield vAdMediaProposal.save();
      } catch (e) {
        throw e;
      }

      this.set('showCancelAwardFormFg', false);
      this.resetForm(); // this.set('vResponseNote', '');
      // setTimeout(function () {
      //   history.replaceState(null, null, ' ');
      // }, 500);
      // window.location.hash = '#' + this.element.querySelector('md-card-content').id;
    }),
    actions: {
      toggleAcceptNote() {
        this.toggleProperty('isEditNote');
      },

      showCancelAwardForm() {
        this.set('showCancelAwardFormFg', true);
        this.resetForm();
      },

      showAcceptAwardForm() {
        this.set('showAcceptAwardFormFg', true);
      },

      cancelCancelAwardForm() {
        this.set('showCancelAwardFormFg', false);
        this.resetForm();
      },

      updateDate(theField, selected) {
        let adkToast = this.get('adkToast');
        let adkSessionAccount = this.get('adkSessionAccount');
        let newDate = selected.moment;
        let noErrorFlag = true; // let startDate = moment(this.get('adkSessionAccount').get('myNow'));
        // let newDateStr = startDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');

        let startDate = adkSessionAccount.getComopanyLocalDateObj(adkSessionAccount.get('myNow'));
        let endDate = adkSessionAccount.getComopanyLocalDateObj(this.get('adRfp').get('adPlayEndDate'));

        if (_adkDateTime.default.compareDate(newDate, endDate) > 0) {
          adkToast.display("Date beyond ad play end date, set to end date", "error", 3000);
          newDate = endDate;
          noErrorFlag = false;
        }

        if (_adkDateTime.default.compareDate(newDate, startDate) < 0) {
          adkToast.display("Date is before today date, set to today date", "error", 3000);
          newDate = startDate;
          noErrorFlag = false;
        } // let newDate = this.get(theField);


        this.set(theField + 'Local', newDate); // let theActualField = theField.substring(0, theField.length - 5);
        // let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
        // this.set(theField, moment(new Date(newDateStr)).toDate());

        let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00.000' + adkSessionAccount.get('utcOffsetStr');
        let rtnDate = new Date(newDateStr);
        this.set(theField, rtnDate); //let startDate = adkSessionAccount.getComopanyLocalDateObj(adkSessionAccount.get('myNow'));

        if (noErrorFlag) {
          adkToast.display("Date successfully set", "warning", 2000);
        }
      },

      //updateDate
      checkCenter(centerField, centerDate) {
        let adkSessionAccount = this.get('adkSessionAccount');
        let startDate = adkSessionAccount.getComopanyLocalDateObj(this.get('adkSessionAccount').get('myNow'));
        let endDate = adkSessionAccount.getComopanyLocalDateObj(this.get('adRfp').get('adPlayEndDate'));
        let newCenterDate = centerDate.moment; //let projectStartDate;

        if (_adkDateTime.default.compareYearMonth(newCenterDate, endDate) > 0) {
          newCenterDate = endDate;
        }

        if (_adkDateTime.default.compareYearMonth(newCenterDate, startDate) < 0) {
          newCenterDate = startDate;
        }

        this.set(centerField, newCenterDate);
      },

      //checkCenter
      cancelAcceptAwardForm() {
        this.set('showAcceptAwardFormFg', false);
        this.set('acceptanceNote', '');
        this.set('projectStartDate', new Date());
        setTimeout(function () {
          history.replaceState(null, null, ' ');
        }, 500);
        window.location.hash = '#' + this.element.querySelector('md-card-content').id;
      },

      nothing() {}

    }
  });

  _exports.default = _default;
});