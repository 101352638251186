define("adtikir3v1/pods/components/adk-display-vendor-media-detail/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    didReceiveAttrs() {
      this.loadMediaDetailLocationTask.perform();
    },

    loadMediaDetailLocationTask: (0, _emberConcurrency.task)(function* () {
      let locations = yield this.store.query('location', {
        vendorMediaDetail: this.get('vendorMedia').get('id')
      });
      this.set("locationList", locations.toArray());
    }).restartable()
  });

  _exports.default = _default;
});