define("adtikir3v1/pods/agency/admanager/ad/financials/account/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AdAccountRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class AdAccountRoute extends Ember.Route.extend(_keycloakAuthenticatedRoute.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "adkSessionAccount", _descriptor2, this);
    } // @task(function* (){
    //   let adAgencyModel = yield this.modelFor('agency.admanager.ad');
    //   let adAgency = yield adAgencyModel.ad;
    //   let adInvoice = yield this.store.query('vAdInvoice', {
    //     adId: adAgency.id,
    //   });
    //   return {
    //     ad: adAgency,
    //     adCampaign      : adAgencyModel.adCampaign,
    //     adCampaignDetail: adAgencyModel.adCampaignDetail,
    //     acWorkFlows      : adAgencyModel.acWorkFlows,
    //     adInvoice       : adInvoice,
    //   }
    // }).restartable() loadModelTask;
    // model() {
    //   try {
    //     return this.loadModelTask.perform();
    //   } catch (e) {
    //     throw (e);
    //   }
    // }
    // setupController(controller, model) {
    //   this._super(controller, model);
    //   controller.set('ad', model.ad);
    //   controller.set('adCampaign', model.adCampaign);
    //   controller.set('adCampaignDetail', model.adCampaignDetail);
    //   controller.set('acWorkFlows', model.acWorkFlows);
    //   controller.set('adInvoice', model.adInvoice);
    // }  //setupController


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AdAccountRoute;
});