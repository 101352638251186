define("adtikir3v1/models/parent-territory", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com
  //Author: Soon Lam 
  //Date: 
  //Purpose: Parent are Regional offices and territories are the responsibility of the Regional Offices
  // .       Home Office is responsible for all Territories (i.e. all Territories are selected)
  //Usage: 
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    parent: attr('string'),
    companyId: attr('string'),
    companyName: attr('string')
  });

  _exports.default = _default;
});