define("adtikir3v1/pods/components/adkcard-dashboard-details/component", ["exports", "sanitize-html"], function (_exports, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {
  //   task
  // } from 'ember-concurrency';
  // import {
  //   inject as service
  // } from '@ember/service';
  //import adkDateTime  from '../../../utils/adk-date-time';
  //import moment from 'moment'
  //import { computed } from '@ember/object'; 
  var _default = Ember.Component.extend({
    // @service store,
    // For components to create own variables
    didReceiveAttrs() {
      this._super(...arguments);

      this.setProperties({
        campaignName: this.adCampaign.get('campaignName'),
        campaignNameLocal: this.adCampaign.get('campaignNameLocal'),
        clientRefId: this.adCampaign.get('clientRefId')
      });
    },

    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      },

      saveCampaignInfo() {
        let adCampaign = this.get('adCampaign'); // Soon 
        // This is not elegant solution as need to pass isEditing back from parent on success.
        // Maybe implement didUpdateAttrs?

        this.toggleProperty('isEditing');
        adCampaign.set('campaignName', (0, _sanitizeHtml.default)(this.get('campaignName')));
        adCampaign.set('campaignNameLocal', (0, _sanitizeHtml.default)(this.get('campaignNameLocal')));
        adCampaign.set('clientRefId', (0, _sanitizeHtml.default)(this.get('clientRefId')));
        this.set('campaignName', (0, _sanitizeHtml.default)(this.get('campaignName')));
        this.set('campaignNameLocal', (0, _sanitizeHtml.default)(this.get('campaignNameLocal')));
        this.set('clientRefId', (0, _sanitizeHtml.default)(this.get('clientRefId')));
        this.saveAction(true, adCampaign); //End of localComponentSaveName
      }

    } //action

  });

  _exports.default = _default;
});