define("adtikir3v1/pods/profile/company/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProfileCompanyIndexClass = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('model.myCompany.country'), (_class = class ProfileCompanyIndexClass extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adkSessionAccount", _descriptor, this);

      _initializerDefineProperty(this, "adkToast", _descriptor2, this);

      _defineProperty(this, "actions", {
        actionToEditMode() {
          this.set('editMode', true);
        },

        actionCancelEditMode() {
          if (this.model.myCompany.get('hasDirtyAttributes')) {
            this.model.myCompany.rollbackAttributes();
          }

          this.set('editMode', false);
        },

        actionUpdateCompany() {
          let adkToast = this.get('adkToast');
          let controller = this;

          if (this.filesid) {
            this.model.myCompany.set('logo', this.filesid[0]);
          }

          this.model.myCompany.set('industries', this.model.myCompanyIndustries);
          this.model.myCompany.save().then(function () {
            adkToast.display("Record save", "warning");
            controller.set('editMode', false);
          }).catch(function (e) {
            adkToast.display(e.message, "error");
            controller.model.myCompany.rollbackAttributes();
            controller.set('editMode', false);
          });
        },

        uploadImage(attrs) {
          if (attrs.filesId.length != 0) {
            //this.set("filesid", attrs.filesid);
            this.model.myCompany.set('logo', attrs.filesId[0]);
          }
        },

        onChangeAgency() {},

        onChangeMedia() {}

      });
    }

    get myCompanyCountry() {
      let myCompanyCountry = this.get('store').peekRecord('country', this.model.myCompany.country);
      return myCompanyCountry;
    }

    set myCompanyCountry(country) {
      this.model.myCompany.set('country', country.id);
    }

    init() {
      super.init(...arguments);
      this.set('editMode', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adkToast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "myCompanyCountry", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "myCompanyCountry"), _class.prototype)), _class));
  _exports.default = ProfileCompanyIndexClass;
});