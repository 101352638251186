define("adtikir3v1/models/place-country-tbd", ["exports", "ember-data", "adtikir3v1/models/place-tbd"], function (_exports, _emberData, _placeTbd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    hasMany
  } = _emberData.default;

  var _default = _placeTbd.default.extend({
    name: attr("string"),
    createdAt: attr("date"),
    updatedAt: attr("date"),
    cities: hasMany('city'),
    states: hasMany('state')
  });

  _exports.default = _default;
});