define("adtikir3v1/pods/components/adk-display-image/component", ["exports", "ember-concurrency", "adtikir3v1/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    CDN: Ember.inject.service('adk-cdn-url'),
    width: 0,
    height: 0,

    init() {
      this._super(...arguments);

      this.setProperties({
        url: '',
        isImage: false,
        isVideo: false,
        isLandscape: false,
        videoType: ''
      });
    },

    preloadImage(url) {
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = resolve;
        image.onerror = reject;
        image.src = url;
      });
    },

    didReceiveAttrs() {
      //     fileid: this.fileid,
      if (this.width >= this.height) {
        this.isLandscape = true;
      }

      if (this.title) {
        this.set('myTitle', this.title);
      } else {
        // this.myTitle = this.fileid;
        // console.log("test: ", this.myTitle = this.fileid)
        this.set('myTitle', this.fileid);
      }

      let geturl = this.get('getUrl');

      if (this.divider === undefined) {
        this.set('divider', true);
      }

      geturl.perform();
    },

    didRender() {// let el = this.element.parentNode;
      // const styles = window.getComputedStyle(el);
      // const elWidth = el.clientWidth - parseFloat(styles.paddingLeft) 
      //                                 - parseFloat(styles.paddingRight);
      // console.dir(el);
      // console.log(elWidth);     
      // console.log(el.clientWidth);                               
      // if (this.width > elWidth) {
      //    let ratio = elWidth / this.width;
      //    this.width=elWidth;
      //    this.height = this.height * ratio;
      // }
    },

    getUrl: (0, _emberConcurrency.task)(function* () {
      //need check fileid first to decide what to do...
      let fileid = this.get('fileid');

      if (!fileid) {
        //throw new Error('fileid is not a string');
        fileid = '/assets/img_blank.png';
      } //no substr . throw error;


      if (fileid.substr(0, 7) === "adkf://") {
        let wip = fileid.substr(7);
        wip = wip.substring(wip.indexOf('/')); //need to plug in the bunny host...cdnHost

        this.set('url', _environment.default.cdnHost + wip);
      } else if (fileid.substr(0, 8) === "/assets/") {
        this.set('url', _environment.default.cdnHost + fileid);
      } else if (fileid.substr(0, 9) === "adkfid://") {
        try {
          let wip = fileid.substr(9);
          let response = yield this.CDN.getCdnUrl(wip); //filetype = response.data.filetype.split('/');

          this.set('url', response.data.url);
        } catch (e) {
          throw e;
        } // } else if (fileid.substr(0, 7) === "/assets/" || 

      } else if (fileid.substr(0, 7) === "http://" || //  fileid.substr(0, 7) === "http://" ||
      fileid.substr(0, 8) === "https://") {
        this.set('url', fileid);
      } else {
        try {
          //old id      
          let response = yield this.CDN.getCdnUrl(fileid);
          this.set('url', response.data.url);
        } catch (e) {
          throw e;
        }
      } //if
      //ext...


      let url = this.get('url');
      let ext = '.' + url.split('.').pop();

      if (_environment.default.imageExt.indexOf(ext) >= 0) {
        this.set('isImage', true);
      }

      if (_environment.default.videoExt.indexOf(ext) >= 0) {
        this.set('isVideo', true);
      } //need to detect file type base on file extention...
      //isImage
      //check video or image


      if (this.get('isImage')) {
        if (this.isLandscape && this.height && this.height !== '' && this.height !== 0) {
          this.set('url', this.get('url') + '?height=' + this.height);
        } else if (this.width && this.width !== '' && this.width !== 0) {
          this.set('url', this.get('url') + '?width=' + this.width);
        } else if (this.height && this.height !== '' && this.height !== 0) {
          this.set('url', this.get('url') + '?height=' + this.height);
        } else {
          this.set('url', this.get('url'));
        }

        yield this.preloadImage(this.get('url'));
      } else if (this.get('isVideo')) {
        this.set('url', this.get('url') + '#t=0.1'); //            this.set('videoType', response.data.filetype)
      } //else isVideo

    }),
    //task
    actions: {
      imageLoad(ev) {
        if (this.isImage) {
          let img = ev.target;
          let imgLandscape = false; //detect

          if (img.width >= img.height) {
            imgLandscape = true;
          }

          if (this.width == 0 || this.width == '') {
            this.width = img.width;
          } //hack for add in check parent node width


          let el = img.parentNode;

          if (el && el.clientWidth > 0) {
            const styles = window.getComputedStyle(el);
            const elWidth = el.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);

            if (this.width > elWidth) {
              this.width = elWidth;
            }
          } //end of hack for add in check parent node width


          if (this.height == 0 || this.height == '') {
            this.height = img.height;
          } // image is landscape and display size is landscape
          // image is not landscape (portrait) and display size is landscape
          // check the width first... 
          // then only check the height


          if (imgLandscape && this.isLandscape || !imgLandscape && this.isLandscape) {
            if (img.width > this.width) {
              img.width = this.width;
            }

            if (img.height > this.height) {
              img.height = this.height;
            }
          } else {
            // this should be the rest combination
            // image is landscape and display size is not landscape (portrait)
            // image is not landscape (portrait) and display size is not landscape (portrait) 
            // check the height first... 
            // then only check the width 
            if (img.height > this.height) {
              img.height = this.height;
            }

            if (img.width > this.width) {
              img.width = this.width;
            }
          } //else if check image landscape and display landscape 

        } //if isImage

      } //imageLoad


    } //action

  });

  _exports.default = _default;
});