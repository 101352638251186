define("adtikir3v1/pods/components/adkcard-ad-client-brief/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    actions: {
      doNotShow: function (theField) {
        this.get('proposal').set(theField, !this.get('proposal').get(theField)); //theFiletoggleProperty(theField);       
      },
      cancel: function () {},

      updateProposalDoc(url) {
        let proposal = this.get('proposal');
        let attachments = proposal.get('attachments');

        if (attachments == null || attachments == undefined) {
          attachments = Ember.A();
        }

        attachments.addObject(url);
        proposal.set('attachments', attachments); //      proposal.save();
      },

      saveRfp: function () {
        let component = this; // let rfp =         component.get('rfp');

        let proposal = this.get('proposal');
        let company = this.get('company');
        let adcampaign = this.get('adcampaign');
        let adcampaignDetail = this.get('adcampaignDetail');
        proposal.get('rfp').then(function (rfp) {
          if (rfp == null || rfp == undefined) {
            rfp = component.store.createRecord('rfp', {
              id: proposal.get('id').concat('rfp')
            });
            proposal.set('rfp', rfp); //isNewFlag = true;
          }

          component.set('rfp', rfp);
          rfp.set('campaignName', adcampaign.get('campaignName'));
          rfp.set('campaignNameLocal', adcampaign.get('campaignNameLocal'));
          rfp.set('advertiserCompanyName', company.get('compangName'));
          rfp.set('advertiserCompanyID', company.get('id'));

          if (proposal.get('clientRefIdEnabled')) {
            rfp.set('clientRefId', adcampaign.get('clientRefId'));
          } else {
            rfp.set('clientRefId', '');
          }

          if (proposal.get('campaignDatesEnabled')) {
            rfp.set('campaignStartDate', adcampaign.get('campaignStartDate'));
            rfp.set('campaignEndDate', adcampaign.get('campaignEndDate'));
            rfp.set('campaignProductLaunchDate', adcampaign.get('campaignProductLaunchDate'));
          } else {
            rfp.set('campaignStartDate', '');
            rfp.set('campaignEndDate', '');
            rfp.set('campaignProductLaunchDate', '');
          }

          if (proposal.get('campaignDescEnabled')) {
            rfp.set('campaignDesc', adcampaign.get('campaignDesc'));
          } else {
            rfp.set('campaignDesc', '');
          }

          if (proposal.get('marketChallengesEnabled')) {
            rfp.set('marketChallenges', adcampaign.get('marketChallenges'));
          } else {
            rfp.set('marketChallenges', '');
          }

          if (proposal.get('productServicesEnabled')) {
            rfp.set('productServices', adcampaign.get('productServices'));
          } else {
            rfp.set('productServices', '');
          }

          if (proposal.get('customerDescEnabled')) {
            rfp.set('customerDesc', adcampaignDetail.get('customerDesc'));
          } else {
            rfp.set('customerDesc', '');
          }

          if (proposal.get('campaignSuccessEnabled')) {
            rfp.set('campaignSuccess', adcampaignDetail.get('campaignSuccess'));
          } else {
            rfp.set('campaignSuccess', '');
          }

          if (proposal.get('attachments') && proposal.get('attachments').length > 0) {
            rfp.set('attachments', proposal.get('attachments').slice(0));
          }

          if (proposal.get('hasDirtyAttributes')) {
            proposal.save(); //save chnages on flag;
          }

          rfp.save().then(onEditOK, onEditFailed);
        }); //get rfp;

        function onEditOK() {
          let adkToast = component.get('adkToast'); // component.resetForm();

          adkToast.display("RFP successfully updated", "warning");
        }

        function onEditFailed() {
          let adkToast = component.get('adkToast');
          adkToast.display("RFP not saved", "error");
        }
      } //saveRfp

    } //action

  });

  _exports.default = _default;
});