define("adtikir3v1/pods/components/adkcard-addashboard-dates/component", ["exports", "adtikir3v1/utils/adk-date-time", "moment"], function (_exports, _adkDateTime, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: false,
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adPlayStartCenter: '',
    adPlayEndCenter: '',

    clearForm() {
      let adkSessionAccount = this.get('adkSessionAccount');
      let adPlayStartDate = adkSessionAccount.getDate(this.get('ad.adPlayStartDate'), true);
      let adPlayEndDate = adkSessionAccount.getDate(this.get('ad.adPlayEndDate'), true);
      this.set('adPlayStartCenter', (0, _moment.default)(adPlayStartDate));
      this.set('adPlayEndCenter', (0, _moment.default)(adPlayEndDate));
      this.set('adPlayStartDateLocal', (0, _moment.default)(adPlayStartDate));
      this.set('adPlayEndDateLocal', (0, _moment.default)(adPlayEndDate));
      this.set('adPlayStartDate', (0, _moment.default)(adPlayStartDate));
      this.set('adPlayEndDate', (0, _moment.default)(adPlayEndDate));
    },

    didReceiveAttrs() {
      this.clearForm();
    },

    actions: {
      cancelEdit() {
        this.clearForm();
        this.set('isEditing', false);
      },

      openEdit() {
        this.set('isEditing', true);
      },

      openDialogWithParent(event) {
        this.set('dialogOrigin', event.currentTarget);
        this.set('showDialogWithParent', true);
      },

      closeDialogWithParent(result) {
        this.set('result', result);
        this.set('showDialogWithParent', false);
      },

      saveAdInfo: function () {
        let ad = this.get('ad');
        let component = this; //  console.log('ad save kel: ', ad)

        ad.set('adPlayStartDate', new Date(this.get('adPlayStartDate')));
        ad.set('adPlayEndDate', new Date(this.get('adPlayEndDate')));
        return this.saveAction(true, ad).then(function () {
          component.clearForm();
          component.set('isEditing', false);
        });
      },

      updateDate(thefield) {
        let adkToast = this.get('adkToast');
        let newDate = arguments[1].moment;
        let adkSessionAccount = this.get('adkSessionAccount');
        let campaignStartDate = (0, _moment.default)(adkSessionAccount.getDate(this.get('adCampaign.campaignStartDate'), true));
        let campaignEndDate = (0, _moment.default)(adkSessionAccount.getDate(this.get('adCampaign.campaignEndDate'), true));
        let startDate = this.get('adPlayStartDateLocal'); //assume is a string ('YYYY-MM-dd')

        let endDate = this.get('adPlayEndDateLocal'); //assume is a string ('YYYY-MM-dd)

        let validStartDate = _adkDateTime.default.validDate(startDate);

        let validEndDate = _adkDateTime.default.validDate(endDate);

        if (!validStartDate) {
          startDate = '';
        }

        if (!validEndDate) {
          endDate = '';
        }

        let compareDate = _adkDateTime.default.compareDate(newDate, campaignStartDate);

        if (compareDate < 0) {
          this.set(thefield, campaignStartDate);
          adkToast.display("Date not in campaign range, will not set it", "warning", 2000);
          return false;
        }

        let compareDate1 = _adkDateTime.default.compareDate(newDate, campaignEndDate);

        if (compareDate1 > 0) {
          this.set(thefield, campaignStartDate);
          adkToast.display("Date not in campaign range, will not set it", "warning", 2000);
          return false;
        }

        if (thefield != 'adPlayEndDate') {
          //check newCenterDate > endDate
          if (endDate != '') {
            //compare with endDate...
            if (_adkDateTime.default.compareDate(newDate, endDate) > 0) {
              newDate = '';
              adkToast.display("Date not in range, will not set it", "error", 2000);
            }
          } //endDate != ''

        }

        if (thefield != 'adPlayStartDate') {
          if (startDate != '') {
            //compare with startDate
            if (_adkDateTime.default.compareDate(newDate, startDate) < 0) {
              newDate = '';
              adkToast.display("Date not in range, will not set it", "error", 2000);
            }
          } //startdate != ''

        }

        if (newDate != '') {
          this.set(thefield + 'Local', (0, _moment.default)(newDate)); // console.log("theField: ", thefield)

          let theActualField = thefield;
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr'); // console.log("newDateStr: ", newDateStr)

          this.set(theActualField, (0, _moment.default)(new Date(newDateStr))); // console.log("result: ", this.set(theActualField, moment(new Date(newDateStr))))

          adkToast.display("Date successfully set", "warning", 2000);
        }
      },

      //end of update
      checkCenter(thefield, centerField) {
        let adkSessionAccount = this.get('adkSessionAccount');
        let campaignStartDate = (0, _moment.default)(adkSessionAccount.getDate(this.get('adCampaign.campaignStartDate'), true));
        let campaignEndDate = (0, _moment.default)(adkSessionAccount.getDate(this.get('adCampaign.campaignEndDate'), true)); // console.log('campaignEndDate: ', campaignEndDate)

        let newCenterDate = arguments[2].moment;
        let adPlayStartDate = this.get('adPlayStartDateLocal'); // console.log('adPlayStartDate: ', adPlayStartDate)

        let adPlayEndDate = this.get('adPlayEndDateLocal'); // console.log('adPlayEndDate: ', adPlayEndDate)

        let validAdStartDate = _adkDateTime.default.validDate(adPlayStartDate);

        let validAdEndDate = _adkDateTime.default.validDate(adPlayEndDate);

        if (!validAdStartDate) {
          adPlayStartDate = '';
        }

        if (!validAdEndDate) {
          adPlayEndDate = '';
        } //adPlayStartDate  Check here


        if (thefield != 'adPlayEndDate') {
          //thefield is start date..
          let endDate = campaignEndDate;

          if (adPlayEndDate != '') {
            endDate = adPlayEndDate;
          } //compare with endDate...


          if (_adkDateTime.default.compareYearMonth(newCenterDate, endDate) > 0) {
            newCenterDate = endDate;
          }

          if (_adkDateTime.default.compareYearMonth(newCenterDate, campaignStartDate) < 0) {
            newCenterDate = campaignStartDate;
          }
        } //adPlayEndDate


        if (thefield != 'adPlayStartDate') {
          let startDate = campaignStartDate;

          if (adPlayStartDate != '') {
            startDate = adPlayStartDate;
          } //compare with startDate


          if (_adkDateTime.default.compareYearMonth(newCenterDate, startDate) < 0) {
            newCenterDate = startDate;
          }

          if (_adkDateTime.default.compareYearMonth(newCenterDate, campaignEndDate) > 0) {
            newCenterDate = campaignEndDate;
          }
        }

        this.set(centerField, newCenterDate);
      } //checkCenter()


    }
  });

  _exports.default = _default;
});