define("adtikir3v1/pods/campaign/details/vendor/agency/proposal/controller", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    refresh: false,
    aProposalResponse: null,
    userAProposalResponseRating: null,
    rateDisplay: false,
    starWidth: 12,
    starHeight: 12,
    showAwardFlag: false,
    showAwardEditFlag: false,
    awardExpiredDayCount: 3,
    awardedNote: '',
    showCancelAwardAction: false,
    showConfirmProjectDialogFlag: false,
    showAwardButtonFlag: Ember.computed('adCampaign.awardProposalResponseFg', 'aProposalResponse', 'aProposalResponse.status', 'showAwardEditFlag', function () {
      let fg = false;
      let adCampaign = this.get('adCampaign');
      let aProposalResponse = this.get('aProposalResponse');
      let showAwardEditFlag = this.get('showAwardEditFlag');

      if (adCampaign.get('awardProposalResponseFg') === 'no' || adCampaign.get('awardProposalResponseFg') === '') {
        if (aProposalResponse && aProposalResponse.get('status') === "vSubmitted") {
          //need check date as well for expiry
          let responseExpiredDate = (0, _moment.default)(aProposalResponse.get('responseExpiredDate'));

          if (responseExpiredDate.diff(this.adkSessionAccount.get('myNow')) > 0) {
            fg = true;
          } //if

        } //if 

      } //if


      if (showAwardEditFlag) {
        fg = false;
      }

      return fg;
    }),
    showAwardedInfoFg: Ember.computed('aProposalResponse.status', function () {
      let aProposalResponse = this.get('aProposalResponse');
      let flaseArray = ['new', 'vSubmitted', 'expired'];

      if (flaseArray.indexOf(aProposalResponse.status) >= 0) {
        return false;
      }

      return true;
    }),
    showAcceptInfoFg: Ember.computed('aProposalResponse.status', function () {
      let aProposalResponse = this.get('aProposalResponse');
      let flaseArray = ['vAccept', 'vReject', 'vAcceptExpired', 'project', 'projectReject'];

      if (flaseArray.indexOf(aProposalResponse.status) >= 0) {
        return true;
      }

      return false;
    }),
    showCreateRatingButtonFlag: Ember.computed("rateDisplay", "userAProposalResponseRating", 'aProposalResponse', 'aProposalResponse.status', function () {
      let fg = false;
      let userAProposalResponseRating = this.get('userAProposalResponseRating');
      let aProposalResponse = this.get('aProposalResponse');
      let rateDisplay = this.get('rateDisplay');

      if (!userAProposalResponseRating) {
        if (aProposalResponse.status === "vSubmitted") {
          if (rateDisplay) {
            fg = true;
          }
        }
      }

      return fg;
    }),
    //expiration date for the award... vendor needed to reponse before this date, 
    //otherwise, statuse will turn to awareExpired
    awardExpiredDateComputed: Ember.computed('awardExpiredDayCount', function () {
      let adkSessionAccount = this.get('adkSessionAccount');
      let awardExpiredDayCount = this.get('awardExpiredDayCount');
      let dateObj = new Date(adkSessionAccount.get('myNow'));
      dateObj.setDate(dateObj.getDate() + awardExpiredDayCount + 1);
      dateObj = (0, _moment.default)(dateObj);
      let newDateStr = dateObj.format('YYYY-MM-DD') + 'T00:00:00.000' + adkSessionAccount.get('utcOffsetStr');
      let rtnDate = new Date(newDateStr);
      return rtnDate;
    }),
    loadAProposalResponsesTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let adCampaingRfp = this.get('adCampaignRfp');
      let result = yield this.store.query('a-proposal-response', {
        adCampaignRfp: adCampaingRfp.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    loadAProposalResponsesRatingsTask: (0, _emberConcurrency.task)(function* (page = 0, pagingObj) {
      let adCampaignResponse = this.get('aProposalResponse');
      let result = yield this.store.query('a-proposal-response-rating', {
        aProposalResponse: adCampaignResponse.id,
        // byUser: false,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, 'result', result);
      Ember.set(pagingObj, 'page', page);
      return true;
    }),
    loadUserAProposalResponseRatingTask: (0, _emberConcurrency.task)(function* () {
      let adCampaignResponse = this.get('aProposalResponse');
      let result = yield this.store.query('a-proposal-response-rating', {
        aProposalResponse: adCampaignResponse.id,
        byUser: true,
        page: 0,
        pageSize: 1
      });
      Ember.set(this, 'userAProposalResponseRating', result.get('firstObject'));

      if (this.userAProposalResponseRating) {
        this.set('rateDisplay', true);
      }
    }),

    cleanAProposalResponse(controller = null) {
      if (!controller) {
        controller = this;
      }

      controller.set('rateDisplay', true);
      controller.set('aProposalResponseRatingList', {
        page: 0,
        pageSize: 20,
        action: this.get("loadAProposalResponsesRatingsTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      controller.set('aProposalResponse', null);
      controller.set('userAProposalResponseRating', null);
      controller.set('showAwardFlag', false);
      controller.set('showCancelAwardAction', false);
      controller.set('awardCancelReason', '');
      controller.set('finalNote', '');
      controller.set('showConfirmProjectDialogFlag', false);
      controller.cleanAwardEdit(controller);
    },

    cleanAwardEdit(controller = null) {
      //clean the award form seciton
      //will be called by cleanAProposalUp..
      if (!controller) {
        controller = this;
      }

      controller.set('showAwardEditFlag', false);
      controller.set('awardExpiredDayCount', 3);
      controller.set('awardedNote', '');
    },

    cleanUp: function () {
      //this.set('createRatingOn', false);
      this.set('aProposalResponseList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAProposalResponsesTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.cleanAProposalResponse(this);
    },

    init() {
      this._super(...arguments);

      this.cleanUp();
    },

    //init
    saveAwardEditActionTask: (0, _emberConcurrency.task)(function* () {
      let aProposalResponse = this.get('aProposalResponse');
      aProposalResponse.set('action', "awardAction");
      aProposalResponse.set('awardedNote', this.get('awardedNote'));
      aProposalResponse.set('awardedDate', this.get('adkSessionAccount').get('myNow').toDate());
      aProposalResponse.set('awardExpiredDate', this.get('awardExpiredDateComputed')); //need popup for confirmation....

      yield aProposalResponse.save();
      this.cleanAwardEdit(); //reload adCampaign for 

      yield this.get('adCampaign').reload();
      this.get('adkToast').display('Proposal awarded', 'warning', 1500);
    }),
    saveCancelAwardActionTask: (0, _emberConcurrency.task)(function* () {
      let aProposalResponse = this.get('aProposalResponse');
      aProposalResponse.set('action', "awardCancelAction");
      aProposalResponse.set('awardResponseMessage', this.get('awardCancelMessage')); //need popup for confirmation....

      yield aProposalResponse.save();
      this.set('showAwardEditFlag', true);
      this.set('awardCancelReason', ''); //reload adCampaign for 

      yield this.get('adCampaign').reload();
      this.get('adkToast').display('Proposal canceled', 'warning', 1500);
    }),
    saveResponseActionTask: (0, _emberConcurrency.task)(function* (action) {
      //action should be projectAcceptAction or projectRejectAction
      let adkToast = this.get('adkToast');
      let aProposalResponse = this.get('aProposalResponse');

      if (this.get('finalNote').length === 0) {
        adkToast.display("Pleae enter some values", "error");
        return;
      }

      aProposalResponse.set('action', action);
      aProposalResponse.set('finalNote', this.get('finalNote'));
      yield aProposalResponse.save(); //reload adCampaign for 

      yield this.get('adCampaign').reload();
      this.set('showConfirmProjectDialogFlag', false);
      this.set('finalNote', '');
      this.get('adkToast').display('Proposal Updated', 'warning', 1500);
    }),
    actions: {
      cancelCancelAwardAction() {
        this.set('showAwardEditFlag', false);
        this.set('awardCancelReason', '');
      },

      showConfirmProjectDialog(action) {
        this.set('showConfirmProjectDialogFlag', true);
        this.set('projectAction', action);
      },

      cancelConfirmProjectAction() {
        this.set('showConfirmProjectDialogFlag', false);
        this.set('projectAction', '');
        this.set('finalNote', '');
      },

      showEditAwardProposal() {
        this.set('showAwardEditFlag', true);
      },

      showCancelAwardAction() {
        this.set('showCancelAwardAction', true); //need set this to cancel
      },

      cancelAwardEditAction() {
        this.cleanAwardEdit();
      },

      setCreateRatingOn() {
        //this.set('createRatingOn', true);
        this.set('userAProposalResponseRating', this.store.createRecord('a-proposal-response-rating'));
        this.set('rateDisplay', false);
      },

      setModifyRatingOn() {
        this.set('rateDisplay', false);
      },

      cancelRating() {
        let userAProposalResponseRating = this.get('userAProposalResponseRating');

        if (userAProposalResponseRating.isNew) {
          this.set('userAProposalResponseRating', null);
        } else {
          if (userAProposalResponseRating.get('hasDirtyAttributes')) {
            userAProposalResponseRating.rollbackAttributes();
          }
        } //this.set('createRatingOn', false);


        this.set('rateDisplay', true);
      },

      saveRating() {
        let adkToast = this.get('adkToast');
        let saveMode;
        let userAProposalResponseRating = this.get('userAProposalResponseRating');
        let changeAttributes = userAProposalResponseRating.changedAttributes();

        if (Object.keys(changeAttributes).length === 0) {
          adkToast.display("Please enter some values", "error");
          return;
        }

        if (userAProposalResponseRating.isNew) {
          saveMode = 'new';
          userAProposalResponseRating.set('aProposalResponse', this.get('aProposalResponse'));
        }

        let controller = this;
        userAProposalResponseRating.save().then(function () {
          if (saveMode === 'new') {
            adkToast.display("Your new rating has been created", "warning");
          } else {
            adkToast.display("Your rating get updated", "warning");
          } //controller.set('createRatingOn', false);


          controller.set('rateDisplay', true);
          Ember.set(controller.get('aProposalResponseRatingList'), 'refresh', true);
          Ember.set(controller, "refresh", userAProposalResponseRating.id);
        });
      },

      setAProposalResponse(aProposalResponse) {
        this.cleanAProposalResponse(this);
        aProposalResponse.reload();
        this.set('aProposalResponse', aProposalResponse);
        let pagingObj = this.get('aProposalResponseRatingList');

        if (!this.rateDisplay) {
          this.actions.cancelRating.call(this);
        } //this.set('aProposalResponseRatingListRefresh', true);


        Ember.set(pagingObj, "refresh", true);
        Ember.set(this, "refresh", aProposalResponse.id); //set(pagingObj, "actionInstance", pagingObj.action.perform(pagingObj.page, pagingObj));

        this.set('loadUserAProposalResponseRatingTaskInstance', this.loadUserAProposalResponseRatingTask.perform());
      },

      updateAProposalResponse(componentValues) {
        this.aProposalResponse.setProperties(componentValues);
        this.aProposalResponse.save();
      }

    } //actions

  });

  _exports.default = _default;
});