define("adtikir3v1/helpers/split", ["exports", "@abcum/ember-helpers/helpers/split"], function (_exports, _split) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _split.default;
    }
  });
  Object.defineProperty(_exports, "split", {
    enumerable: true,
    get: function () {
      return _split.split;
    }
  });
});