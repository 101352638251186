define("adtikir3v1/helpers/ends-with", ["exports", "@abcum/ember-helpers/helpers/ends-with"], function (_exports, _endsWith) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _endsWith.default;
    }
  });
  Object.defineProperty(_exports, "endsWith", {
    enumerable: true,
    get: function () {
      return _endsWith.endsWith;
    }
  });
});