define("adtikir3v1/helpers/history-forward", ["exports", "@abcum/ember-helpers/helpers/history-forward"], function (_exports, _historyForward) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _historyForward.default;
    }
  });
  Object.defineProperty(_exports, "historyForward", {
    enumerable: true,
    get: function () {
      return _historyForward.historyForward;
    }
  });
});