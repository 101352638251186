define("adtikir3v1/pods/campaign/details/ads/ad/vendors/agency/index/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    router: Ember.inject.service(),

    beforeModel() {
      this.get('router').transitionTo("campaign.details.ads.ad.vendors.agency.rfpmanagement");
    }

  });

  _exports.default = _default;
});