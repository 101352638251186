define("adtikir3v1/pods/campaign/details/vendor/media/accepted/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {
  //   A
  // } from '@ember/array';
  var _default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    adkSessionAccount: Ember.inject.service(),
    loadModelTask: (0, _emberConcurrency.task)(function* () {
      let adCampaign = yield this.modelFor('campaign.details').adCampaign;
      let adCampaignDetail = yield adCampaign.get('adCampaignDetail');
      let adCampaignMediaRfp = yield this.store.query('ad-campaign-media-rfp', {
        adCampaign: adCampaign.id
      });
      adCampaignMediaRfp = yield adCampaignMediaRfp.get('firstObject');
      return {
        adCampaign: adCampaign,
        adCampaignDetail: adCampaignDetail,
        adCampaignMediaRfp: adCampaignMediaRfp
      };
    }),

    model() {
      return this.get('loadModelTask').perform();
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('adCampaignMediaRfp', model.adCampaignMediaRfp);
      controller.cleanUp();

      if (controller.aMediaProposalResponseId) {
        controller.runLoadAMediaProposalResponseTask.perform(controller.aMediaProposalResponseId);
      }
    },
    actions: {// willTransition() {
      //   this.controller.cleanUp();
      //   //this.controller.set('refresh', false);
      // }
    }
  });

  _exports.default = _default;
});