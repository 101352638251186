define("adtikir3v1/pods/campaign/details/ads/workflow/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    loadModelTask: (0, _emberConcurrency.task)(function* () {
      try {
        let mediaTypes = yield this.store.findAll('mediaType');
        let adCampaign = yield this.modelFor('campaign.details').adCampaign;
        let adCampaignDetail = yield adCampaign.get('adCampaignDetail');
        let acWorkFlows = yield this.modelFor('campaign.details').acWorkFlows;
        let ads = yield this.store.query('ad', {
          filter: {
            query: adCampaign.id
          }
        });
        return {
          ads: ads,
          mediaTypes: mediaTypes,
          adCampaign: adCampaign,
          adCampaignDetail: adCampaignDetail,
          acWorkFlows: acWorkFlows
        };
      } catch (e) {
        this.intermediateTransitionTo('error', e);
        throw e;
      }
    }).restartable(),
    model: function () {
      try {
        return this.loadModelTask.perform();
      } catch (e) {
        throw e;
      }
    },
    //model
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('mediaTypes', model.mediaTypes);
      controller.set('ads', model.ads);
      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
      controller.set('acWorkFlows', model.acWorkFlows);
    } //setupController

  });

  _exports.default = _default;
});