define("adtikir3v1/pods/media/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nfeFe8wy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex layout-row\"],[8],[0,\"\\n\\n     \"],[7,\"div\",true],[10,\"class\",\"flex-40\"],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[9],[0,\"\\n     \"],[9],[0,\"\\n\\n     \"],[7,\"div\",true],[10,\"class\",\"flex  layout-align-center-center\"],[8],[0,\"\\n\"],[0,\"          \"],[7,\"lottie-player\",true],[10,\"src\",\"https://assets3.lottiefiles.com/packages/lf20_r5BMbX.json\"],[10,\"background\",\"transparent\"],[10,\"speed\",\"1\"],[10,\"style\",\"width: 300px; height: 300px;\"],[10,\"loop\",\"\"],[10,\"autoplay\",\"\"],[8],[9],[0,\"\\n     \"],[9],[0,\"\\n\\n     \"],[7,\"div\",true],[10,\"class\",\"flex-30\"],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[9],[0,\"\\n     \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/media/loading/template.hbs"
    }
  });

  _exports.default = _default;
});