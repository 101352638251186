define("adtikir3v1/helpers/ucwords", ["exports", "@abcum/ember-helpers/helpers/ucwords"], function (_exports, _ucwords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ucwords.default;
    }
  });
  Object.defineProperty(_exports, "ucwords", {
    enumerable: true,
    get: function () {
      return _ucwords.ucwords;
    }
  });
});