define("adtikir3v1/helpers/reload", ["exports", "@abcum/ember-helpers/helpers/reload"], function (_exports, _reload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _reload.default;
    }
  });
  Object.defineProperty(_exports, "reload", {
    enumerable: true,
    get: function () {
      return _reload.reload;
    }
  });
});