define("adtikir3v1/helpers/notify", ["exports", "@abcum/ember-helpers/helpers/notify"], function (_exports, _notify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _notify.default;
    }
  });
  Object.defineProperty(_exports, "notify", {
    enumerable: true,
    get: function () {
      return _notify.notify;
    }
  });
});