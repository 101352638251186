define("adtikir3v1/pods/components/adk-models-table-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function () {
      let record = this.get('record');
      let isDisabled = this.get('isDisabled');
      this.set('isDisabled', record.isDisabled || isDisabled);
    },
    actions: {
      clickOnRow() {
        let record = this.get('record');
        this.set('record.isSelected', !record.isSelected);
      } //clickOnRow


    } //actions

  });

  _exports.default = _default;
});