define("adtikir3v1/pods/campaign/details/vendor/media/rfpmanagement/controller", ["exports", "ember-concurrency", "moment", "adtikir3v1/utils/adk-date-time"], function (_exports, _emberConcurrency, _moment, _adkDateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    adkToast: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.cleanUp();
    },

    //init
    calDateDiff(startDate, endDate, percentage) {
      let totalDayDiff = endDate.getDate() - startDate.getDate();
      let dayDiff = 0;

      if (totalDayDiff > 1) {
        dayDiff = Math.floor(totalDayDiff * percentage / 100);
      }

      let returnDate = new Date();
      returnDate.setDate(startDate.getDate() + dayDiff);
      return (0, _moment.default)(returnDate);
    },

    clearForm() {
      let adkSessionAccount = this.get('adkSessionAccount'); //let adCampaign = this.get('adCampaign');

      this.set('newFormName', '');
      this.set('newFormNote', '');
      this.set('newFormMediaTypes', []);
      this.set('newFormProposalDeadline', adkSessionAccount.get('myToday')); // if (adCampaign) {
      //   alert(1);
      //   this.set('newFormProposalDeadline',
      //     this.calDateDiff(adCampaign.campaignStartDate,
      //       adCampaign.campaignEndDate, 10));
      // } //if

      this.set('createNewFlag', false);
      this.set('newFormProposalDeadlineCenter', this.get('newFormProposalDeadline'));
      this.set('newFormProposalDeadlineLocal', this.get('newFormProposalDeadline'));
    },

    //clearForm
    cleanUp: function () {
      //this.set('createRatingOn', false);
      this.set('adCampaignMediaRfpBodyList', {
        page: 0,
        pageSize: 6,
        action: this.get("loadAdCampaignMediaRfpBodyListTask"),
        result: null,
        refresh: false,
        type: "emberdata"
      });
      this.clearForm();
      this.set('newAdcampaignMediaRfpBodyFlag', false);
      this.set('reviewAdCampaignMediaRfpBodyFlag', false);
      this.set('editAdCampaignMediaRfpBodyFlag', false); //this.cleanAProposalResponse(this);
    },
    //cleanUp
    loadAdCampaignMediaRfpBodyListTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adCampaignMediaRfpId = this.get('adCampaignMediaRfp').id;
      let adCampaignMediaRfpBodies = yield this.store.query('ad-campaign-media-rfp-body', {
        adCampaignMediaRfp: adCampaignMediaRfpId,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", adCampaignMediaRfpBodies); //return attachments;
    }),
    //loadAdCampaignMediaRfpBodyListTask
    saveAdCampaignMediaRfpBodyTask: (0, _emberConcurrency.task)(function* (mode) {
      let adkToast = this.get('adkToast');
      let errorFg = false;

      if (this.get('newFormName').trim().length == 0) {
        adkToast.display('Name must be filled', 'error', 2000);
        errorFg = true;
      }

      if (this.get('newFormMediaTypes').length == 0) {
        adkToast.display('At least one media type  must be selected', 'error', 2000);
        errorFg = true;
      }

      if (errorFg) {
        return false;
      }

      let adCampaignMediaRfpBody;
      let message;

      if (mode === 'edit') {
        message = 'Media RFP body updated';
        adCampaignMediaRfpBody = this.get('adCampaignMediaRfpBody');
      } else {
        message = 'New media RFP body created';
        adCampaignMediaRfpBody = this.store.createRecord('ad-campaign-media-rfp-body');
      }

      adCampaignMediaRfpBody.set('name', this.get('newFormName'));
      adCampaignMediaRfpBody.set('note', this.get('newFormNote'));
      adCampaignMediaRfpBody.set('mediaTypes', this.get('newFormMediaTypes'));
      adCampaignMediaRfpBody.set('adCampaignMediaRfp', this.get('adCampaignMediaRfp'));
      adCampaignMediaRfpBody.set('proposalDeadline', this.get('newFormProposalDeadline').toDate());
      yield adCampaignMediaRfpBody.save();
      adkToast.display(message, 'warning', 2000);
      this.set('newAdcampaignMediaRfpBodyFlag', false);

      if (mode === 'edit') {
        this.set('editAdCampaignMediaRfpBodyFlag', false);
        this.set('reviewAdCampaignMediaRfpBodyFlag', true);
        this.clearForm();
      } else {
        //new...
        this.set('createNewFlag', true);
        this.set('adCampaignMediaRfpBody', adCampaignMediaRfpBody);
        this.set('editAdCampaignMediaRfpBodyFlag', true);
      }

      Ember.set(this.get('adCampaignMediaRfpBodyList'), 'refresh', true);
    }),
    //saveAdCampaignMediaRfpBodyTask
    loadMediaRfpBodyAttachmentTask: (0, _emberConcurrency.task)(function* (page, pagingObj) {
      let adCampaignMediaRfpBody = this.get('adCampaignMediaRfpBody');
      let attachments = yield this.store.query('media-rfp-body-attachment', {
        adCampaignMediaRfpBody: adCampaignMediaRfpBody.id,
        page: page,
        pageSize: pagingObj.pageSize
      });
      Ember.set(pagingObj, "result", attachments); //return attachments;
    }).drop(),
    //loadMediaBodyAttachmentTask 
    saveMediaRfpBodyAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentObj) {
      let adCampaignMediaRfpBody = this.get('adCampaignMediaRfpBody');
      let attachment = this.store.createRecord('media-rfp-body-attachment', {
        attachFile: attachmentObj.attachFile,
        description: attachmentObj.description,
        adCampaignMediaRfpBody: adCampaignMediaRfpBody
      });

      try {
        attachment = yield attachment.save();
        return attachment;
      } catch (e) {
        throw e;
      }
    }).drop(),
    //saveMediaBodyAttachmentTask
    actions: {
      createNewRfp: function () {
        if (this.newAdcampaignMediaRfpBodyFlag || this.editAdCampaignMediaRfpBodyFlag) {
          alert('some form already opened, cancel or save it first');
          return;
        }

        if (this.reviewAdCampaignMediaRfpBodyFlag) {
          this.set('reviewAdCampaignMediaRfpBodyFlag', false);
          this.set('adCampaignMediaRfpBody', null);
        }

        this.set('newAdcampaignMediaRfpBodyFlag', true);
        this.clearForm();
        return true;
      },
      cancelNewAdCampaignMediaRdpBodyAction: function () {
        if (this.editAdCampaignMediaRfpBodyFlag) {
          this.set('editAdCampaignMediaRfpBodyFlag', false);
          this.set('reviewAdCampaignMediaRfpBodyFlag', true);
        } else {
          this.set('newAdcampaignMediaRfpBodyFlag', false);
        }

        this.clearForm();
        return true;
      },

      setAdCampaignMediaRfpBody(adCampaignMediaRfpBody) {
        if (this.newAdcampaignMediaRfpBodyFlag || this.editAdCampaignMediaRfpBodyFlag) {
          alert('some form already opened, cancel or save it first');
          return;
        }

        this.set('adCampaignMediaRfpBody', adCampaignMediaRfpBody);
        this.set('reviewAdCampaignMediaRfpBodyFlag', true);
      },

      setEditAdCampaignMediaRfpBodyAction() {
        let adCampaignMediaRfpBody = this.get('adCampaignMediaRfpBody');
        this.set('newFormName', adCampaignMediaRfpBody.name);
        this.set('newFormNote', adCampaignMediaRfpBody.note);
        this.set('newFormMediaTypes', Array.from(adCampaignMediaRfpBody.mediaTypes.toArray()));
        this.set('newFormProposalDeadlineLocal', (0, _moment.default)(adCampaignMediaRfpBody.proposalDeadline));
        this.set('newFormProposalDeadline', (0, _moment.default)(adCampaignMediaRfpBody.proposalDeadline));
        this.set('newFormProposalDeadlineCenter', (0, _moment.default)(adCampaignMediaRfpBody.proposalDeadline));
        this.set('editAdCampaignMediaRfpBodyFlag', true);
        this.set('reviewAdCampaignMediaRfpBodyFlag', false);
      },

      updateDate(selected) {
        let adkToast = this.get('adkToast');
        let newDate = (0, _moment.default)(selected.date);
        let startDate = (0, _moment.default)(this.get('adCampaign').campaignStartDate); //assume is a moment obj

        let endDate = (0, _moment.default)(this.get('adCampaign').campaignEndDate); //assume is a moment obj
        //check newCenterDate > endDate
        //compare with endDate...

        if (_adkDateTime.default.compareDate(newDate, endDate) > 0) {
          newDate = '';
          adkToast.display("Date should not be later than the end date, it will not be set", "error", 2000);
          return false;
        } //cannot put else.. cause productLaunchDate need check both
        //compare with startDate


        if (_adkDateTime.default.compareDate(newDate, startDate) < 0) {
          newDate = '';
          adkToast.display("Date should not be earlier than the start date, it will not be set", "error", 2000);
          return false;
        }

        if (newDate) {
          this.set('newFormProposalDeadlineLocal', newDate);
          let newDateStr = newDate.format('YYYY-MM-DD') + 'T00:00:00' + this.get('adkSessionAccount').get('utcOffsetStr');
          this.set('newFormProposalDeadline', (0, _moment.default)(new Date(newDateStr)));
        }
      },

      // updateDate()
      checkCenter(newCenterDate) {
        newCenterDate = (0, _moment.default)(newCenterDate.date);
        let startDate = (0, _moment.default)(this.get('adCampaign').campaignStartDate); //assume is a moment obj

        let endDate = (0, _moment.default)(this.get('adCampaign').campaignEndDate); //assume is a moment obj
        // let productLaunchDate = this.get('productLaunchDate');
        //check newCenterDate > endDate
        //compare with endDate...

        if (_adkDateTime.default.compareYearMonth(newCenterDate, endDate) >= 0) {
          newCenterDate = endDate;
        } //compare with startDate


        if (_adkDateTime.default.compareYearMonth(newCenterDate, startDate) <= 0) {
          newCenterDate = startDate;
        }

        this.set("newFormProposalDeadlineCenter", newCenterDate);
      } //checkCenter()    


    } //actions

  });

  _exports.default = _default;
});