define("adtikir3v1/helpers/ix", ["exports", "@abcum/ember-helpers/helpers/ix"], function (_exports, _ix) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ix.default;
    }
  });
  Object.defineProperty(_exports, "ix", {
    enumerable: true,
    get: function () {
      return _ix.ix;
    }
  });
});