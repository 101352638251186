define("adtikir3v1/models/a-media-proposal-response-rating", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    overAllRating: attr('number', {
      defaultValue: 0
    }),
    backgroundRating: attr('number', {
      defaultValue: 0
    }),
    objectivesRating: attr('number', {
      defaultValue: 0
    }),
    implementationRating: attr('number', {
      defaultValue: 0
    }),
    reportingRating: attr('number', {
      defaultValue: 0
    }),
    costRating: attr('number', {
      defaultValue: 0
    }),
    teamRating: attr('number', {
      defaultValue: 0
    }),
    proComment: attr('string'),
    conComment: attr('string'),
    aMediaProposalResponse: belongsTo('a-media-proposal-response'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });

  _exports.default = _default;
});