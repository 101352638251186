define("adtikir3v1/helpers/rsvp-hash", ["exports", "@abcum/ember-helpers/helpers/rsvp-hash"], function (_exports, _rsvpHash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rsvpHash.default;
    }
  });
  Object.defineProperty(_exports, "rsvpHash", {
    enumerable: true,
    get: function () {
      return _rsvpHash.rsvpHash;
    }
  });
});