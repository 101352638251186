define("adtikir3v1/helpers/pattern-integer", ["exports", "@abcum/ember-helpers/helpers/pattern-integer"], function (_exports, _patternInteger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _patternInteger.default;
    }
  });
  Object.defineProperty(_exports, "patternInteger", {
    enumerable: true,
    get: function () {
      return _patternInteger.patternInteger;
    }
  });
});