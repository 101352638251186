define("adtikir3v1/models/ad-campaign-rfp-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    //rfp session  
    campaignDescEnabled: attr('boolean', {
      defaultValue: true
    }),
    marketChallengesEnabled: attr('boolean', {
      defaultValue: true
    }),
    productServicesEnabled: attr('boolean', {
      defaultValue: true
    }),
    customerDescEnabled: attr('boolean', {
      defaultValue: true
    }),
    campaignSuccessEnabled: attr('boolean', {
      defaultValue: true
    }),
    clientRefIdEnabled: attr('boolean', {
      defaultValue: true
    }),
    campaignDatesEnabled: attr('boolean', {
      defaultValue: true
    }),
    adCampaignRfpIsCreated: attr('boolean', {
      defaultValue: false
    }),
    adCampaignRfpId: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    // no adCampaignRfp reference in ember side... 
    // maybe in future need it
    //************** 
    // adCampaignRfp: belongsTo('ad-campaign-rfp', {
    //   inverse: null
    // }),
    //will be use adcampaign id + _rfp_setting as id
    adCampaign: belongsTo('ad-campaign') //disable, as adCampaignProposalRequestList need to use paging
    //************** 
    //adCampaignProposalRequestLists: hasMany('ad-campaign-proposal-request-list'),

  });

  _exports.default = _default;
});