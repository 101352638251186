define("adtikir3v1/helpers/equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function equal(params
  /*, hash*/
  ) {
    var result = params[0] == params[1];
    return result;
  });

  _exports.default = _default;
});