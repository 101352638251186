define("adtikir3v1/models/ad-rfp", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default; //import { attr } from 'ember-decorators/data';

  var _default = Model.extend({
    adName: attr('string', {
      defaultValue: ''
    }),
    adNameLocal: attr('string', {
      defaultValue: ''
    }),
    description: attr('string', {
      defaultValue: ''
    }),
    adPlayStartDate: attr('string'),
    adPlayEndDate: attr('string'),
    adMediaFormatDetails: attr('string', {
      defaultValue: ''
    }),
    mediaformat: attr('string', {
      defaultValue: ''
    }),
    mediatype: attr('string', {
      defaultValue: ''
    }),
    adPic: attr('string', {
      defaultValue: ''
    }),
    extraNote: attr('string', {
      defaultValue: ''
    }),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    advertiserCompanyId: attr('string', {
      defaultValue: ''
    }),
    advertiserCompanyName: attr('string', {
      defaultValue: ''
    }),
    targetingMale: attr('string', {
      defaultValue: ''
    }),
    targetingFemale: attr('string', {
      defaultValue: ''
    }),
    targetingMarriedStatus: attr('string', {
      defaultValue: ''
    }),
    targetingHasChildren: attr('string', {
      defaultValue: ''
    }),
    targetingAnnualIncomeLevel: attr('string', {
      defaultValue: ''
    }),
    targetingIndustry: attr('string', {
      defaultValue: ''
    }),
    targetingLanguage: attr('string', {
      defaultValue: ''
    }),
    targetingLocation: attr('string', {
      defaultValue: ''
    }),
    currencyAccept: attr('string', {
      defaultValue: ''
    }),
    campaignDesc: attr('string', {
      defaultValue: ''
    }),
    productServices: attr('string', {
      defaultValue: ''
    }),
    customerDesc: attr('string', {
      defaultValue: ''
    }),
    marketChallenges: attr('string', {
      defaultValue: ''
    }),
    updatedByFirstName: attr('string'),
    updatedByLastName: attr('string'),
    updatedByRole: attr('string'),
    //to be do
    agencyMode: attr('boolean'),
    company: belongsTo('company')
  });

  _exports.default = _default;
});