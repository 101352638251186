define("adtikir3v1/pods/components/adk-model-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "71BxVqzW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex adkcardTable\"],[8],[0,\"\\n  \"],[5,\"models-table\",[],[[\"@data\",\"@columns\",\"@showGlobalFilter\",\"@useFilteringByColumns\",\"@showComponentFooter\",\"@showColumnsDropdown\",\"@columnComponents\"],[[23,0,[\"data\"]],[23,0,[\"columns\"]],[23,0,[\"showGlobalFilter\"]],[23,0,[\"useFilteringByColumns\"]],[23,0,[\"showComponentFooter\"]],[23,0,[\"showColumnsDropdown\"]],[28,\"hash\",null,[[\"edit-row\",\"edit-color\",\"delete-row\"],[[28,\"component\",[\"models-table/cell-edit-toggle\"],[[\"saveRowAction\",\"cancelRowAction\"],[[28,\"action\",[[23,0,[]],\"onSaveRow\"],null],[28,\"action\",[[23,0,[]],\"onCancelRow\"],null]]]],[28,\"component\",[\"adk-models-table-display-color\"],[[\"onColorChanged\"],[[28,\"action\",[[23,0,[]],\"onColorChanged\"],null]]]],[28,\"component\",[\"adk-delete-row\"],[[\"onClick\"],[[28,\"action\",[[23,0,[]],\"deleteRow\"],null]]]]]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-model-table/template.hbs"
    }
  });

  _exports.default = _default;
});