define("adtikir3v1/pods/components/adk-ad-timeline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rAg/SXZb",
    "block": "{\"symbols\":[\"timeline\",\"date\",\"block\",\"content\"],\"statements\":[[4,\"vertical-timeline\",null,null,{\"statements\":[[4,\"each\",[[24,[\"dateSorted\"]]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"block\"]],\"expected `timeline.block` to be a contextual component but found a string. Did you mean `(component timeline.block)`? ('adtikir3v1/pods/components/adk-ad-timeline/template.hbs' @ L3:C9) \"],null]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,3,[\"image\"]],\"expected `block.image` to be a contextual component but found a string. Did you mean `(component block.image)`? ('adtikir3v1/pods/components/adk-ad-timeline/template.hbs' @ L4:C11) \"],null]],null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,3,[\"content\"]],\"expected `block.content` to be a contextual component but found a string. Did you mean `(component block.content)`? ('adtikir3v1/pods/components/adk-ad-timeline/template.hbs' @ L8:C11) \"],null]],[[\"class\"],[[23,2,[\"color\"]]]],{\"statements\":[[0,\"          \"],[7,\"p\",true],[10,\"class\",\"adkTimelineFont\"],[8],[1,[23,2,[\"description\"]],false],[9],[0,\"\\n        \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,4,[\"date\"]],\"expected `content.date` to be a contextual component but found a string. Did you mean `(component content.date)`? ('adtikir3v1/pods/components/adk-ad-timeline/template.hbs' @ L10:C11) \"],null]],null,{\"statements\":[[1,[23,2,[\"date\"]],false]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"parameters\":[4]},null]],\"parameters\":[3]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-ad-timeline/template.hbs"
    }
  });

  _exports.default = _default;
});