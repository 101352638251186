define("adtikir3v1/components/intro-js", ["exports", "ember-introjs/components/intro-js"], function (_exports, _introJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _introJs.default;
    }
  });
});