define("adtikir3v1/models/budget-comment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com
  const {
    Model,
    attr,
    belongsTo // hasMany

  } = _emberData.default;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    userRoleId: attr('string'),
    comment: attr('string'),
    adBudgetWorkflowStep: attr("number"),
    adCampaignId: attr("string"),
    adId: attr("string"),
    // role:              attr('string'),
    createdAt: attr('date'),
    // updatedAt:         attr('date'),
    ad: belongsTo('ad')
  });

  _exports.default = _default;
});