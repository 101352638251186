define("adtikir3v1/pods/components/adk-file-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zIbjtlOM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"embedded\"]]],null]],null,{\"statements\":[[4,\"paper-button\",null,[[\"raised\",\"class\",\"onClick\"],[true,\"adkEditButton\",[28,\"action\",[[23,0,[]],\"toggleIsPop\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-icon\",[\"cloud_upload\"],[[\"size\"],[28]]],false],[0,\"    Upload Files\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showPromptDialog\"]]],null,{\"statements\":[[4,\"paper-dialog\",null,[[\"fullscreen\",\"onClose\",\"origin\",\"clickOutsideToClose\"],[[24,[\"fullscreen\"]],[28,\"action\",[[23,0,[]],\"closePromptDialog\",false],null],[24,[\"dialogOrigin\"]],true]],{\"statements\":[[0,\"\\n\"],[4,\"paper-dialog-content\",null,null,{\"statements\":[[0,\"        \"],[7,\"h2\",true],[10,\"class\",\"md-title\"],[8],[0,\"Upload Your Files Here\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"UploaderContainer\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-dialog-actions\",null,[[\"class\"],[\"layout-row\"]],{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n        \"],[4,\"paper-button\",null,[[\"class\",\"primary\",\"raised\",\"onClick\"],[\"adkCancelButton\",true,true,[28,\"action\",[[23,0,[]],\"closePromptDialog\",false],null]]],{\"statements\":[[0,\"Close\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-file-uploader/template.hbs"
    }
  });

  _exports.default = _default;
});