define("adtikir3v1/pods/campaign/details/financials/invoicing/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let adkCampaignDashboardRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    let adCampaignDetailModel = yield this.modelFor('campaign.details');
    let adCampaignInvoice = yield this.store.query('adInvoice', {
      adCampaignId: adCampaignDetailModel.adCampaign.id
    });
    return {
      adCampaign: adCampaignDetailModel.adCampaign,
      adCampaignDetail: adCampaignDetailModel.adCampaignDetail,
      adCampaignInvoice: adCampaignInvoice
    };
  }).restartable(), (_class = class adkCampaignDashboardRoute extends Ember.Route.extend(_keycloakAuthenticatedRoute.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "adkSessionAccount", _descriptor2, this);

      _initializerDefineProperty(this, "loadModelTask", _descriptor3, this);

      _defineProperty(this, "actions", {
        willTransition: function () {
          this.controller.set('editMode', false);
        }
      });
    }

    model() {
      try {
        return this.loadModelTask.perform();
      } catch (e) {
        throw e;
      }
    } //Template: refer adCampaign as adCampaign
    //Controler: refer adCampaign as this.adCampaign


    setupController(controller, model) {
      this._super(controller, model);

      controller.set('adCampaignInvoice', model.adCampaignInvoice);
      controller.set('adCampaign', model.adCampaign);
      controller.set('adCampaignDetail', model.adCampaignDetail);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adkSessionAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadModelTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = adkCampaignDashboardRoute;
});