define("adtikir3v1/pods/components/adkcard-post-old/component", ["exports", "adtikir3v1/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    CDN: Ember.inject.service('adk-cdn-url'),
    field: "media",

    init() {
      this._super(...arguments);

      this.setProperties({
        url: '',
        videoType: ''
      });
      this.reset();
    },

    reset() {
      this.setProperties({
        isImage: false,
        isVideo: false,
        isPdf: false,
        isAudio: false,
        isDoc: false
      });
    },

    didReceiveAttrs() {
      if (this.imgWidth > this.imgHeight) {
        this.isLandscape = true;
      }

      this.width = Math.floor(this.imgWidth * 0.6);

      if (this.title) {
        this.set('myTitle', this.title);
      } else if (this.titleField) {
        this.set('myTitle', this.get('adPost').get(this.get('titleField')));
      } else {
        this.set('myTitle', this.get('adPost').get('title'));
      }

      let getFileExt = this.get('getFileExt');
      getFileExt.perform();

      if (!this.get('cardad')) {
        this.set('cardad', "card-ad card-ad-height");
      }
    },

    click(event) {
      // get all 'md-card' elements
      // var a = document.getElementsByTagName('md-card');
      // var i = 1;
      // for (i = 1; i < a.length; i++) {
      //   // Remove the class 'active-class' if it exists
      //   a[i].classList.remove('active-class')
      // }
      let mdcard = event.currentTarget.children.item(0);
      this.adkPostSetActive(mdcard);
      this.onSelect(event);
      return true;
    },

    getFileExt: (0, _emberConcurrency.task)(function* () {
      let fileid = this.get('adPost').get(this.get('field'));

      if (!fileid.substr) {
        throw new Error('fileid is not a string');
      } //no substr . throw error;


      if (fileid.substr(0, 7) === "adkf://") {
        let wip = fileid.substr(7);
        wip = wip.substring(wip.indexOf('/')); //need to plug in the bunny host...cdnHost

        this.set('url', _environment.default.cdnHost + wip);
      } else if (fileid.substr(0, 8) === "/assets/") {
        this.set('url', _environment.default.cdnHost + fileid);
      } else if (fileid.substr(0, 9) === "adkfid://") {
        try {
          let wip = fileid.substr(9);
          let response = yield this.CDN.getCdnUrl(wip); //filetype = response.data.filetype.split('/');

          this.set('url', response.data.url);
        } catch (e) {
          throw e;
        } // } else if (fileid.substr(0, 7) === "/assets/" || 

      } else if (fileid.substr(0, 7) === "http://" || //  fileid.substr(0, 7) === "http://" ||
      fileid.substr(0, 8) === "https://") {
        this.set('url', fileid);
      } else {
        try {
          //old id      
          let response = yield this.CDN.getCdnUrl(fileid);
          this.set('url', response.data.url);
        } catch (e) {
          throw e;
        }
      } //if
      //ext...


      this.reset();
      let url = this.get('url');
      let ext = '.' + url.split('.').pop();

      if (_environment.default.imageExt.indexOf(ext) >= 0) {
        this.set('isImage', true);
      } else if (_environment.default.videoExt.indexOf(ext) >= 0) {
        this.set('isVideo', true);
      } else if (_environment.default.pdfExt.indexOf(ext) >= 0) {
        this.set('isPdf', true);
      } else if (_environment.default.docExt.indexOf(ext) >= 0) {
        this.set('isDoc', true);
      } else if (_environment.default.audioExt.indexOf(ext) >= 0) {
        this.set('isAudio', true);
      }
    }),
    actions: {
      imageLoad(ev) {
        let imgIsLandscape = false;

        if (this.isImage) {
          let img = ev.target;

          if (img.width > img.height) {
            imgIsLandscape = true;
          }

          if (this.isLandscape && imgIsLandscape || !this.isLandscape && imgIsLandscape) {
            if (img.width > this.imgWidth) {
              img.width = this.imgWidth;
            }
          } else if (this.isLandscape && !imgIsLandscape || !this.isLandscape && !imgIsLandscape) {
            if (img.height > this.imgHeight) {
              img.height = this.imgHeight;
            }
          }
        }
      }

    }
  });

  _exports.default = _default;
});