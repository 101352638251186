define("adtikir3v1/helpers/pattern-email", ["exports", "@abcum/ember-helpers/helpers/pattern-email"], function (_exports, _patternEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _patternEmail.default;
    }
  });
  Object.defineProperty(_exports, "patternEmail", {
    enumerable: true,
    get: function () {
      return _patternEmail.patternEmail;
    }
  });
});