define("adtikir3v1/pods/campaign/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pwL/5HqZ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[5,\"adkcard-campaign-new\",[],[[\"@campaignImage\",\"@cntrlSaveCampaign\",\"@cntrlCancelSaveCampaign\",\"@fieldTitle\"],[[24,[\"model\",\"campaignImage\"]],[28,\"action\",[[23,0,[]],\"saveNewCampaign\"],null],[28,\"action\",[[23,0,[]],\"cancelNewCampaign\"],null],\"New Campaign\"]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]}],[0,\" \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/campaign/new/template.hbs"
    }
  });

  _exports.default = _default;
});