define("adtikir3v1/models/ad-agency-proposal-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    note: attr('string', {
      defaultValue: ''
    }),
    cost: attr('number'),
    vAdAgencyProposal: belongsTo('v-ad-agency-proposal'),
    aAdAgencyProposal: belongsTo('a-ad-agency-proposal'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });

  _exports.default = _default;
});