define("adtikir3v1/models/location-state", ["exports", "ember-data", "adtikir3v1/models/location"], function (_exports, _emberData, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _location.default.extend({
    name: attr('string'),
    stateCode: attr('string'),
    country: belongsTo('location-country'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    type: 'State'
  });

  _exports.default = _default;
});