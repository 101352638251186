define("adtikir3v1/pods/components/adk-show-attachments/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    maxCount: 0,
    pageSize: 5,
    showAttachment: false,
    attachmentFile: null,
    //attachmentFile var to keep the new uploaded file
    refreshFunc: function () {
      if (this.get('refresh')) {
        Ember.run.next(this, function () {
          Ember.set(this.get('attachmentsList'), "refresh", true);
          Ember.set(this, "refresh", false);
        });
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('attachmentsList', {
        page: 0,
        pageSize: this.get('pageSize'),
        action: this.get("loadAttachmentTask"),
        result: null,
        total: 1,
        //set total to > 0... so standard paging will be loading and set the total ....
        refresh: this.refresh,
        type: "emberdata"
      }); // no need run this to get total when first run... 
      // just set the attachmentsList total > 0.. then adk-standard-paging will run it
      // let attachmentsList = this.get('attachmentsList');
      // this.set('actionTask', attachmentsList.action.perform(attachmentsList.page, attachmentsList));

      this.addObserver('attachmentsList.refresh', this.refreshFunc);
    },

    //didReceiveAttrs
    // total: computed('attachmentsList.{result,total,refresh}', function() {
    //    let attachmentsList = this.get('attachmentsList');
    //    if (attachmentsList.result 
    //        && attachmentsList.result.meta 
    //        && attachmentsList.result.meta.page 
    //        && attachmentsList.result.meta.page.total
    //       ) {
    //       return attachmentsList.result.meta.page.total;
    //   }
    //   return 0;
    // }),
    actions: {
      selectCard(attachment, ev) {
        this.set('dialogOrigin', ev.currentTarget.childNodes[1]);
        this.set('theAttachment', attachment);
        this.set('showAttachment', true);
      },

      closeShowAttachment() {
        this.set('theAttachment', null);
        this.set('showAttachment', false);
      },

      adkAttachmentActive() {}

    }
  });

  _exports.default = _default;
});