define("adtikir3v1/models/vendor-media-media-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  var _default = Model.extend({
    mediaTypeName: attr('string'),
    vendor: belongsTo('vendor')
  });

  _exports.default = _default;
});