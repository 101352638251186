define("adtikir3v1/pods/components/adk-location-select/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('countryOptions', []); //set removeList

      this.set('removeList', []);
      this.clearCountry();
      this.clearState();
      this.clearCity();
    },

    // this is an array to record the end of each location-* type in locationList array...
    // it assume the locationList will be in country state city order  
    typeEndIndex: Ember.computed('locationList.[]', function () {
      let locationList = this.get('locationList');
      let indexArray = [-1, -1, -1];

      for (let i = 0; i < locationList.length; i++) {
        if (locationList[i].constructor.modelName === "location-country") {
          indexArray[0] = i;
        } else if (locationList[i].constructor.modelName === "location-state") {
          indexArray[1] = i;
        } else if (locationList[i].constructor.modelName === "location-city") {
          indexArray[2] = i;
        }
      }

      return indexArray;
    }),
    // this functuion used for disable the add button for country in the form
    // it get the country id base on different type
    getCountryIdFunction: function (location) {
      if (location.constructor.modelName === "location-country") {
        return location.id;
      } else {
        return location.get('country.id');
      }
    },
    // this functuion used for disable the add button for state in the form
    // it get the state id base on different type
    getStateIdFunction: function (location) {
      if (location.constructor.modelName === "location-state") {
        return location.get('id');
      } else if (location.constructor.modelName === "location-city") {
        return location.get('state.id');
      } else {
        return '';
      }
    },
    // this is the computed flag to enable or disable the + button for country
    addCountryButtonDisableFlag: Ember.computed('selectedCountry', 'locationList.[]', function () {
      let locationList = this.get('locationList');
      let selectedCountry = this.get('selectedCountry');

      if (selectedCountry) {
        for (let i = 0; i < locationList.length; i++) {
          if (this.getCountryIdFunction(locationList[i]) === selectedCountry.id) {
            return true;
          }
        }
      }

      return false;
    }),
    // this is the computed flag to enable or disable the + button for state
    addStateButtonDisableFlag: Ember.computed('selectedState', 'locationList.[]', function () {
      let locationList = this.get('locationList');
      let typeEndIndex = this.get('typeEndIndex');
      let selectedState = this.get('selectedState'); //the start need to be start from 
      //state start location = typeEndIndex[0] + 1;

      let startIdx = typeEndIndex[0] + 1;

      if (selectedState) {
        for (let i = startIdx; i < locationList.length; i++) {
          if (this.getStateIdFunction(locationList[i]) === selectedState.id) {
            return true;
          }
        }
      }

      return false;
    }),
    // this is the computed flag to enable or disable the + button for city
    addCityButtonDisableFlag: Ember.computed('selectedCity', 'locationList.[]', function () {
      let locationList = this.get('locationList');
      let typeEndIndex = this.get('typeEndIndex');
      let selectedCity = this.get('selectedCity'); //the start need to be start from 
      //city start location = typeEndIndex[0] + 1;

      let startIdx = typeEndIndex[1] === -1 ? typeEndIndex[0] + 1 : typeEndIndex[1] + 1;

      if (selectedCity) {
        for (let i = startIdx; i < locationList.length; i++) {
          if (locationList[i].id === selectedCity.id) {
            return true;
          }
        }
      }

      return false;
    }),
    saveButtonDisableFlag: Ember.computed('locationList.[]', 'removeList.[]', function () {
      let removeList = this.get('removeList');
      let locationList = this.get('locationList');

      if (removeList.length > 0) {
        return false;
      }

      for (let i = 0; i < locationList.length; i++) {
        if (locationList[i].adkNewFlag) {
          return false;
        }
      }

      return true;
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let parentId = this.get('parentId');
      let parentField = this.get('parentField');
      let removeList = this.get('removeList');
      let locationList = this.get('locationList');

      for (let i = 0; i < locationList.length; i++) {
        if (locationList[i].adkNewFlag) {
          //create record code here
          let location = this.store.createRecord('location', {
            parentId: parentId,
            parentField: parentField,
            locationableType: locationList[i].constructor.modelName,
            LocationableId: locationList[i].id
          });
          yield location.save();
          locationList[i].set('adkNewFlag', false);
        }
      }

      for (let i = removeList.length - 1; i >= 0; i--) {
        let id = parentField + '!!!' + parentId + '!!!' + removeList[i].constructor.modelName + '!!!' + removeList[i].id;
        let record = yield this.store.findRecord('location', id);
        yield record.destroyRecord();
        removeList.removeObject(removeList[i]);
      } //for
      //this.set('removeList', []);

    }),

    clearCountry() {
      this.set('selectedCountry', '');
    },

    clearState() {
      this.set('selectedState', '');
      this.set('stateOptions', []);
    },

    clearCity() {
      this.set('selectedCity', '');
      this.set('cityOptions', []);
    },

    didReceiveAttrs() {
      this._super(...arguments); // this is to create a copy of the locationList...


      this.set('locationList', this.get('locations').toArray()); //run load countryList first.. when 1st time run

      this.loadLocationCountryTask.perform();
    },

    //load country list
    loadLocationCountryTask: (0, _emberConcurrency.task)(function* () {
      let countries = yield this.store.findAll('location-country');
      this.set('countryOptions', countries); //this.set('countryDbOptions', this.('get').slice(0));
    }),
    // load stateList.. 
    // this will check the state's country is in locationList or not.. if so, dont load it
    loadLocationStateTask: (0, _emberConcurrency.task)(function* () {
      let locationList = this.get('locationList');
      let typeEndIndex = this.get('typeEndIndex');
      let endIdx = typeEndIndex[0];

      if (endIdx === -1) {
        endIdx = typeEndIndex[1];
      }

      if (endIdx === -1) {
        endIdx = locationList.length - 1;
      }

      for (let i = 0; i < endIdx + 1; i++) {
        if (locationList[i].constructor.modelName === "location-country") {
          if (locationList[i].id === this.get('selectedCountry').id) {
            return;
          }
        }
      }

      let states = yield this.store.query('location-state', {
        country: this.get('selectedCountry').id
      });
      this.set('stateOptions', states);
    }),
    // load city list.. 
    // this will check the city's state is in locationList or not.. if so, dont load it
    loadLocationCityTask: (0, _emberConcurrency.task)(function* () {
      let locationList = this.get('locationList');
      let typeEndIndex = this.get('typeEndIndex');
      let endIdx = typeEndIndex[1];

      if (endIdx === -1) {
        endIdx = locationList.length - 1;
      }

      for (let i = 0; i < endIdx + 1; i++) {
        if (locationList[i].constructor.modelName === "location-state") {
          if (locationList[i].id === this.get('selectedState').id) {
            return;
          }
        }
      }

      let cities = yield this.store.query('location-city', {
        state: this.get('selectedState').id
      });
      this.set('cityOptions', cities);
    }),
    //this is to set the selectedCountry and some clean up
    updateCountryTask: (0, _emberConcurrency.task)(function* (country) {
      this.clearState();
      this.clearCity();
      this.set('selectedCountry', country);
      yield this.loadLocationStateTask.perform();
    }).restartable(),
    //this is to set the selectedState and some clean up
    updateStateTask: (0, _emberConcurrency.task)(function* (state) {
      this.clearCity();
      this.set('selectedState', state);
      yield this.loadLocationCityTask.perform();
    }).restartable(),
    //selectedCity no need function as no clean up is needed
    actions: {
      removeLocation(location) {
        let locationList = this.get('locationList');
        let removeObj = locationList.filter(function (item) {
          return item.get('id') === Ember.get(location, 'id');
        });

        if (!removeObj[0].adkNewFlag) {
          this.get('removeList').pushObject(removeObj[0]);
        }

        this.get('locationList').removeObject(removeObj[0]);
        this.clearCountry();
        this.clearState();
        this.clearCity();
      },

      //removeLocation
      addMe(location, type) {
        if (!location) {
          alert("Please select first");
          return;
        }

        let locationList = this.get('locationList');
        let typeEndIndex = this.get('typeEndIndex');
        let insertPosition = -1;
        let idx;

        if (type === 'location-country') {
          idx = 0;
          this.clearState();
          this.clearCity();
        } else if (type === 'location-state') {
          idx = 1;
          this.clearCity();
        } else if (type === 'location-city') {
          idx = 2;
        } //find the right position to insert.. so list always country state city order;


        insertPosition = typeEndIndex[idx];

        for (let i = 0; i < idx; i++) {
          if (insertPosition !== -1) {
            break;
          }

          insertPosition = typeEndIndex[i];
        } //loop throught removeList.. if found, then remove from removeList;


        let removeList = this.get('removeList');
        let foundFlag = false;

        for (let i = 0; i < removeList.length; i++) {
          if (location.constructor.modelName === removeList[i].constructor.modelName && location.id === removeList[i].id) {
            //found it
            foundFlag = true; //remove from list    

            removeList.removeObject(removeList[i]);
            break;
          }
        }

        if (!foundFlag) {
          //this is the flag for new list.. so we need to create record on it        
          location.set('adkNewFlag', true);
        }

        locationList.insertAt(insertPosition + 1, location);
        this.set('locationList', locationList.slice(0));
      } //addMe


    }
  });

  _exports.default = _default;
});