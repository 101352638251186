define("adtikir3v1/models/location-country", ["exports", "ember-data", "adtikir3v1/models/location"], function (_exports, _emberData, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    // Model, 
    attr,
    hasMany
  } = _emberData.default;

  var _default = _location.default.extend({
    name: attr('string'),
    capital: attr('string'),
    native: attr('string'),
    phone: attr('string'),
    currency: attr('string'),
    languages: hasMany('language'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    type: 'Country'
  });

  _exports.default = _default;
});