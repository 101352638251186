define("adtikir3v1/pods/components/adktoggle-approval/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import moment from 'moment';
  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    //Injected store to be able to use it
    init: function () {
      this._super(...arguments); // Update the time.


      this.updateTime();
    },
    updateTime: function () {
      var _this = this; // Update the time every second.


      Ember.run.later(function () {
        if (_this.isDestroyed) {
          return;
        }

        let date = _this.get('adkSessionAccount').get('myNow').toString();

        _this.set('currentTime', date);

        _this.set('localTime', date);

        _this.updateTime();
      }, 1000);
    },
    isApproveButtonDisabled: Ember.computed.empty('approvalComment'),

    approvedAssetClean() {
      this.set('approvalComment', '');
    },

    actions: {
      approveCurrentStage(adApproval) {
        // let adkSessionAccount = this.get('sessionAccount');
        // let user = sessionAccount.get('user');
        let adkToast = this.get('adkToast'); //let controller = this;

        adApproval.set('approvedComment', this.get('stageApprovalComment'));
        adApproval.set('approved', true);
        adApproval.set('approvedAt', new Date());
        adApproval.save().then(function () {
          adkToast.display("Final approved done", "warning", 3000);
          return adApproval.reload();
        }, function () {
          if (adApproval.get('hasDirtyAttributes')) {
            adApproval.rollbackAttributes();
          }

          adkToast.display("Approval Fail", "warning", 3000);
          return false;
        });
        this.approvedAssetClean(); // let ad = this.get('ad');
        // let nextWorkFlowNumber = this.get('adFindNextWorkFlow')(ad.get('workFlowStatusNumber'));
        // //increase ad.workFlowStatusNumber;
        // if (nextWorkFlowNumber > 1) {
        //    ad.set('workFlowStatusNumber',nextWorkFlowNumber);
        //    let newAdApproval = this.store.createRecord('ad-approval', {
        //      workFlowStatusNumber: nextWorkFlowNumber,            
        //    });
        //    newAdApproval.set('ad', ad);      
        //    newAdApproval.save().then(function(){
        //      ad.get('adApprovals').addObject(newAdApproval);
        //      ad.save();
        //      adkToast.display("Final approved done", "warning",3000);
        //    });
        // }
      },

      approveCurrentAssetLegalStage() {
        let adPost = this.get('adPost');
        let aPD = this.get('aPD');
        let legalApprovalComment = this.get('legalApprovalComment');
        let selectedApproval = this.get('selectedApproval');
        let timestamp = new Date();
        let adkToast = this.get('adkToast');

        if (aPD.annoViewer) {
          this.aPD.set('annoViewer', false);
        }

        adPost.set('legalApprovalStage', selectedApproval);
        adPost.set('legalApprovalComment', legalApprovalComment);
        adPost.set('lastModifiedAt', timestamp);
        adPost.save().then(function () {
          adkToast.display("Status Updated", "warning", 3000);
          return true;
        }, function () {
          if (adPost.get('hasDirtyAttributes')) {
            adPost.rollbackAttributes();
          }

          adkToast.display("Status not recorded", "warning", 3000);
        });
        this.approvedAssetClean();
      },

      approveCurrentAsset() {
        let adPost = this.get('adPost');
        let aPD = this.get('aPD');

        if (aPD.annoEditor) {
          this.aPD.set('annoEditor', false);
        }

        let approvedComment = this.get('approvalComment');
        let timestamp = new Date();
        let adkToast = this.get('adkToast');
        adPost.set('approved', true);
        adPost.set('approvedComment', approvedComment);
        adPost.set('approvedAt', timestamp);
        adPost.save().then(function () {
          adkToast.display("Asset approved", "warning", 3000);
          return true;
        }, function () {
          if (adPost.get('hasDirtyAttributes')) {
            adPost.rollbackAttributes();
          }

          adkToast.display("Asset not approved", "warning", 3000);
        });
        this.approvedAssetClean(); // adkToast.display("Asset approved", "warning",3000);
      },

      //which is the value of the properties of the button.
      changeBooleanProp(which, newValue) {
        this.set(`booleanProp${which}`, newValue);
      },

      selectedApproval() {
        return;
      } // actionSaveComment: function (adPost) {
      //   // let adkSessionAccount = this.get('sessionAccount');
      //   // let user = sessionAccount.get('user');
      //   let adkToast = this.get('adkToast');
      //   //let component = this;
      //   adPost.set('approved', true);
      //   adPost.set('approvedCmt', this.get('approvalComments'));
      //   // adPost.set('approvedBy', user);
      //   adPost.set('approvedAt', new Date());
      //   adPost.save().then(function() {
      //     adkToast.display("asset approved", "warning",3000);
      //     return true;
      //   },
      //   function () {
      //     adkToast.display("asset not approved", "warning", 3000);
      //   });
      // },


    }
  });

  _exports.default = _default;
});