define("adtikir3v1/pods/components/adk-location-show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Q8lKOx7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"Country\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"get\",[[24,[\"location\"]],\"name\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"State\"],null]],null,{\"statements\":[[0,\"       \"],[1,[28,\"get\",[[28,\"get\",[[24,[\"location\"]],\"country\"],null],\"name\"],null],false],[0,\" => \"],[1,[28,\"get\",[[24,[\"location\"]],\"name\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"       \"],[1,[28,\"get\",[[28,\"get\",[[24,[\"location\"]],\"country\"],null],\"name\"],null],false],[0,\" => \"],[1,[28,\"get\",[[28,\"get\",[[24,[\"location\"]],\"state\"],null],\"name\"],null],false],[0,\" => \"],[1,[28,\"get\",[[24,[\"location\"]],\"name\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-location-show/template.hbs"
    }
  });

  _exports.default = _default;
});