define("adtikir3v1/pods/campaign/details/financials/budget/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  //import adkDateTime  from '../../../utils/adk-date-time';
  //import moment from 'moment'
  // import { computed } from '@ember/object';
  let campaignCntrl = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class campaignCntrl extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "adkToast", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _defineProperty(this, "enableExchangeRate", false);

      _defineProperty(this, "actions", {
        campaignSaveBudget(componentValues) {
          let adkToast = this.get('adkToast');
          let router = this.get('router');
          this.model.setProperties(componentValues);
          this.model.save().then(() => {
            adkToast.display("Data Saved Success", "secondary");
            router.transitionTo('campaign.details.financials');
          }, () => {
            console.log("************######***********");
            console.log('Campaign/Budget save failed');
            console.log("************######***********");
            adkToast.display("Campaign Budget Save Failed", "error");
          });
        },

        //end of saveBudget
        saveBudgetUltimate() {// let adkToast = this.get('adkToast');
        },

        adSaveBudget(componentValues) {
          this.model.setProperties(componentValues);
          let adkToast = this.get('adkToast');
          let router = this.get('router');
          this.model.save().then(() => {
            adkToast.display("Data Saved Success", "secondary");
            router.transitionTo('campaign.details.financials');
          }, () => {
            console.log("************######***********");
            console.log('Campaign/Budget save failed');
            console.log("************######***********");
            adkToast.display("ad Budget Save Failed", "error");
          });
        },

        //end of saveAdBudget
        enableExchangeRate() {
          this.set('enableExchangeRate', true);
        }

      });
    } //end of actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adkToast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = campaignCntrl;
});